import React, { useEffect, useState } from 'react'
import * as XLSX from 'xlsx';
import './choose-box.scss'
import NormalInput from '../../common/inputs/NormalInput'
import { YYYYMMDDFormat, DDMMYYYYFormat } from '../../../assets/javascript/formate-functions'
import { servicedAxios } from '../../../config/axios'
import toast from 'react-hot-toast'
import Spinner from '../../../components/common/spinners/SpinWithMessage'

const DarDownloadBox = ({ setModal }) => {
    const [form, setForm] = useState({ from_date: null, end_date: null, })
    const [loading, setLoading] = useState('')
    const [progress, setProgress] = useState(0);

    const handleChange = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        })
    }

    const downloadFile = (workbook, filename) => {
        const excelBuffer = XLSX.write(workbook, {
            bookType: 'xlsx', bookSST: true, type: 'array', cellDates: true, cellNF: false
        });

        const data = new Blob([excelBuffer], { type: 'application/octet-stream' });
        filename = filename + '.xlsx';

        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            // For IE browser
            window.navigator.msSaveOrOpenBlob(data, filename);
            setLoading('')
            setModal({ status: false })
        } else {
            // For other browsers
            const url = window.URL.createObjectURL(data);
            const link = document.createElement('a');
            link.href = url;
            link.download = filename;
            link.click();
            window.URL.revokeObjectURL(url);
            setLoading('')
            setModal({ status: false })
        }
    }

    const exportToExcel = (dar, kms) => {

        const workbook = XLSX.utils.book_new();
        // DAR Setup
        const sheetName1 = 'DAR';
        let workSheetData1 = []
        dar.map((data, index) => {
            const obj = {
                CID: data?.cid || '',
                NAME: data?.name_of_customer || '',
                SERVICER: data?.technician || '',
                DATE: data?.date ? new Date(data?.date) : '',
                'IN TIME': new Date(data?.in_time).toLocaleTimeString() || '',
                'OUT TIME': new Date(data?.out_time).toLocaleTimeString() || '',
                DURATION: data?.duration || 0,
                STATUS: data?.purifier_customer_status || '',
                TYPE: data?.work || '',
                'CALL PRIMARY SECONDARY': data?.primary_call || 0,
                'CALL SPECIAL WORK': data?.extra_call || 0,
                'CALL WARRANTY WORK': 0,
                'CALL SPECIAL WORK DESCRIPTION': data?.extra_charge_reason || '',
                'COMPLAINT REGISTRATION NUMBER': data?.reg_no || '',
                'SERVICE SRL NUMBER': data?.service_srl_number || '',
                'PRIMARY SECONDARY SERVICE CHARGE': data?.normal_service_charge || 0,
                'SPECIAL WORK SERVICE CHARGE': data?.extra_service_charge || 0,
                'WARRANTY WORK SERVICE CHARGE': 0,
                'AMC FUND': data?.amc_fund || 0,
                'SSP FUND': data?.ssp_fund || 0,
                'PRIMARY SPARE SALE': data?.primary_spare_rate || 0,
                'SECONDARY SPARE SALE': data?.secondary_spare_rate || 0,
                'SPECIAL WORK SPARE SALE': data?.special_work_space_rate || 0,
                'TOTAL SPARE SALE': ((data?.primary_spare_rate || 0) + (data?.secondary_spare_rate || 0) + (data?.special_work_space_rate || 0)),
                'PRIMARY RECEIPT': ((data?.normal_service_charge || 0) + (data?.primary_spare_rate || 0) + (data?.amc_fund || 0) + (data?.ssp_fund || 0)),
                'SECONDARY RECEIPT': data?.secondary_spare_rate || 0,
                'SPECIAL WORK RECEIPT': ((data?.extra_service_charge || 0) + (data?.special_work_space_rate || 0)),
                'WARRANTY WORK RECEIPT': 0,
                'TOTAL AMOUNT': data.grand_total || 0,
                'ABOVE 24': data?.above_24_call || 0,
                'REPEAT..? BASED ON DATE': data?.repeat_software ? `${data?.repeat_software}` : '',
                'PREVIOUSLY ATTENDED BY': data?.previous_work_tech || '',
                'PREVIOUSLY ATTENDED SERVICE FORM': data?.previous_work_srl_number || '',
                'IS IT REPEAT..? TECHNICIAN SAYS': data?.repeat_software ? `${data?.repeat_tech}` : '',
                'NOT REPEAT REASON, TECHNICIAN SAYS': data?.why_not_repeat || ''
            }
            workSheetData1.push(obj)
            return obj;
        })

        const worksheet1 = XLSX.utils.json_to_sheet(workSheetData1);
        XLSX.utils.book_append_sheet(workbook, worksheet1, sheetName1);

        // KMS Setup

        const sheetName2 = 'KMS';
        let workSheetData2 = []
        kms.map((data, index) => {
            const obj = {
                STAFF_ID: data?.staff_id || '',
                FULL_NAME: data?.full_name || '',
                DATE: data?.date ? new Date(data?.date) : '',
                KMS: data?.kms || 0,

            }
            workSheetData2.push(obj)
            return obj;
        })

        const worksheet2 = XLSX.utils.json_to_sheet(workSheetData2);
        XLSX.utils.book_append_sheet(workbook, worksheet2, sheetName2);

        return workbook;
    };


    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading('Service data fetching')

        servicedAxios.get(`/dar/xl-download?from_date=${form?.from_date}&to_date=${form?.end_date}`, {
            onDownloadProgress: (progressEvent) => {
                const percentage = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                setProgress(percentage);
            }
        }).then((response) => {
            setLoading('Kms data fetching')
            servicedAxios.get(`/dar/kms?from_date=${form?.from_date}&to_date=${form?.end_date}`, {
                onDownloadProgress: (progressEvent) => {
                    const percentage = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    setProgress(percentage);
                }
            }).then((result) => {
                setLoading('XL file Building')
                if (!response?.data?.[0] && !result?.data?.[0]) {
                    setLoading('')
                    toast.error('No data available')
                    return;
                }
                const workbook = exportToExcel(response?.data || [], result?.data || []);
                downloadFile(workbook, `DAR`)

            })
        }).catch(() => {
            toast.error('Something wrong, Try now!')
        })

    }

    useEffect(() => {
        setForm({
            ...form,
            from_date: YYYYMMDDFormat(new Date(new Date().setDate(1)).setMonth(0)),
            end_date: YYYYMMDDFormat(new Date())
        })
        // eslint-disable-next-line
    }, [])


    return (
        <div className="dar-download-box-div">
            <form action="" onSubmit={handleSubmit}>
                {loading
                    ? <div className="loading-box-div">
                        <Spinner height={'150px'} message={`${progress}% \n ${loading}...`} />
                    </div>
                    : <>
                        <NormalInput label='From date (MM-DD-YYYY)' name='from_date' type={'date'} value={form?.from_date} onChangeFun={handleChange}
                            max={form?.end_date} />
                        <NormalInput label='End date (MM-DD-YYYY)' name='end_date' type={'date'} value={form?.end_date} onChangeFun={handleChange}
                            max={YYYYMMDDFormat(new Date())} />
                        <div className="button-div">
                            <button type={'submit'}>Download</button>
                        </div>
                    </>}
            </form>

        </div>
    )
}

export default DarDownloadBox