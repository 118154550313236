import React from 'react'
import Spinner from '../../../components/common/spinners/SpinWithMessage'
import TableFilter from '../../../components/common/table-filter/TableFilter'
import { RxDashboard } from 'react-icons/rx'
import { PiArrowArcRightBold } from "react-icons/pi";
import { LuClipboardEdit, LuExpand } from "react-icons/lu";
import { RiChatNewLine } from 'react-icons/ri'
import { formatStringDate } from '../../../assets/javascript/formate-functions'
import RegFullView2 from '../../../components/admin/reg-full-view/RegFullView2'
import RegisterService from '../../../components/admin/register-service/RegisterService'
import PostponeService from '../../../components/admin/service-cancel/PostponeService';
import AddDescription from '../../../components/admin/add-description/AddDescription'
import { downloadRegDataXl, regDataExportToExcel } from '../../../assets/javascript/reg-xl-functions';

const TableView2 = ({ type, status, data, doRefresh, setModal, setData, dataList }) => {

    const openModal = (elem, head) => {
        setModal({
            content: elem,
            title: head,
            status: true
        })
    }

    const handleDownloadButton = () => {
        const workbook = regDataExportToExcel(dataList, type);
        downloadRegDataXl(workbook, `${type === 'complaint' ? 'Complaints Reg List' : 'Service & Renewals Reg List'}`)
    }

    return (
        <div className="table-view-div">
            {data?.[0]
                ? <TableFilter topRight={<div style={{ display: "flex", gap: '10px' }}>
                    <button title='Refresh' className='re-button' onClick={doRefresh}>Refresh</button>
                    <button title='Refresh' className='re-button' onClick={handleDownloadButton}>Download XL</button>
                </div>}>
                    <table >
                        <thead>
                            <tr>
                                <th>Full Name</th>
                                <th>Service / Index</th>
                                <th>Next service</th>
                                <th>Package expire</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody >
                            {data?.map((obj) => <tr>

                                <td style={{ textAlign: 'center' }}>
                                    CID :  {obj.cid}  <small className={`text-badge ${obj?.purifier_customer_status}-text`}>{obj?.purifier_customer_status}</small>
                                    <br></br>
                                    <span style={{ fontWeight: 700 }}>{obj?.full_name}</span>
                                    <br></br>
                                    {obj?.address?.post && `${obj?.address?.post} P.O`} ({obj?.customer_zone})
                                </td>

                                <td style={{ textAlign: 'center' }}>
                                    {obj?.renewal ? 'Renewal / Periodical' : "Service / Periodical"}
                                    <br></br>
                                    <small className={`text-badge no-text`}>S{(obj?.service_count || 0) + 1}</small>
                                    {obj?.service_count >= 4 && <small className={`text-badge red-text`}>4 Service completed</small>}
                                </td>
                                <td style={{ textAlign: 'center' }}>
                                    {formatStringDate(new Date(obj?.next_service_date))}
                                </td>
                                <td style={{ textAlign: 'center' }}>
                                    {formatStringDate(new Date(obj?.package_expiry_date))}
                                </td>


                                {/* Hide Td for search */}
                                <td style={{ display: 'none' }}>
                                    {obj?.purifier_customer_status} {obj?.full_name}
                                    {obj?.status}
                                    {obj?.service_count >= 4 ? '4 Service completed' : ""}
                                </td>
                                {/* End Hide */}

                                <td>
                                    <div className='buttons-div' >
                                        <button title='View' className='button-small-icon view'
                                            onClick={() => openModal(<RegFullView2 data={obj} />, 'Expand View')}> <LuExpand /></button>
                                        <button title='Register' className='button-small-icon register'
                                            onClick={() => openModal(<RegisterService cid={obj?.cid} setModal={setModal} setData={setData}
                                                data={obj} serviceOnly={true} />, 'Register service')}>
                                            <LuClipboardEdit /></button>
                                        <button title='Add Description' className='button-small-icon add'
                                            onClick={() => openModal(<AddDescription cid={obj?.cid} setModal={setModal} />, 'Add Description')}><RiChatNewLine /></button>
                                        <button title='Postpone' className='button-small-icon postpone'
                                            onClick={() => openModal(<PostponeService data={obj} setModal={setModal} setData={setData} />,
                                                'Postpone service')}><PiArrowArcRightBold /></button>
                                    </div>
                                </td>

                            </tr>)}
                        </tbody>
                    </table>
                </TableFilter>
                : <>
                    <Spinner message={`Empty ${status}`} icon={<RxDashboard />} spin={false} />
                </>}
        </div>
    )
}

export default TableView2