import React, { useEffect, useState } from 'react'
import './service-list.scss'
import SinglePage from '../../../components/common/page/SinglePage'
import Spinner from '../../../components/common/spinners/SpinWithMessage'
import { serviceAxios } from '../../../config/axios'
import { useSelector } from 'react-redux'
import { ServiceCard } from '../../../components/user/service-list-card/ServiceCard'
import { RxDashboard } from 'react-icons/rx'

function ScheduledList() {
    const { user } = useSelector((state) => state.userAuth)
    // const [cache, setCache] = useState({ complaint: [], service: [], renewal: [] })
    const [data, setData] = useState({ complaint: [], service: [], renewal: [] })
    const [loading, setLoading] = useState('fetch')


    const fetchData = () => {
        setLoading('fetch')
        serviceAxios.get(`/schedule?zone_id=${user?.active_zone?._id}`).then((response) => {
            let data = [...response.data.complaint, ...response.data.service, ...response.data.renewal]
                .sort((a, b) => {
                    const dateA = a?.scheduled_date_time ? new Date(a.scheduled_date_time) : null;
                    const dateB = b?.scheduled_date_time ? new Date(b.scheduled_date_time) : null;

                    if (dateA && dateB) {
                        return dateA - dateB; // Sort in descending order
                    } else if (dateA) {
                        return 1; // Move objects with defined dateA to the front
                    } else if (dateB) {
                        return -1; // Move objects with defined dateB to the front
                    } else {
                        return 0; // Leave objects with undefined dates in their original order
                    }
                })
            // setCache(data)
            setData(data)
            setLoading('')
        })
    }

    useEffect(() => {
        fetchData()
        // eslint-disable-next-line
    }, [])


    return (
        <div className='pending-service-list-div'>
            <SinglePage titleArray={['Scheduled service list']}>
                <div className="top-buttons-div">
                    <div className="left">
                    </div>
                    <div className="right">
                        {loading !== 'fetch' && <>
                            <button onClick={() => fetchData()}>Refresh</button>
                        </>}
                    </div>
                </div>
                <div className="content-div">
                    {loading === 'fetch' ?
                        <Spinner message='fetch data...' />
                        : <div className='content-border'>
                            {data?.[0] ? <div className="item-div">
                                {data?.map((service, index) => {
                                    return <ServiceCard key={index} data={service} type={service?.renewal
                                        ? 'renewal'
                                        : service?.service
                                            ? 'service'
                                            : 'complaint'
                                    } navLink={'/scheduled-works/service'} />
                                })}
                            </div>
                                :
                                <Spinner message={`No Schedules`} icon={<RxDashboard />} spin={false} />}
                        </div>
                    }
                </div>
            </SinglePage >
        </div >
    )
}

export default ScheduledList