import React from 'react'
import './style.scss'
import AdminPage from '../../../components/common/page/AdminPage'
import { useNavigate } from 'react-router-dom'

const InputOptionPage = () => {
    const navigate = useNavigate()

    return (
        <div className="home-page-div">
            <AdminPage titleArray={['Input options']}>
                {/* Service Form */}
                <div className="sub-title">
                    <h3>Service Form</h3>
                </div>
                <div className="home-border">
                    <div className="button-div" >
                        <button onClick={() => navigate('/admin/input-resources/single/leak_descriptions')} >LEAK DESCRIPTIONS</button>
                    </div>
                    <div className="button-div" >
                        <button onClick={() => navigate('/admin/input-resources/single/crack_descriptions')} >CRACK DESCRIPTIONS</button>
                    </div>
                    <div className="button-div" >
                        <button onClick={() => navigate('/admin/input-resources/sub_option/complaint_nature')} >COMPLAINT NATURE</button>
                    </div>
                    <div className="button-div" >
                        <button onClick={() => navigate('/admin/input-resources/single/extra_charge_reasons')} >EXTRA CHARGE REASONS</button>
                    </div>
                    <div className="button-div" >
                        <button onClick={() => navigate('/admin/input-resources/single/cancel_schedules_reasons')} >CANCEL SCHEDULES REASONS</button>
                    </div>
                    <div className="button-div" >
                        <button onClick={() => navigate('/admin/input-resources/single/work_repeat_reasons')} >WORK REPEAT REASONS</button>
                    </div>
                </div>

                {/* Service Registration */}
                <div className="sub-title">
                    <h3>Service registration & Related</h3>
                </div>
                <div className="home-border">
                    <div className="button-div" >
                        <button onClick={() => navigate('/admin/input-resources/single-with-status/reg-complaint-reasons')} >COMPLAINT REASONS</button>
                    </div>
                    <div className="button-div" >
                        <button onClick={() => navigate('/admin/input-resources/single/service_cancel_reasons')} >SERVICE CANCEL REASONS</button>
                    </div>
                    <div className="button-div" >
                        <button onClick={() => navigate('/admin/input-resources/single/service_postpone_reasons')} >SERVICE POSTPONE REASONS</button>
                    </div>
                </div>
            </AdminPage >
        </div >
    )
}

export default InputOptionPage