import React, { useEffect, useState } from 'react'
import './red-yellow-list.scss'
import AdminPage from '../../../components/common/page/AdminPage'
import TableFilter from '../../../components/common/table-filter/TableFilter'
import Spinner from '../../../components/common/spinners/SpinWithMessage'
import Modal from '../../../components/common/modal/Modal';
import ExpireAction from '../../../components/admin/expire-action/ExpireAction';
import { serviceAxios } from '../../../config/axios'
import { FaEye, FaStar } from 'react-icons/fa'
import { GrList } from 'react-icons/gr'
import { useNavigate } from 'react-router-dom'
import { TbStatusChange } from "react-icons/tb";

const BlackList = ({ listType }) => {
    const [listData, setListData] = useState([])
    const [loading, setLoading] = useState('fetch')
    const navigate = useNavigate()
    const [modal, setModal] = useState({ content: null, title: null, status: false })

    const openModal = (elem, title, data) => {
        setModal({
            content: elem,
            title,
            status: true
        })
    }

    useEffect(() => {

        serviceAxios.get('/black-list').then((response) => {
            setListData(response.data)
            setLoading('')
        })


        // eslint-disable-next-line
    }, [])


    return (

        <div className="red-yellow-list-div">
            <AdminPage titleArray={['Black list']}>
                <Modal modal={modal} setModal={setModal} />
                {listData?.[0] ? <>
                    <TableFilter srlNo={true}>
                        <table>
                            <thead>
                                <tr>
                                    <th>CID</th>
                                    <th>Full name</th>
                                    <th>From Status</th>
                                    <th>Post</th>
                                    <th>Expired on</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {listData?.map((packageObj) => <tr>
                                    <td style={{ textAlign: 'center' }}>{packageObj.cid}</td>
                                    <td style={{ textAlign: 'center' }}>
                                        <span style={{ fontWeight: 700 }}>{packageObj?.full_name}
                                            {packageObj?.star_rate && <small className={`text-badge green-text`}><FaStar /> {packageObj?.star_rate}</small>}</span>
                                        <br></br>
                                        {`${packageObj?.place}`}
                                    </td>
                                    <td style={{ textAlign: 'center' }}>
                                        <small className={`text-badge ${packageObj?.package_name}-text`}>{packageObj?.package_name}</small>
                                        to
                                        <small className={`text-badge O/W-text`}>O/W</small>
                                    </td>
                                    <td style={{ textAlign: 'center' }}>
                                        {`${packageObj?.post}`}
                                    </td>
                                    <td style={{ textAlign: 'center' }}>
                                        {`${packageObj?.end_date}`}
                                        <br></br>
                                        {`Service : ${packageObj?.service_count || 0}`}
                                    </td>
                                    <td >
                                        <div className='buttons-div' >
                                            <button title='Action' className='button-small-icon action'
                                                onClick={() => openModal(<ExpireAction packageId={packageObj?._id} packageName={packageObj?.package_name} cid={packageObj.cid} setModal={setModal}
                                                    setList={setListData} />,
                                                    "PACKAGE ACTION")}>
                                                <TbStatusChange /></button>
                                            <button title='View work card' className='button-small-icon view'
                                                onClick={() => navigate(`/admin/customer-list/${packageObj?.cid}/cards`)}>
                                                <FaEye /></button>
                                        </div>
                                    </td>
                                    {/* For Search */}
                                    <td style={{ display: 'none' }}>
                                        {packageObj?.package_name}
                                    </td>
                                </tr>)}

                            </tbody>
                        </table>




                    </TableFilter>
                </>
                    : <>
                        <Spinner height={'300px'} spin={!(!loading)} message={loading ? 'Fetch data' : 'No data'}
                            icon={!loading && <GrList />} />
                    </>}
            </AdminPage>
        </div>
    )
}

export default BlackList