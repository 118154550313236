import React from 'react'
import { changeFormValue } from '../../../redux/features/user/serviceSlice'
import { useDispatch, useSelector } from 'react-redux'
import NormalInput from '../../../components/common/inputs/NormalInput'
import { FaRegDotCircle } from "react-icons/fa";

const PumpInput = ({ title, index, type, filterCriteria, rejectCriteria }) => {
    const dispatch = useDispatch();
    const { serviceForm } = useSelector((state) => state.serviceData)


    const handleFiltered = (e, index, type, criteria) => {
        let status = null

        if (!Number(e.target.value)) {
            status = 'No'
        } else if (Number(e.target.value) < criteria) {
            status = 'Low'
        } else if (Number(e.target.value) >= criteria) {
            status = "Normal"
        }

        let array = [...(serviceForm?.pump_membrane_checking || [])]
        array[index] = {
            ...array?.[index] || {},
            index,
            type,
            filter_water: e.target.value,
            filter_water_status: status
        }
        array = array.slice(0, index + 1)

        dispatch(changeFormValue({
            ...serviceForm,
            pump_membrane_checking: array
        }))

    }

    const handleReject = (e, index, type, criteria) => {
        let status = null

        if (!Number(e.target.value)) {
            status = 'No'
        } else if (Number(e.target.value) < criteria) {
            status = 'Low'
        } else if (Number(e.target.value) >= criteria) {
            status = "Normal"
        }

        let array = [...(serviceForm?.pump_membrane_checking || [])]
        array[index] = {
            ...array?.[index] || {},
            index,
            type,
            reject_water: e.target.value,
            reject_water_status: status
        }
        array = array.slice(0, index + 1)

        dispatch(changeFormValue({
            ...serviceForm,
            pump_membrane_checking: array
        }))

    }

    return (
        <div className="pump-check-input-div">
            <div>
                <div className="sub-title"><h4><FaRegDotCircle /> {title}</h4></div>
                <div className="two-input-single-line-div">
                    <NormalInput label='Flow ltr' name={`filtered`} id={`filtered${index}`} type={'number'} min={"0"} step={"0.1"}
                        value={serviceForm?.pump_membrane_checking?.[index]?.filter_water}
                        onChangeFun={(e) => handleFiltered(e, index, type, filterCriteria)}
                    />
                    <div className="pump-div">
                        <p>{filterCriteria} ltr</p>
                        {serviceForm?.pump_membrane_checking?.[index]?.filter_water_status &&
                            <span className={`text-badge ${serviceForm?.pump_membrane_checking?.[index]?.filter_water_status}-text`}>
                                {serviceForm?.pump_membrane_checking?.[index]?.filter_water_status}</span>}
                    </div>
                </div>
                <div className="two-input-single-line-div">
                    <NormalInput label='Flow ml' name='reject' id={`reject${index}`} type={'number'} min={"0"} step={"0.1"}
                        value={serviceForm?.pump_membrane_checking?.[index]?.reject_water}
                        onChangeFun={(e) => handleReject(e, index, type, rejectCriteria)}
                    />
                    <div className="pump-div">
                        <p>{rejectCriteria} ml</p>
                        {serviceForm?.pump_membrane_checking?.[index]?.reject_water_status &&
                            <span className={`text-badge ${serviceForm?.pump_membrane_checking?.[index]?.reject_water_status}-text`}>
                                {serviceForm?.pump_membrane_checking?.[index]?.reject_water_status}</span>}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PumpInput