export const purifierSpareCategory = [
    'pump', 'membrane', 'fr', 'other'
]

export const purifierProductCategory = [
    'RO', 'UV', 'UV+UF', 'RO+UV', 'RO+UV+UF', 'RO+ALKALINE', 'RO+UV+ALKALINE', 'RO+UV+UF+ALKALINE'
]

export const complaintPriority = [
    'Normal', 'Urgent', 'Emergency'
]

export const product_usages = [
    'DOMESTIC', 'INDUSTRIAL', 'COMMERCIAL'
]