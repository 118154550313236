import React, { useState } from 'react'
import './choose-box.scss'
import NormalInput from '../../common/inputs/NormalInput'
import { YYYYMMDDFormat } from '../../../assets/javascript/formate-functions'
import { useNavigate } from 'react-router-dom'



const ServicedChooseBox = () => {
    const [form, setForm] = useState({
        from_date: YYYYMMDDFormat(new Date()),
        end_date: YYYYMMDDFormat(new Date())
    })
    const navigate = useNavigate()

    const handleChange = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        navigate('/dar-list', {
            state: form
        })
    }


    return (
        <div className="choose-box-div">
            <form action="" onSubmit={handleSubmit}>
                <NormalInput label='From date (MM/DD/YYYY)' name='from_date' type={'date'} value={form?.from_date} onChangeFun={handleChange}
                    max={form?.end_date} min={YYYYMMDDFormat(new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1))} />
                <NormalInput label='End date (MM/DD/YYYY)' name='end_date' type={'date'} value={form?.end_date} onChangeFun={handleChange}
                    max={YYYYMMDDFormat(new Date())} />
                <div className="button-div">
                    <button type={'submit'}>Select</button>
                </div>
            </form>
        </div>
    )
}

export default ServicedChooseBox