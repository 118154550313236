import React, { useState } from 'react'
import './filter-service-list.scss'
import MultiSelect from '../../common/inputs/MultiSelect';
import SelectInput from '../../common/inputs/SelectInput';
import NormalInput from '../../common/inputs/NormalInput';
import { BiLoaderAlt } from 'react-icons/bi'
import { toast } from 'react-hot-toast'
import { useSearchParams } from 'react-router-dom';


export const FilterServiceList = ({ posts, setModal }) => {

    const [searchParams, setSearchParams] = useSearchParams()
    const [element, setElement] = useState({
        post_office: [],
        customer_status: [],
        cid: null,
        type: null
    })

    const customer_status = [
        { label: 'AMC', value: 'AMC' },
        { label: 'SSP', value: 'SSP' },
        { label: 'I/W', value: 'I/W' },
        { label: 'O/W', value: 'O/W' },
        { label: 'O/C', value: 'O/C' },
    ]


    const types = [
        { option: 'This month', value: "1" },
        { option: 'Previous months', value: "0" }
    ]


    const handleMultiChange = (values, key) => {
        setElement((state) => ({ ...state, [key]: values }))
    }

    const handleChange = (e) => {
        setElement((state) => ({ ...state, [e.target.name]: e.target.value }))
    }

    const handleSubmit = (e) => {
        e.preventDefault()

        if (!element.post_office[0] && !element.customer_status[0] && !element.type && !element.cid) {
            toast.error('No filter elements')
        } else {

            const postOfficeString = element.post_office.map((obj) => obj.value).join('+')
            const customerStatusString = element.customer_status.map((obj) => obj.value).join('+')
            const cidString = element.cid
            const typeString = element.type

            const newSearchParams = new URLSearchParams(searchParams);

            if (postOfficeString) {
                newSearchParams.set('postOffice', postOfficeString);
            } else {
                newSearchParams.delete('postOffice')
            }

            if (customerStatusString) {
                newSearchParams.set('customerStatus', customerStatusString);
            } else {
                newSearchParams.delete('customerStatus')
            }

            if (cidString) {
                newSearchParams.set('cid', cidString);
            } else {
                newSearchParams.delete('cid')
            }

            if (typeString) {
                newSearchParams.set('type', typeString);
            } else {
                newSearchParams.delete('type')
            }

            setSearchParams(newSearchParams);
            setModal({ status: false })
        }

    }

    return (
        <div className="filter-service-list">
            <form action="" onSubmit={handleSubmit}>
                <NormalInput label='Customer ID' name='cid' type={'number'} value={element?.cid} isRequired={false} min={100} onChangeFun={handleChange} />

                <MultiSelect values={posts} label='Post office' onChangeFun={(selected) => handleMultiChange(selected, 'post_office')} />

                <MultiSelect values={customer_status} label='Customer status' onChangeFun={(selected) => handleMultiChange(selected, 'customer_status')} />

                <SelectInput values={types} firstOption={{ option: 'Choose', value: null }} label='Type'
                    name='type' onChangeFun={handleChange} isRequired={false} />

                <div className="button-div">
                    <button type={'submit'}>Filter</button>
                </div>
            </form>
        </div>
    )
}
