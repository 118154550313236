import React, { useState } from 'react'
import './style.scss'
import NormalInput from '../../../components/common/inputs/NormalInput'
import RadioInput from '../../../components/common/inputs/RadioInput'
import InfoBox from '../../../components/common/info-box/InfoBox'
import { BiLoaderAlt } from 'react-icons/bi'
import { serviceAxios } from '../../../config/axios'
import { toast } from 'react-hot-toast'

const ExpireAction = ({ packageId, packageName, cid, setModal, setList }) => {
    const [loading, setLoading] = useState('')
    const [form, setForm] = useState({ packageId, cid, action: '0' })

    const handleChange = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        const ask = window.confirm('Ready for submit ?')
        if (ask) {
            setLoading('submit')
            serviceAxios.post('/package-auto-expire-reaction', form).then((response) => {
                setModal({ status: false })
                setList((state) => state.filter((obj) => obj._id !== packageId))
                toast.success(response.message)
                setLoading('')
            }).catch((error) => {
                toast.error(error.message)
                setLoading('')
            })
        }
    }


    return (
        <div className="expire-action-div">
            <InfoBox description={'This is the last step to revert back to the old package'} />
            <form action="" onSubmit={handleSubmit}>
                <RadioInput label={`Continue with O/W`} name={'action'} checked={form?.action === '0'} value={'0'} onChangeFun={handleChange} /> <br></br>
                <RadioInput label={`Continue with ${packageName}`} name={'action'} checked={form?.action === '1'} value={'1'} onChangeFun={handleChange} />
                {form?.action === '1' && <>
                    <NormalInput label='Extend days' type={'number'} value={form?.extend_days} min={1} max={100} name='extend_days' onChangeFun={handleChange} />
                    <NormalInput label='Reason of extend' value={form?.reason} name='reason' onChangeFun={handleChange} />
                </>}
                <div className="button-div">
                    <button type='submit'>{loading ? <span className='loading-icon'><BiLoaderAlt /></span> : 'Submit Action'}</button>
                </div>
            </form>
        </div>
    )
}

export default ExpireAction