import React, { useEffect, useState } from 'react'
import './service.scss'
import SinglePage from '../../../components/common/page/SinglePage'
import { useLocation, useNavigate } from 'react-router-dom'
import SpinWithMessage from '../../../components/common/spinners/SpinWithMessage'
import ServiceInfo from '../../../components/user/service-details/ServiceDetails'
import { serviceAxios, customerAxios } from '../../../config/axios'
import { TbCalendarPlus } from 'react-icons/tb'
import { RiChatNewLine } from 'react-icons/ri'
import Modal from '../../../components/common/modal/Modal'
import AddDescription from '../../../components/user/add-description/AddDescription'
import ScheduleNow from '../../../components/user/schedule-now/ScheduleNow'
import { PiArrowArcRightBold } from 'react-icons/pi'
import PostponeService from '../../../components/admin/service-cancel/PostponeService'

function PendingService() {
    const location = useLocation()
    const navigate = useNavigate()
    const [loading, setLoading] = useState('fetch')
    const [customer, setCustomer] = useState({})
    const [purifier, setPurifier] = useState({})
    const [regDetails, setRegDetails] = useState({})
    const [modal, setModal] = useState({ content: null, title: null, status: false })

    const openModel = (element, title) => {
        setModal({
            content: element,
            title: title,
            status: true
        })
    }

    useEffect(() => {
        if (!location?.state?.cid) {
            navigate('/pending-service-list?page=complaint')
        }

        const apiPromises = [];
        // Customer details
        const profile = customerAxios.get(`/profile?cid=${location?.state?.cid}&detail=YES`).then((response) => {
            setCustomer(response.data)
        })
        apiPromises.push(profile)

        const product = customerAxios.get(`/service/purifier?cid=${location?.state?.cid}`).then((response) => {
            setPurifier(response.data)
        })
        apiPromises.push(product)

        if (location?.state?.reg_no) {
            const regDetails = serviceAxios.get(`/registered/${location?.state?.type}?reg_no=${location?.state?.reg_no}`).then((response) => {
                setRegDetails(response.data)
            })
            apiPromises.push(regDetails)
        }

        Promise.all(apiPromises).then(() => setLoading(''))
        // eslint-disable-next-line
    }, [])

    return (
        <div className='pending-service-div'>
            <Modal modal={modal} setModal={setModal} />
            <SinglePage titleArray={[`CID : ${location?.state?.cid}`, `Type : ${location?.state?.type}`]}>
                {loading === 'fetch'
                    ? <>
                        <SpinWithMessage message='fetch data...' />
                    </>
                    : <>
                        <div className='service-details-main-div'>
                            <div className="page-border-div">
                                <div className="content-div">
                                    <ServiceInfo customer={customer} purifier={purifier} regDetails={regDetails}
                                        type={location?.state?.type} blacked={location?.state?.blacked} before_status={location?.state?.before_status} />
                                </div>
                                <div className="button-div">
                                    {!location?.state?.reg_no && !location?.state?.blacked && <button className='cancel-button'
                                        onClick={() => openModel(<PostponeService data={{
                                            cid: customer.cid,
                                            next_service_date: purifier?.next_periodical_service_date || null,
                                            package_expiry_date: purifier?.package_expiry_date || null
                                        }} setModal={setModal} isTechnician={true} />, 'Postpone Service')}>
                                        <PiArrowArcRightBold /><span>Postpone</span></button>}
                                    <button className='description-button'
                                        onClick={() => openModel(<AddDescription cid={customer.cid} setModal={setModal} setCustomer={setCustomer} />, 'Add description')}>
                                        <RiChatNewLine /><span>Add description</span></button>
                                    {((purifier?.pending_ssp_token || 0) < 1 && customer?.purifier_customer_status === 'SSP') ? "" :
                                        <button className='green' onClick={() => openModel(<ScheduleNow reg_no={regDetails?.reg_no || null} cid={customer.cid} type={location?.state?.type}
                                            zone_id={customer?.zone_id} service_index={(purifier.service_count?.length || 0) + 1} />, 'Service Schedule')}>
                                            <TbCalendarPlus /><span>Schedule now</span></button>}
                                </div>
                            </div>
                        </div>
                    </>}
            </SinglePage>
        </div>
    )
}

export default PendingService