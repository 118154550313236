import React, { useEffect, useState } from 'react'
import '../add-edit-zone/add-edit-zone.scss'
import NormalInput from '../../common/inputs/NormalInput';
import SelectInput from '../../common/inputs/SelectInput';
import { BiLoaderAlt } from 'react-icons/bi'
import { userAxios } from '../../../config/axios';
import { product_usages } from '../../../assets/javascript/const-data'
import toast from 'react-hot-toast';


const CreateCustomer = ({ setCustomerList, setModal }) => {
    const [loading, setLoading] = useState('fetch')
    const [productList, setProductList] = useState([])
    const [usageList, setUsageList] = useState([])
    const [form, setForm] = useState({})

    const packageList = [
        { option: 'I/W', value: 'I/W' },
        { option: 'O/W', value: 'O/W' },
        { option: 'O/C', value: 'O/C' }
    ]

    const handleChange = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading('submit')
        userAxios.post('/customer', form).then((response) => {
            setCustomerList((state) => [response.data, ...state])
            toast.success('Creation success!')
            setLoading('')
            setModal({ status: false })
        }).catch((error) => {
            toast.error(error.message)
            setLoading('')
        })
    }


    useEffect(() => {
        userAxios.get('/products?all=YES&spare_list=NO').then((response) => {
            const options = response.data?.map((product) => {
                return {
                    option: `${product.product_name} (${product.product_category})`,
                    value: product._id
                }
            })
            setProductList(options)
            setLoading('')
        })
        setUsageList(product_usages.map((value) => ({ option: value, value: value })))
    }, [])

    return (
        <div className="create-customer-form-div add-edit-zone-div">
            <form action="" onSubmit={handleSubmit}>
                <NormalInput label='Customer ID' name='cid' value={form?.cid} type={'number'} onChangeFun={handleChange} min={100} />
                <SelectInput label='Purifier status' values={packageList} firstOption={{ option: 'Choose...', value: '' }}
                    name='purifier_customer_status' onChangeFun={handleChange} />
                <SelectInput label='Product name' values={productList} firstOption={{ option: 'Choose...', value: '' }}
                    name='product_id' onChangeFun={handleChange} isRequired={form?.purifier_customer_status === 'I/W' ? true : false}/>
                <SelectInput label='Product usage' values={usageList} firstOption={{ option: 'Choose...', value: '' }}
                    name='product_usage' onChangeFun={handleChange} isRequired={form?.purifier_customer_status === 'I/W' ? true : false}/>
                <NormalInput label='Installation Srl no' name='installation_srl_no' value={form.installation_srl_no} type={'text'}
                    onChangeFun={handleChange} isRequired={false} />
                <NormalInput label='Installation Date' name='installed_at' value={form.installed_at} type={'date'}
                    onChangeFun={handleChange} isRequired={form?.purifier_customer_status === 'I/W' ? true : false} />
                <div className="button-div">
                    <button type={loading ? 'button' : 'submit'}>{loading ? <span className='loading-icon'><BiLoaderAlt /></span> : 'Create'}</button>
                </div>
            </form>
            {loading === 'fetch' && <div className="loading-pop">
                <p>Loading...</p>
            </div>}
        </div>
    )
}

export default CreateCustomer