export const findTdsStatus = (inValue = 0, outValue = 0, productType = 'RO') => {
    // Product Type = UV : UV / ALKALINE included : ALKALINE / Normal RO : RO

    if (typeof inValue !== 'number' || typeof outValue !== 'number') {
        throw Error('TDS inValue / outValue is not Number')
    }

    // NO
    if (!inValue || !outValue) {
        return 'No'
    }

    // LOW
    if (outValue < 5) {
        return 'Low'
    }

    // TYPE : UV
    if (productType === 'UV') {
        if (outValue > (inValue + 50)) {
            return 'High'
        } else {
            return 'Normal'
        }
    }

    // TYPE : ALKALINE
    if (productType === 'ALKALINE') {
        if (outValue > ((inValue * 0.15) + 40)) {
            return 'High'
        } else {
            return 'Normal'
        }
    }

    // TYPE : RO
    if (outValue > inValue * 0.15) {
        return 'High'
    } else {
        return 'Normal'
    }

}