import React, { useEffect, useState } from 'react'
import './style.scss'
import { changeFormValue, nextAction, clearNext } from '../../../redux/features/user/serviceSlice'
import { useDispatch, useSelector } from 'react-redux'
import RadioInput from '../../common/inputs/RadioInput'
import NormalInput from '../../../components/common/inputs/NormalInput'
import SelectInput from '../../../components/common/inputs/SelectInput'
import { extractNumbers } from '../../../assets/javascript/find-functions'
import { useSearchParams } from 'react-router-dom'
import { findTdsStatus } from '../../../assets/javascript/service-from-helper'


const SectionOne = React.memo(({ resources, purifier, showInput, product }) => {
    const dispatch = useDispatch();
    const { serviceForm } = useSelector((state) => state.serviceData)
    const [leakOptions, setLeakOptions] = useState([])
    const [crackOptions, setCrackOptions] = useState([])
    // eslint-disable-next-line
    const [searchParams, setSearchParams] = useSearchParams();


    const pumpList = [
        { option: 'E-CHEN', value: 'E-CHEN', selected: "E-CHEN" === serviceForm?.before_checking_data?.pump },
        { option: 'KEMFLOW', value: 'KEMFLOW', selected: "KEMFLOW" === serviceForm?.before_checking_data?.pump },
        { option: 'E-FLOW', value: 'E-FLOW', selected: "E-FLOW" === serviceForm?.before_checking_data?.pump },
        { option: 'CRUZE', value: 'CRUZE', selected: "CRUZE" === serviceForm?.before_checking_data?.pump },
        { option: 'WAVE', value: 'WAVE', selected: "'WAVE" === serviceForm?.before_checking_data?.pump },
        { option: 'PENTAIR', value: 'PENTAIR', selected: "PENTAIR" === serviceForm?.before_checking_data?.pump },
        { option: 'other', value: 'other', selected: "other" === serviceForm?.before_checking_data?.pump },
    ]

    const frList = [
        { option: '300', value: '300', selected: "300" === serviceForm?.before_checking_data?.fr },
        { option: '350', value: '350', selected: "350" === serviceForm?.before_checking_data?.fr },
        { option: '400', value: '400', selected: "400" === serviceForm?.before_checking_data?.fr },
        { option: '450', value: '450', selected: "450" === serviceForm?.before_checking_data?.fr },
    ]

    // Handle change before service checking data
    const handleChangeWorkingStatus = (e) => {
        if (e.target.value === 'yes') {
            dispatch(changeFormValue({
                ...serviceForm,
                before_checking_data: {
                    ...serviceForm?.before_checking_data,
                    purifier_working_status: true
                },
                flow_checking_second_time: {
                    ...(serviceForm?.flow_checking_second_time || {}),
                    tds_status: undefined,
                    input_tds: undefined,
                    output_tds: undefined,
                }
            }))
        } else {
            dispatch(changeFormValue({
                ...serviceForm,
                before_checking_data: {
                    ...serviceForm?.before_checking_data,
                    purifier_working_status: false,
                    input_tds: null,
                    output_tds: null,
                    tds_status: null,
                    filtered_water_flow_status: null,
                    filtered_water_flow_ltrs: null,
                    reject_water_flow_status: null,
                    reject_water_flow_ltrs: null,
                    ph: null,
                    ph_status: null
                },
                evaluation_data: {
                    ...serviceForm?.evaluation_data,
                    input_tds: null,
                    output_tds: null,
                    tds_status: null,
                    filtered_water_flow_status: null,
                    filtered_water_flow_ltrs: null,
                    reject_water_flow_status: null,
                    reject_water_flow_ltrs: null,
                    ph: null,
                    ph_status: null
                }
            }))
        }
    }

    const handleBeforeServiceChecking = (e) => {

        let productFilterType = 'RO'
        if (['UV', 'UV+UF'].includes(product?.product_category)) {
            productFilterType = 'UV'
        } else if (['RO+ALKALINE', 'RO+UV+ALKALINE', 'RO+UV+UF+ALKALINE'].includes(product?.product_category)) {
            productFilterType = 'ALKALINE'
        }

        dispatch(changeFormValue({
            ...serviceForm,
            before_checking_data: {
                ...serviceForm?.before_checking_data,
                [e.target.name]: e.target.value
            },
            evaluation_data: {
                ...serviceForm?.evaluation_data,
                [e.target.name]: e.target.value
            }
        }))

        if (e.target.name === 'filtered_water_flow_ltrs') {
            let status = null
            if (Number(e.target.value) === 0) {
                status = 'No'
            } else if (Number(e.target.value) < 11) {
                status = 'Low'
            } else if (Number(e.target.value) >= 11) {
                status = 'Normal'
            }

            dispatch(changeFormValue({
                ...serviceForm,
                before_checking_data: {
                    ...serviceForm?.before_checking_data,
                    filtered_water_flow_status: status,
                    [e.target.name]: e.target.value
                },
                evaluation_data: {
                    ...serviceForm?.evaluation_data,
                    filtered_water_flow_status: status,
                    [e.target.name]: e.target.value
                }
            }))
        }

        if (e.target.name === 'reject_water_flow_ltrs') {
            let status = null
            if (Number(e.target.value) === 0) {
                status = 'No'
            } else if (Number(e.target.value) < extractNumbers(serviceForm?.before_checking_data?.fr || 0)) {
                status = 'Low'
            } else if (Number(e.target.value) >= extractNumbers(serviceForm?.before_checking_data?.fr || 0)) {
                status = 'Normal'
            }

            dispatch(changeFormValue({
                ...serviceForm,
                before_checking_data: {
                    ...serviceForm?.before_checking_data,
                    reject_water_flow_status: status,
                    [e.target.name]: e.target.value
                },
                evaluation_data: {
                    ...serviceForm?.evaluation_data,
                    reject_water_flow_status: status,
                    [e.target.name]: e.target.value
                }
            }))
        }

        if (e.target.name === 'fr') {
            let status = null
            if (Number(e.target.value || 0) === 0) {
                status = 'No'
            } else if (Number(e.target.value) > Number(serviceForm?.before_checking_data?.reject_water_flow_ltrs || 0)) {
                status = 'Low'
            } else if (Number(e.target.value) <= Number(serviceForm?.before_checking_data?.reject_water_flow_ltrs || 0)) {
                status = 'Normal'
            }

            dispatch(changeFormValue({
                ...serviceForm,
                before_checking_data: {
                    ...serviceForm?.before_checking_data,
                    reject_water_flow_status: status,
                    [e.target.name]: e.target.value
                },
                evaluation_data: {
                    ...serviceForm?.evaluation_data,
                    reject_water_flow_status: status,
                    [e.target.name]: e.target.value
                }
            }))
        }

        if (e.target.name === 'input_tds') {


            let status = findTdsStatus(Number(e.target.value), Number(serviceForm?.before_checking_data?.output_tds), productFilterType)

            dispatch(changeFormValue({
                ...serviceForm,
                before_checking_data: {
                    ...serviceForm?.before_checking_data,
                    tds_status: status,
                    [e.target.name]: e.target.value
                },
                evaluation_data: {
                    ...serviceForm?.evaluation_data,
                    tds_status: status,
                    [e.target.name]: e.target.value
                }
            }))
        }

        if (e.target.name === 'output_tds') {


            let status = findTdsStatus(Number(serviceForm?.before_checking_data?.input_tds), Number(e.target.value), productFilterType)

            dispatch(changeFormValue({
                ...serviceForm,
                before_checking_data: {
                    ...serviceForm?.before_checking_data,
                    tds_status: status,
                    [e.target.name]: e.target.value
                },
                evaluation_data: {
                    ...serviceForm?.evaluation_data,
                    tds_status: status,
                    [e.target.name]: e.target.value
                }
            }))
        }

        if (e.target.name === 'ph') {
            let status = null
            if (!Number(e.target.value)) {
                status = 'No'
            } else if (6.5 > Number(e.target.value)) {
                status = 'Low'
            } else if (8.5 < Number(e.target.value)) {
                status = 'High'
            } else {
                status = 'Normal'
            }

            dispatch(changeFormValue({
                ...serviceForm,
                before_checking_data: {
                    ...serviceForm?.before_checking_data,
                    ph_status: status,
                    [e.target.name]: e.target.value
                },
                evaluation_data: {
                    ...serviceForm?.evaluation_data,
                    ph_status: status,
                    [e.target.name]: e.target.value
                }
            }))
        }

    }

    const handleLeakInputs = (e) => {
        if (e.target.value === 'yes') {
            dispatch(changeFormValue({
                ...serviceForm,
                before_checking_data: {
                    ...serviceForm?.before_checking_data,
                    leak: true
                }
            }))
        } else {
            dispatch(changeFormValue({
                ...serviceForm,
                before_checking_data: {
                    ...serviceForm?.before_checking_data,
                    leak: false,
                    leak_description: null
                }
            }))
        }
    }

    const handleScratchCrackInputs = (e) => {
        if (e.target.value === 'yes') {
            dispatch(changeFormValue({
                ...serviceForm,
                before_checking_data: {
                    ...serviceForm?.before_checking_data,
                    scratch_crack: true
                }
            }))
        } else {
            dispatch(changeFormValue({
                ...serviceForm,
                before_checking_data: {
                    ...serviceForm?.before_checking_data,
                    scratch_crack: false,
                    scratch_crack_description: null
                }
            }))
        }
    }

    const handleSectionSubmit = (e) => {
        e.preventDefault()

        if (serviceForm?.before_checking_data?.filtered_water_flow_status !== 'Normal' && showInput?.pump) {
            // to section two   
            dispatch(nextAction(['with_new_spun_filter', 'current_condition']))
            setSearchParams(`section=with_new_spun_filter`)
        } else if (serviceForm?.before_checking_data?.reject_water_flow_status !== 'Normal' && showInput?.rejectWater) {
            // to section two
            dispatch(nextAction(['with_new_spun_filter', 'current_condition']))
            setSearchParams(`section=with_new_spun_filter`)
        } else if (serviceForm?.before_checking_data?.tds_status !== 'Normal' && showInput?.pump) {
            // to section four
            dispatch(nextAction(['pump_and_membrane_checking', 'current_condition']))
            setSearchParams(`section=pump_and_membrane_checking`)
            dispatch(clearNext(['with_new_spun_filter']))
            dispatch(changeFormValue({
                ...serviceForm,
                flow_checking_second_time: undefined
            }))

        } else {
            // to section four
            dispatch(nextAction(['inspection_report', 'current_condition']))
            setSearchParams(`section=inspection_report`)
            dispatch(clearNext(['with_new_spun_filter', 'pump_and_membrane_checking']))
            dispatch(changeFormValue({
                ...serviceForm,
                flow_checking_second_time: undefined,
                pump_membrane_checking: undefined
            }))
        }
    }


    useEffect(() => {
        setLeakOptions(resources?.leak_descriptions?.map((item) => ({
            option: item.option,
            value: item.option,
            selected: serviceForm?.before_checking_data?.leak_description === item.option
        })))
        setCrackOptions(resources?.crack_descriptions?.map((item) => ({
            option: item.option,
            value: item.option,
            selected: serviceForm?.before_checking_data?.scratch_crack_description === item.option
        })))
        // eslint-disable-next-line
    }, [resources])




    return (
        <div className="service-form-section-div">
            <div className="title-section">
                <h3>Current condition</h3>
                <p>Enter current condition for purifier</p>
            </div>
            <div className="form-div">
                <form action="" onSubmit={handleSectionSubmit}>

                    {/* Purifier Status */}
                    <div className="radio-input-border-div">
                        <div className="sub-title"><h4>Purifier status</h4></div>
                        <div className="radio-inputs">
                            <RadioInput label={"Working"} name={'purifier_working_status'} value={'yes'} onChangeFun={handleChangeWorkingStatus}
                                checked={serviceForm?.before_checking_data?.purifier_working_status} />
                            <RadioInput label={"Not working"} name={'purifier_working_status'} value={'no'} onChangeFun={handleChangeWorkingStatus}
                                checked={!serviceForm?.before_checking_data?.purifier_working_status} />
                        </div>
                    </div>

                    {/* Input Output */}
                    {serviceForm?.before_checking_data?.purifier_working_status &&
                        <div>
                            <div className="sub-title">
                                <h4>TDS</h4>
                                {serviceForm?.before_checking_data?.tds_status &&
                                    <span className={`text-badge ${serviceForm?.before_checking_data?.tds_status}-text`}>{serviceForm?.before_checking_data?.tds_status}</span>}
                            </div>
                            <div className="two-input-single-line-div">
                                <NormalInput label='Input TDS' name='input_tds' value={serviceForm?.before_checking_data?.input_tds || null} type={'number'}
                                    onChangeFun={handleBeforeServiceChecking} min={"0"} step={"0.1"} />
                                <NormalInput label='Output TDS' name='output_tds' value={serviceForm?.before_checking_data?.output_tds || null} type={'number'} min={"0"} step={"0.1"}
                                    onChangeFun={handleBeforeServiceChecking} />
                            </div>
                        </div>}

                    {/* Filtered water flow */}
                    <div>
                        {serviceForm?.before_checking_data?.purifier_working_status && <div className="sub-title">
                            <h4>Filtered water flow</h4>
                            {serviceForm?.before_checking_data?.filtered_water_flow_status &&
                                <span className={`text-badge ${serviceForm?.before_checking_data?.filtered_water_flow_status}-text`}>
                                    {serviceForm?.before_checking_data?.filtered_water_flow_status}</span>}
                        </div>}
                        <div className="two-input-single-line-div">
                            {serviceForm?.before_checking_data?.purifier_working_status && <NormalInput label='Current flow' name='filtered_water_flow_ltrs'
                                value={serviceForm?.before_checking_data?.filtered_water_flow_ltrs || null} type={'number'} min={"0"} step={"0.1"}
                                onChangeFun={handleBeforeServiceChecking} />}
                            {showInput?.pump && <SelectInput label='Pump' name='pump' values={pumpList} firstOption={{ option: 'Choose...', value: '' }}
                                onChangeFun={handleBeforeServiceChecking} />}
                        </div>
                    </div>

                    {/*Reject water flow */}
                    <div>
                        {serviceForm?.before_checking_data?.purifier_working_status && showInput?.rejectWater && <div className="sub-title">
                            <h4>Reject water flow</h4>
                            {serviceForm?.before_checking_data?.reject_water_flow_status &&
                                <span className={`text-badge ${serviceForm?.before_checking_data?.reject_water_flow_status}-text`}>
                                    {serviceForm?.before_checking_data?.reject_water_flow_status}</span>}
                        </div>}
                        <div className="two-input-single-line-div">
                            {serviceForm?.before_checking_data?.purifier_working_status && showInput?.rejectWater && <NormalInput label='Current flow' name='reject_water_flow_ltrs'
                                value={serviceForm?.before_checking_data?.reject_water_flow_ltrs || null} type={'number'} min={"0"} step={"0.1"}
                                onChangeFun={handleBeforeServiceChecking} />}
                            {showInput?.fr && <SelectInput label='FR' name='fr' values={frList} firstOption={{ option: 'Choose...', value: '' }}
                                onChangeFun={handleBeforeServiceChecking} />}
                        </div>
                    </div>
                    {/* PH */}
                    <div>
                        {serviceForm?.before_checking_data?.purifier_working_status && showInput.ph && <div className="sub-title">
                            <h4>Potential of Hydrogen (PH)</h4>
                            {serviceForm?.before_checking_data?.ph_status &&
                                <span className={`text-badge ${serviceForm?.before_checking_data?.ph_status}-text`}>
                                    {serviceForm?.before_checking_data?.ph_status}</span>}
                        </div>}
                        <div className="two-input-single-line-div">
                            {serviceForm?.before_checking_data?.purifier_working_status && showInput.ph &&
                                <NormalInput label='' name='ph'
                                    value={serviceForm?.before_checking_data?.ph || null} type={'number'} min={"0"} max={14} step={"0.1"}
                                    onChangeFun={handleBeforeServiceChecking} />}
                        </div>
                    </div>

                    {/* Leak */}
                    <div className="radio-input-border-div">
                        <div className="sub-title"><h4>Leak</h4></div>
                        <div className="radio-inputs">
                            <RadioInput label={"Yes"} name={'leak'} value={'yes'} onChangeFun={handleLeakInputs}
                                checked={serviceForm?.before_checking_data?.leak} />
                            <RadioInput label={"No"} name={'leak'} value={'no'} onChangeFun={handleLeakInputs}
                                checked={!serviceForm?.before_checking_data?.leak} />
                        </div>
                        {serviceForm?.before_checking_data?.leak && <SelectInput label='Description' name='leak_description'
                            values={leakOptions} firstOption={{ option: 'Choose...', value: '' }}
                            onChangeFun={handleBeforeServiceChecking} />}
                    </div>

                    {/* Scratches */}
                    <div className="radio-input-border-div">
                        <div className="sub-title"><h4>Scratches & Cracks</h4></div>
                        <div className="radio-inputs">
                            <RadioInput label={"Yes"} name={'scratch_crack'} value={'yes'} onChangeFun={handleScratchCrackInputs}
                                checked={serviceForm?.before_checking_data?.scratch_crack} />
                            <RadioInput label={"No"} name={'scratch_crack'} value={'no'} onChangeFun={handleScratchCrackInputs}
                                checked={!serviceForm?.before_checking_data?.scratch_crack} />
                        </div>
                        {serviceForm?.before_checking_data?.scratch_crack && <SelectInput label='Description' name='scratch_crack_description'
                            values={crackOptions} firstOption={{ option: 'Choose...', value: '' }}
                            onChangeFun={handleBeforeServiceChecking} />}
                    </div>

                    {/* Next Buttons */}
                    <div className="handle-buttons-div">
                        <button type='submit' className='next'>Next</button>
                    </div>

                </form>
            </div >
        </div >
    )
})

export default SectionOne