import React, { useState } from 'react'
import './style.scss'
import NormalInput from '../../common/inputs/NormalInput'
import { useSelector } from 'react-redux'
import { userAxios } from '../../../config/axios'
import { BiLoaderAlt } from 'react-icons/bi'
import toast from 'react-hot-toast'
import { YYYYMMDDFormat } from '../../../assets/javascript/formate-functions'
import { getDaysFromISODate } from '../../../assets/javascript/find-functions'

const AddKms = ({ data, setModal, setKmsList }) => {

    const [form, setForm] = useState({ date: data?.date || YYYYMMDDFormat(new Date()), kms: data?.kms })
    const { user } = useSelector((state) => state.userAuth)
    const [loading, setLoading] = useState(false)

    const handleChange = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        })
    }


    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true)
        let obj = {
            ...form,
            tech_id: user?._id
        }
        userAxios.put('/technician/kms', obj).then((response) => {
            let flag = true
            setKmsList((state) => state?.map((oneKms) => {
                if (oneKms?.date === form.date) {
                    flag = false
                    return obj
                }
                return oneKms
            }))

            if (flag) {
                setKmsList((state) => ([
                    obj,
                    ...state
                ]))
            }

            toast.success(response.message)
            setLoading(false)
            setModal({ status: false })

        })
    }

    return (
        <div className="add-kms-div">
            <form action="" onSubmit={handleSubmit}>
                <NormalInput label='Date' value={form?.date} type={'date'} name='date' onChangeFun={handleChange}
                    max={YYYYMMDDFormat(new Date())} min={getDaysFromISODate(new Date(), -2)} />
                <NormalInput label='kilometers' value={form?.kms} name='kms' onChangeFun={handleChange} />
                <div className="button-div">
                    <button type='submit'>{loading ? <span className='loading-icon'><BiLoaderAlt /></span> : "Submit"}</button>
                </div>
            </form>
        </div>
    )
}

export default AddKms