import React, { useEffect, useState } from 'react'
import '../service-form-comp/style.scss';
import './work-category.scss'
import { IoIosArrowForward } from "react-icons/io";
import SelectInput from '../../common/inputs/SelectInput';
import RadioInput from '../../common/inputs/RadioInput';
import NormalInput from '../../common/inputs/NormalInput';
import CheckboxInput from '../../common/inputs/CheckboxInput';

import { GiTransparentTubes } from "react-icons/gi";
import { useDispatch, useSelector } from 'react-redux';
import { changeFormValue, nextAction } from '../../../redux/features/user/serviceSlice'
import { product_usages } from '../../../assets/javascript/const-data'
import { useSearchParams } from 'react-router-dom'

const WorkCategory = ({ purifier, showInput, eligibility, reg_type, customer, product, resources, setSpareType, aboutWork }) => {
    const dispatch = useDispatch()
    const { serviceForm } = useSelector((state) => state.serviceData)
    // eslint-disable-next-line 
    const [searchParams, setSearchParams] = useSearchParams();
    const [descriptions, setDescriptions] = useState([])
    const [serviceChargeList, setServiceChargeList] = useState([])
    const [repeatReasons, setRepeatReasons] = useState([])
    const [productUsageList, setProductUsageList] = useState([])
    const [purifierList, setPurifierList] = useState([])

    const extraServiceChargeList = [
        { option: '100', value: '100', selected: "100" === `${serviceForm?.bill_data?.extra_charge_est}` },
        { option: '200', value: '200', selected: "200" === `${serviceForm?.bill_data?.extra_charge_est}` },
        { option: '300', value: '300', selected: "300" === `${serviceForm?.bill_data?.extra_charge_est}` },
        { option: '400', value: '400', selected: "400" === `${serviceForm?.bill_data?.extra_charge_est}` },
        { option: '500', value: '500', selected: "500" === `${serviceForm?.bill_data?.extra_charge_est}` },
        { option: '600', value: '600', selected: "600" === `${serviceForm?.bill_data?.extra_charge_est}` },
        { option: '700', value: '700', selected: "700" === `${serviceForm?.bill_data?.extra_charge_est}` },
        { option: '800', value: '800', selected: "800" === `${serviceForm?.bill_data?.extra_charge_est}` },
        { option: '900', value: '900', selected: "900" === `${serviceForm?.bill_data?.extra_charge_est}` },
        { option: '1000', value: '1000', selected: "1000" === `${serviceForm?.bill_data?.extra_charge_est}` },
    ]

    // Reset work
    const resetWork = () => {
        const ask = window.confirm('Are you reset work ?')
        if (ask) {
            dispatch(changeFormValue({
                ...serviceForm,
                work_category_selected: undefined,
                work_method: undefined,
                work_type: undefined,
                work: undefined,
                package_id: undefined,
                service_index: undefined,
                complaint_index: undefined,
                current_ssp_card_no: undefined,
                purifier_customer_status: undefined,
                service_data: undefined,
                bill_data: undefined,
                call_details: undefined,
                primary_spares: undefined,
                secondary_spares: undefined,
                special_spares: undefined,
                cabin_change: undefined
            }))
        }
    }

    // Above 24 
    const handleAbout24 = (e) => {
        if (e.target.value === 'yes') {
            dispatch(changeFormValue({
                ...serviceForm,
                service_data: {
                    ...serviceForm.service_data,
                    above_24: true
                },
                call_details: {
                    ...serviceForm.call_details,
                    above_24_kms: 1
                }
            }))
        } else {
            dispatch(changeFormValue({
                ...serviceForm,
                service_data: {
                    ...serviceForm.service_data,
                    above_24: false
                },
                call_details: {
                    ...serviceForm.call_details,
                    above_24_kms: 0
                }
            }))
        }
    }

    // CabinChange
    const handleCabinChange = (e) => {
        if (e.target.name === 'product_id' || e.target.name === 'product_usage') {
            dispatch(changeFormValue({
                ...serviceForm,
                cabin_details: {
                    ...(serviceForm.cabin_details || {}),
                    [e.target.name]: e.target.value
                }
            }))
            return;
        }

        if (e.target.value === 'yes') {
            dispatch(changeFormValue({
                ...serviceForm,
                service_data: {
                    ...serviceForm.service_data,
                    cabin_change: true
                }
            }))
        } else {
            dispatch(changeFormValue({
                ...serviceForm,
                service_data: {
                    ...serviceForm.service_data,
                    cabin_change: false,
                }
            }))
        }
    }

    // Handle Repeat
    const handleRepeat = (e) => {
        if (e.target.name === 'repeat_work' && e.target.value === 'yes') {
            dispatch(changeFormValue({
                ...serviceForm,
                service_data: {
                    ...serviceForm.service_data,
                    repeat_tech: true,
                    why_not_repeat: undefined,
                    extra_charge_reason: undefined,
                },
                bill_data: {
                    ...serviceForm.bill_data,
                    extra_charge_est: undefined,
                    extra_charge_tally: undefined,
                    extra_charge_receivable: undefined,
                },
                call_details: {
                    ...serviceForm.call_details,
                    extra_work: undefined
                }
            }))
        } else if (e.target.name === 'repeat_work') {
            dispatch(changeFormValue({
                ...serviceForm,
                service_data: {
                    ...serviceForm.service_data,
                    repeat_tech: false,
                    extra_charge_reason: undefined
                },
                bill_data: {
                    ...serviceForm.bill_data,
                    extra_charge_est: undefined,
                    extra_charge_tally: undefined,
                    extra_charge_receivable: undefined,
                },
                call_details: {
                    ...serviceForm.call_details,
                    extra_work: undefined
                }
            }))
        }
        if (e.target.name === 'why_not-repeat') {
            dispatch(changeFormValue({
                ...serviceForm,
                service_data: {
                    ...serviceForm.service_data,
                    why_not_repeat: e.target.value
                }
            }))
        }
    }

    // Handle normal
    const handleServiceDataOnly = (e) => {
        dispatch(changeFormValue({
            ...serviceForm,
            service_data: {
                ...serviceForm.service_data,
                [e.target.name]: e.target.value
            }
        }))
    }

    const handlePCU = (e) => {
        dispatch(changeFormValue({
            ...serviceForm,
            service_data: {
                ...serviceForm.service_data,
                package_continuity_uncomforted: !serviceForm?.service_data?.package_continuity_uncomforted
            }
        }))
    }

    const handleServiceCharge = (e) => {
        const indexOfCharge = aboutWork?.service_charge_est.indexOf(Number(e.target.value))

        dispatch(changeFormValue({
            ...serviceForm,
            bill_data: {
                ...serviceForm.bill_data,
                service_charge_est: aboutWork?.service_charge_est[indexOfCharge],
                service_charge_tally: aboutWork?.service_charge_receivable[indexOfCharge],
                service_charge_receivable: aboutWork?.service_charge_receivable[indexOfCharge]
            },
            call_details: {
                ...serviceForm.call_details,
                primary_secondary: aboutWork?.call[indexOfCharge]
            }
        }))

    }

    const handleExtraCharge = (e) => {

        const currentCharge = serviceForm?.bill_data?.extra_charge_est || 0
        const callDiff = Number(e.target.value - currentCharge) / 100
        const nowCall = (serviceForm?.call_details?.extra_work || 0) + callDiff

        dispatch(changeFormValue({
            ...serviceForm,
            service_data: {
                ...serviceForm.service_data,
                extra_charge_reason: (e.target.name === 'extra_charge' && e.target.value === '')
                    ? ""
                    : serviceForm.service_data.extra_charge_reason
            },
            bill_data: {
                ...serviceForm.bill_data,
                extra_charge_est: Number(e.target.value),
                extra_charge_tally: (customer?.purifier_customer_status === 'O/W' || customer?.purifier_customer_status === 'O/C') ? Number(e.target.value) : 0,
                extra_charge_receivable: (customer?.purifier_customer_status === 'O/W' || customer?.purifier_customer_status === 'O/C') ? Number(e.target.value) : 0
            },
            call_details: {
                ...serviceForm.call_details,
                extra_work: nowCall
            }
        }))

    }

    const handleSubmit = (e) => {
        e.preventDefault();

        if (!serviceForm?.primary_spares?.[0] && !serviceForm?.secondary_spares?.[0] && !serviceForm?.special_spares?.[0]) {
            const ask = window.confirm(`You haven't selected any spare parts. Do you want to proceed?`)
            if (ask) {
                dispatch(nextAction(['review', 'work_category']))
                setSearchParams(`section=review`)
            }
        } else {
            dispatch(nextAction(['review', 'work_category']))
            setSearchParams(`section=review`)
        }
    }

    // extra_charge_reasons
    useEffect(() => {

        setDescriptions(resources?.extra_charge_reasons?.map((item) => ({
            option: item.option,
            value: item.option,
            selected: serviceForm?.service_data?.extra_charge_reason === item.option
        })))

        setServiceChargeList(aboutWork?.service_charge_est?.map((charge) => ({
            option: charge,
            value: charge,
            selected: serviceForm?.bill_data?.service_charge_est === charge
        })))

        setRepeatReasons(resources?.work_repeat_reasons?.map((item) => ({
            option: item.option,
            value: item.option,
            selected: serviceForm?.service_data?.why_not_repeat === item.option
        })))

        setProductUsageList(product_usages.map((item) => (
            { option: item, value: item, selected: serviceForm?.cabin_details?.product_usage === item || purifier?.product_usage === item }
        )))

        setPurifierList(resources?.product_list?.map((product) => ({
            option: `${product.product_name} (${product.product_category})`,
            value: product._id,
            selected: serviceForm?.cabin_details?.product_id === product._id || purifier?.product_id === product._id
        })))

        // eslint-disable-next-line
    }, [resources, aboutWork, purifier])

    return (
        <div className="work-category-div service-form-section-div">
            <form action="" onSubmit={handleSubmit}>
                <div className="form-div">
                    <div className="work-content-div">
                        {/* Not for machine checkup / required */}
                        {aboutWork?.spares_allow?.primary && < div className={serviceForm?.primary_spares?.length ? "box-div selected" : 'box-div'}
                            onClick={() => setSpareType('Primary')}>
                            <div className="icon-div">
                                {serviceForm?.primary_spares?.length ?
                                    <p>{serviceForm?.primary_spares?.length}</p>
                                    : <GiTransparentTubes />
                                }
                            </div>
                            <div className="text-div">
                                <p>Primary spare</p>
                            </div>
                            <span>
                                <IoIosArrowForward />
                            </span>
                        </div>}
                        {aboutWork?.spares_allow?.secondary && <div className={serviceForm?.secondary_spares?.length ? "box-div selected" : 'box-div'}
                            onClick={() => setSpareType('Secondary')}>
                            <div className="icon-div">
                                {serviceForm?.secondary_spares?.length ?
                                    <p>{serviceForm?.secondary_spares?.length}</p>
                                    : <GiTransparentTubes />
                                }
                            </div>
                            <div className="text-div">
                                <p>Secondary spare</p>
                            </div>
                            <span>
                                <IoIosArrowForward />
                            </span>
                        </div>}
                        {aboutWork?.spares_allow?.special && <div className={serviceForm?.special_spares?.length ? "box-div selected" : 'box-div'}
                            onClick={() => setSpareType('Special')}>
                            <div className="icon-div">
                                {serviceForm?.special_spares?.length ?
                                    <p>{serviceForm?.special_spares?.length}</p>
                                    : <GiTransparentTubes />
                                }
                            </div>
                            <div className="text-div">
                                <p>Special work spare</p>
                            </div>
                            <span>
                                <IoIosArrowForward />
                            </span>
                        </div>}
                    </div>

                    <div className="form-section">
                        {!serviceForm?.service_data?.repeat_tech &&
                            <>
                                {/* Auto and manual */}
                                <SelectInput label='Service charge' name='service_charge' values={serviceChargeList}
                                    onChangeFun={handleServiceCharge} />
                                {/* Extra work Service charge */}
                                {aboutWork?.extra_charge && <SelectInput label='Extra work charge' name='extra_charge' values={extraServiceChargeList}
                                    firstOption={{ option: 'Choose...', value: '' }} onChangeFun={handleExtraCharge}
                                    isRequired={false} />}
                                {/* Reason */}
                                {serviceForm?.call_details?.extra_work ? <SelectInput label='Description for extra charge' name='extra_charge_reason'
                                    values={descriptions} firstOption={{ option: 'Choose...', value: '' }}
                                    onChangeFun={handleServiceDataOnly} /> : ''}
                            </>}
                        {/* For ssp renewal */}
                        {serviceForm?.work_method === 'ssp package renewal' && <NormalInput label='New SSP card number' name='ssp_card_number'
                            type={'number'} value={serviceForm?.service_data?.ssp_card_number} onChangeFun={handleServiceDataOnly} />}

                        {/* If repeat work */}
                        {serviceForm?.service_data?.repeat_software && <div className="radio-input-border-div">
                            <div className="sub-title"><h4>Repeat work ?</h4></div>
                            <div className="radio-inputs">
                                <RadioInput label={"Yes"} name={'repeat_work'} value={'yes'} onChangeFun={handleRepeat}
                                    checked={serviceForm?.service_data?.repeat_tech} />
                                <RadioInput label={"No"} name={'repeat_work'} value={'no'} onChangeFun={handleRepeat}
                                    checked={!serviceForm?.service_data?.repeat_tech} />
                            </div>
                            {!serviceForm?.service_data?.repeat_tech &&
                                <SelectInput label='Why not repeat' name='why_not-repeat'
                                    values={repeatReasons} firstOption={{ option: 'Choose...', value: '' }}
                                    onChangeFun={handleRepeat} />}
                        </div>}

                        {/* common */}
                        <div className="radio-input-border-div">
                            <div className="sub-title"><h4>Above 24 kms ?</h4></div>
                            <div className="radio-inputs">
                                <RadioInput label={"Yes"} name={'above_24'} value={'yes'} onChangeFun={handleAbout24}
                                    checked={serviceForm?.service_data?.above_24} />
                                <RadioInput label={"No"} name={'above_24'} value={'no'} onChangeFun={handleAbout24}
                                    checked={!serviceForm?.service_data?.above_24} />
                            </div>
                        </div>

                        {/* Cabin change */}
                        {(serviceForm?.work_category_selected === 'checkup' || serviceForm?.work_category_selected === 'machine_exchange')
                            ? "" : <div className="radio-input-border-div">
                                <div className="sub-title"><h4>Cabin change work ?</h4></div>
                                <div className="radio-inputs">
                                    <RadioInput label={"Yes"} name={'cabin_change'} value={'yes'} onChangeFun={handleCabinChange}
                                        checked={serviceForm?.service_data?.cabin_change} />
                                    <RadioInput label={"No"} name={'cabin_change'} value={'no'} onChangeFun={handleCabinChange}
                                        checked={!serviceForm?.service_data?.cabin_change} />
                                </div>
                                {serviceForm?.service_data?.cabin_change &&
                                    <>
                                        <SelectInput label='New product name' name='product_id'
                                            values={purifierList} firstOption={{ option: 'Choose...', value: '' }}
                                            onChangeFun={handleCabinChange} />
                                        <SelectInput label='Purifier usage type' name='product_usage'
                                            values={productUsageList} firstOption={{ option: 'Choose...', value: '' }}
                                            onChangeFun={handleCabinChange} />
                                    </>
                                }
                            </div>}


                        {/* Confirm package inactive */}
                        {(!serviceForm?.service_data?.package_renewal && ['AMC', 'SSP', 'I/W'].includes(serviceForm?.purifier_customer_status) &&
                            new Date() > new Date(new Date(purifier?.package_expiry_date || '').setDate(new Date(purifier?.package_expiry_date || '').getDate() - 40)))
                            && <div className="radio-input-border-div">
                                <div className="sub-title"></div>
                                <CheckboxInput label={"This customer on renewal status. If not interested for renewal new AMC / SSP package. The customer close the package after  expire date."}
                                    name={'package_continuity_uncomforted'} value={true} onChangeFun={handlePCU} checked={serviceForm?.service_data?.package_continuity_uncomforted}
                                    isRequired={false} />
                            </div>}
                    </div>
                    {/* Next Buttons */}
                    <div className="handle-buttons-div">
                        <button type='button' className='reset-work' onClick={resetWork}>Reset work</button>
                        <button type='submit' className='next'>Next</button>
                    </div>
                </div>
            </form >
        </div >
    )
}

export default WorkCategory