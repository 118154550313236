import React, { useState } from 'react'
import './add-edit.scss'
import NormalInput from '../../common/inputs/NormalInput';
import toast from 'react-hot-toast';
import { BiLoaderAlt } from 'react-icons/bi'
import { userAxios } from '../../../config/axios';
import { complaintPriority } from '../../../assets/javascript/const-data'


const AddEditOptionStatus = ({ title, data, setModal, setData }) => {
    const [loading, setLoading] = useState(false)
    const [form, setForm] = useState(data || {})

    const handleChange = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault()

        const regex = new RegExp("other", "i")

        if (form?.option.length === 5 && regex.test(form?.option || '')) {
            toast.error(`You cannot add 'other'.`);
            setLoading(false)
            return
        }

        setLoading(true)

        if (data) {
            userAxios.put(`/resource/single-with-status/${title}`, form).then(() => {
                setData((state) => state.map((input) => {
                    if (input._id === data._id) {
                        return form

                    }
                    return input
                }))
                setModal({ status: false })
                setLoading(false)
            }).catch((error) => {
                toast.error(error.message || 'Try now')
                setLoading(false)
            })
        } else {
            userAxios.post(`/resource/single-with-status/${title}`, form).then((response) => {

                setData((state) => ([
                    response.data,
                    ...state
                ]))
                setModal({ status: false })
                setLoading(false)
            }).catch((error) => {
                toast.error(error.message || 'Try now')
                setLoading(false)
            })
        }
    }


    return (
        <div className="add-edit-input-single-div">
            <form onSubmit={handleSubmit}>
                <NormalInput type={'text'} label='Option' name='option' value={form.option} onChangeFun={handleChange} />
                <NormalInput type={'number'} label='Priority' name='status' value={form.status} onChangeFun={handleChange}
                    min={1} max={complaintPriority.length || 3} />
                <p className='small-text'>Priority : {complaintPriority?.map((value, index) => `${index + 1}: ${value}, `)}</p>
                <div className="button-div">
                    <button type={loading ? 'button' : 'submit'}>{loading ? <span className='loading-icon'><BiLoaderAlt /></span> : 'Submit'}</button>
                </div>
            </form>
            {loading === 'fetch' && <div className="loading-pop">
                <p>Loading...</p>
            </div>}
        </div>
    )
}

export default AddEditOptionStatus