import React from 'react'
import './style.scss'
import { changeFormValue, nextAction } from '../../../redux/features/user/serviceSlice'
import { useDispatch, useSelector } from 'react-redux'
import PumpInput from '../service-form-sub-comp/PumpInput'
import { extractNumbers } from '../../../assets/javascript/find-functions'
import { useSearchParams } from 'react-router-dom'
import NormalInput from '../../common/inputs/NormalInput'
import { findTdsStatus } from '../../../assets/javascript/service-from-helper'
import InfoBox from '../../common/info-box/InfoBox'

const SectionThree = React.memo(({ resources, purifier, showInput, product }) => {
    const dispatch = useDispatch();
    const { serviceForm } = useSelector((state) => state.serviceData)
    // eslint-disable-next-line
    const [searchParams, setSearchParams] = useSearchParams();

    const handleChange = (e) => {

        if (e.target.name === 'input_tds') {

            let status = findTdsStatus(Number(e.target.value), Number(serviceForm?.membrane_tds_info?.output_tds), 'RO')

            dispatch(changeFormValue({
                ...serviceForm,
                membrane_tds_info: {
                    ...serviceForm?.membrane_tds_info,
                    tds_status: status,
                    [e.target.name]: e.target.value
                }
            }))
        }

        if (e.target.name === 'output_tds') {
            let status = findTdsStatus(Number(serviceForm?.membrane_tds_info?.input_tds), Number(e.target.value), 'RO')

            dispatch(changeFormValue({
                ...serviceForm,
                membrane_tds_info: {
                    ...serviceForm?.membrane_tds_info,
                    tds_status: status,
                    [e.target.name]: e.target.value
                },
            }))
        }

    }

    const handleSectionSubmit = (e) => {
        e.preventDefault()

        // to section four
        dispatch(nextAction(['inspection_report', 'pump_and_membrane_checking']))
        setSearchParams(`section=inspection_report`)

    }


    return (
        <div className="service-form-section-div">
            <div className="title-section">
                <h3>Pump and membrane checking</h3>
                {/* <p>Description</p> */}
            </div>
            <div className="form-div">
                <form action="" onSubmit={handleSectionSubmit} >
                    {['RO+ALKALINE', 'RO+UV+ALKALINE', 'RO+UV+UF+ALKALINE'].includes(product?.product_category) &&
                        (serviceForm?.before_checking_data?.tds_status === 'High' || serviceForm?.flow_checking_second_time?.tds_status === 'High') &&
                        <div>
                            <div className="sub-title">
                                <h4>From Membrane, TDS</h4>
                                {serviceForm?.membrane_tds_info?.tds_status &&
                                    <span className={`text-badge ${serviceForm?.membrane_tds_info?.tds_status}-text`}>{serviceForm?.membrane_tds_info?.tds_status}</span>}
                            </div>
                            <div className="two-input-single-line-div">
                                <NormalInput label='Input TDS' name='input_tds' value={serviceForm?.membrane_tds_info?.input_tds || null} type={'number'}
                                    onChangeFun={handleChange} min={"0"} step={"0.1"} />
                                <NormalInput label='Output TDS' name='output_tds' value={serviceForm?.membrane_tds_info?.output_tds || null} type={'number'} min={"0"} step={"0.1"}
                                    onChangeFun={handleChange} />
                            </div>
                        </div>}

                    {serviceForm?.membrane_tds_info?.tds_status === 'Normal' && <InfoBox title={'Check Alkaline Filter !'} description={'It is not a pump or membrane problem. The problem is the alkaline filter. Check the alkaline filter and fix the problem'} />}


                    {/* Use initial FR */}
                    {/* Tds status is Hight    ===> Don't show this inputs */}
                    {serviceForm?.flow_checking_second_time?.tds_status !== 'High' && !(['RO+ALKALINE', 'RO+UV+ALKALINE', 'RO+UV+UF+ALKALINE'].includes(product?.product_category) &&
                        (serviceForm?.before_checking_data?.tds_status === 'High' || serviceForm?.flow_checking_second_time?.tds_status === 'High')) && <PumpInput title={`OP + OM + FR ${extractNumbers(serviceForm?.before_checking_data?.fr)}`} index={0}
                            type={`op+om+fr${extractNumbers(serviceForm?.before_checking_data?.fr)}`} filterCriteria={12}
                            rejectCriteria={extractNumbers(serviceForm?.before_checking_data?.fr)} />
                    }

                    {/* Use FR  300*/}
                    {/* initial state fr is 300 ===> Don't show this inputs */}
                    {/* Tds status is Hight    ===> Don't show this inputs */}
                    {serviceForm?.pump_membrane_checking?.[0]?.filter_water_status && serviceForm?.pump_membrane_checking?.[0]?.reject_water_status
                        && (serviceForm?.pump_membrane_checking?.[0]?.filter_water_status !== 'Normal' ||
                            serviceForm?.pump_membrane_checking?.[0]?.reject_water_status !== 'Normal') &&
                        extractNumbers(serviceForm?.before_checking_data?.fr) !== 300 &&
                        serviceForm?.flow_checking_second_time?.tds_status !== 'High' &&
                        <PumpInput title={`OP + OM + FR 300`} index={1} type={`op+om+fr300`} filterCriteria={12} rejectCriteria={300} />
                    }


                    {/* Use FR  450*/}
                    {/* initial state fr is 300 & status not normal ===> This is Next setup */}
                    {/* Membrane tds is not normal */}
                    {(
                        (extractNumbers(serviceForm?.before_checking_data?.fr) === 300 && serviceForm?.pump_membrane_checking?.[0]?.filter_water_status
                            && serviceForm?.pump_membrane_checking?.[0]?.reject_water_status &&
                            (serviceForm?.pump_membrane_checking?.[0]?.filter_water_status !== 'Normal' ||
                                serviceForm?.pump_membrane_checking?.[0]?.reject_water_status !== 'Normal')) ||

                        (extractNumbers(serviceForm?.before_checking_data?.fr) !== 300 && serviceForm?.pump_membrane_checking?.[1]?.filter_water_status
                            && serviceForm?.pump_membrane_checking?.[1]?.reject_water_status
                            && (serviceForm?.pump_membrane_checking?.[1]?.filter_water_status !== 'Normal' ||
                                serviceForm?.pump_membrane_checking?.[1]?.reject_water_status !== 'Normal'))
                        || serviceForm?.flow_checking_second_time?.tds_status === 'High' ||
                        (serviceForm?.membrane_tds_info?.tds_status && serviceForm?.membrane_tds_info?.tds_status !== 'Normal')
                    ) &&

                        < PumpInput title={`OP + NM + FR 450`} index={2} type={`op+nm+fr450`} filterCriteria={14} rejectCriteria={450} />
                    }


                    {/* Use FR  350*/}
                    {serviceForm?.pump_membrane_checking?.[2]?.filter_water_status
                        && serviceForm?.pump_membrane_checking?.[2]?.reject_water_status
                        && (serviceForm?.pump_membrane_checking?.[2]?.filter_water_status !== 'Normal' ||
                            serviceForm?.pump_membrane_checking?.[2]?.reject_water_status !== 'Normal') &&

                        <PumpInput title={`NP + OM + FR 350`} index={3} type={`np+om+fr350`} filterCriteria={12} rejectCriteria={350} />
                    }

                    {/* Use FR  450*/}
                    {serviceForm?.pump_membrane_checking?.[3]?.filter_water_status
                        && serviceForm?.pump_membrane_checking?.[3]?.reject_water_status
                        && (serviceForm?.pump_membrane_checking?.[3]?.filter_water_status !== 'Normal' ||
                            serviceForm?.pump_membrane_checking?.[3]?.reject_water_status !== 'Normal') &&

                        <PumpInput title={`NP + NM + FR 450`} index={4} type={`np+nm+fr450`} filterCriteria={15} rejectCriteria={450} />
                    }

                    {/* Next Buttons */}
                    <div className="handle-buttons-div">
                        <button type='submit' className='next'>Next</button>
                    </div>


                </form>
            </div >
        </div >
    )
})

export default SectionThree