import React, { useEffect } from 'react'
import './success-submit.scss'
import SinglePage from '../../common/page/SinglePage'
import SuccessGif from '../../../assets/images/success.gif'
import SuccessAudio from '../../../assets/audio/submit.mp3'
import { IoMdCheckmarkCircle, IoMdCloseCircleOutline } from "react-icons/io";
import { useNavigate, useSearchParams } from 'react-router-dom';

const SuccessSubmit = () => {

    const [searchParams, setSearchParams] = useSearchParams()
    const navigate = useNavigate()

    useEffect(() => {
        if (!searchParams?.get('srl_number')) {
            navigate('/')
        }
    }, [])

    return (
        <SinglePage>
            <div className="success-submit-div">
                <div className="content-border">
                    <div className="section-div section-one-div">
                        <img src={SuccessGif} alt="Success" />
                        <h4>Service completed!</h4>
                        <audio src={SuccessAudio} autoPlay hidden />
                    </div>
                    <div className="section-div section-two-div">
                        <div className="list-item-div">
                            {searchParams?.get('sms') === 'Yes' ? <IoMdCheckmarkCircle /> : <IoMdCloseCircleOutline />}
                            <p>SMS sent.</p>
                        </div>
                        <div className="list-item-div">
                            <IoMdCheckmarkCircle />
                            <p>Report generated.</p>
                        </div>
                        <div className="list-item-div">
                            <IoMdCheckmarkCircle />
                            <p>Feedback submitted.</p>
                        </div>
                    </div>
                    <div className="section-div section-three-div">
                        <button onClick={() => navigate(`/dar-list/${searchParams.get('srl_number')}/view`, { replace: true })}>Report</button>
                        <button onClick={() => navigate('/', { replace: true })}>Close</button>
                    </div>
                </div>
            </div>
        </SinglePage>
    )
}

export default SuccessSubmit