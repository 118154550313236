import React, { useState } from 'react'
import './add-edit.scss'
import NormalInput from '../../common/inputs/NormalInput';
import toast from 'react-hot-toast';
import { BiLoaderAlt } from 'react-icons/bi'
import { userAxios } from '../../../config/axios';


const AddEditModal = ({ title, data, setModal, setData }) => {
    const [loading, setLoading] = useState(false)
    const [option, setOption] = useState(data?.option || '')

    const handleChange = (e) => {
        setOption(e.target.value)
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        setLoading(true)

        if (data) {
            userAxios.put(`/resource/single/${title}`, { _id: data._id, option }).then(() => {
                setData((state) => state.map((input) => {
                    if (input._id === data._id) {
                        return {
                            ...input,
                            option
                        }
                    }
                    return input
                }))
                setModal({ status: false })
                setLoading(false)
            }).catch((error) => {
                toast.error(error.message || 'Try now')
                setLoading(false)
            })
        } else {
            userAxios.post(`/resource/single/${title}`, { option }).then((response) => {

                setData((state) => ([
                    response.data,
                    ...state
                ]))
                setModal({ status: false })
                setLoading(false)
            }).catch((error) => {
                toast.error(error.message || 'Try now')
                setLoading(false)
            })
        }
    }


    return (
        <div className="add-edit-input-single-div">
            <form onSubmit={handleSubmit}>
                <NormalInput type={'text'} label='Option' name='option' value={option} onChangeFun={handleChange} />

                <div className="button-div">
                    <button type={loading ? 'button' : 'submit'}>{loading ? <span className='loading-icon'><BiLoaderAlt /></span> : 'Submit'}</button>
                </div>
            </form>
            {loading === 'fetch' && <div className="loading-pop">
                <p>Loading...</p>
            </div>}
        </div>
    )
}

export default AddEditModal