import React, { useEffect, useState } from 'react'
import './choose-box.scss'
import NormalInput from '../../common/inputs/NormalInput'
import CheckboxInput from '../../common/inputs/CheckboxInput'
import { YYYYMMDDFormat } from '../../../assets/javascript/formate-functions'
import { thisMonthLastDay, thisMonthFirstDay } from '../../../assets/javascript/find-functions'
import { useNavigate } from 'react-router-dom'


const ChooseBox = ({ type }) => {
    const [form, setForm] = useState({ type: type, from_date: null, end_date: null, all: 'NO', })
    const navigate = useNavigate()

    const handleChange = (e) => {
        if (e.target.name === 'all') {
            setForm({
                ...form,
                [e.target.name]: e.target?.checked ? 'YES' : 'NO'
            })

        } else {
            setForm({
                ...form,
                [e.target.name]: e.target.value
            })
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if (type === 'complaint') {
            navigate('/admin/complaints-list?status=Proceed', {
                state: form
            })
        } else {
            navigate('/admin/service-list?status=UnRegistered', {
                state: form
            })
        }
    }

    useEffect(() => {
        if (type === 'complaint') {
            setForm({
                ...form,
                from_date: YYYYMMDDFormat(new Date().setDate(new Date().getDate() - 2)),
                end_date: YYYYMMDDFormat(new Date())
            })
        } else {
            setForm({
                ...form,
                from_date: YYYYMMDDFormat(thisMonthFirstDay(new Date())),
                end_date: YYYYMMDDFormat(thisMonthLastDay(new Date()))
            })
        }
        // eslint-disable-next-line
    }, [type])


    return (
        <div className="choose-box-div">
            <form action="" onSubmit={handleSubmit}>
                <NormalInput label='From date (MM-DD-YYYY)' name='from_date' type={'date'} value={form?.from_date} onChangeFun={handleChange}
                    max={form?.end_date} />
                <NormalInput label='End date (MM-DD-YYYY)' name='end_date' type={'date'} value={form?.end_date} onChangeFun={handleChange}
                    max={type === 'complaint' ? YYYYMMDDFormat(new Date()) : YYYYMMDDFormat(thisMonthLastDay(new Date()))} />
                <CheckboxInput label={'Include closed and Cancelled status'} name={'all'} isRequired={false}
                    onChangeFun={handleChange} checked={form?.all === 'YES'} />
                <div className="button-div">
                    <button type={'submit'}>Select</button>
                </div>
            </form>
            {/* {loading === 'fetch' && <div className="loading-pop">
                <p>Loading...</p>
            </div>} */}
        </div>
    )
}

export default ChooseBox