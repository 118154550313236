import React from 'react'
import './service-card.scss';
import { GoDotFill } from "react-icons/go";
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux'


const ServiceCard = ({ data }) => {
    const { admin } = useSelector((state) => state.adminAuth)

    return (
        <div className="serviced-card-div green-color">
            <NavLink to={admin ? `/admin/serviced-list/${data?.service_srl_number}/view` : `/dar-list/${data?.service_srl_number}/view`}>
                <div className="card-top">
                    <div className="left">
                        <p>{new Date(data?.date).toDateString()}</p>
                        <GoDotFill />
                        <span className={`text-badge ${data?.purifier_customer_status}-text`}>{data?.purifier_customer_status}</span>
                    </div>
                    <div className={`right ${data?.purifier_customer_status}-text`}>
                        <p>{data?.work_method?.toUpperCase()}</p>
                    </div>
                </div>
                <div className="card-content">
                    <div className="section-one-div">
                        {!(!data?.grand_total_receivable) &&
                            <p className='amount'><span>₹</span>{data?.grand_total_receivable}</p>}
                        {data?.repeat_work &&
                            <p className=''>Repeat<span>Yes</span></p>}
                        {data?.cabin_change &&
                            <p className=''>Cabin change<span>Yes</span></p>}
                    </div>
                    <div className="section-two-div">
                        {data?.primary_spares?.map((spare, index) => {
                            return <p className='primary' key={index}>{spare?.spare_name}<span>{spare?.quantity}</span></p>
                        })}
                        {data?.secondary_spares?.map((spare, index) => {
                            return <p className='secondary' key={index}>{spare?.spare_name}<span>{spare?.quantity}</span></p>
                        })}
                        {data?.special_spares?.map((spare, index) => {
                            return <p className='special' key={index}>{spare?.spare_name}<span>{spare?.quantity}</span></p>
                        })}
                    </div>
                </div>
                <div className="card-bottom">
                    <div className="left">
                        <p>{data?.service_srl_number}</p>
                        <GoDotFill />
                        <p>{data?.work}</p>
                    </div>
                    <div className="rig">
                        <p>{data?.technician}</p>
                    </div>
                </div>
            </NavLink>
        </div>
    )
}

export default ServiceCard