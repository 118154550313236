import React, { useEffect, useState } from 'react'
import '../add-edit-zone/add-edit-zone.scss'
import NormalInput from '../../common/inputs/NormalInput';
import SelectInput from '../../common/inputs/SelectInput';
import MultiSelect from '../../common/inputs/MultiSelect';
import { BiLoaderAlt } from 'react-icons/bi'
import { serviceAxios, userAxios } from '../../../config/axios';
import toast from 'react-hot-toast';
import InfoBox from '../../common/info-box/InfoBox'
import { useNavigate } from 'react-router-dom'
import { YYYYMMDDFormat } from '../../../assets/javascript/formate-functions';
import { thisMonthFirstDay, thisMonthLastDay } from '../../../assets/javascript/find-functions';


const RegisterService = ({ cid, data, setModal, setData, serviceOnly }) => {
    const [loading, setLoading] = useState('fetch')
    const [complaintReasons, setComplaintReasons] = useState([])
    const [form, setForm] = useState({ cid })
    const [zoneLists, setZoneLists] = useState([])
    const [techList, setTechList] = useState([])
    const [serviceType, setServiceType] = useState([])
    const navigate = useNavigate()

    const handleChange = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        })

        if (e.target.name === 'service_type') {
            setForm({
                ...form,
                what_is_complaint: undefined,
                description: undefined,
                [e.target.name]: e.target.value
            })
        }

        if (e.target.name === 'send_to_zone_id') {
            const selectedZone = zoneLists.filter((obj) => obj?.value === e.target.value)
            setForm({
                ...form,
                [e.target.name]: e.target.value,
                send_to_zone: selectedZone?.[0]?.option || null,
                send_to_technician_id: null
            })

            setTechList(
                selectedZone?.[0]?.technicians?.map((tech) => {
                    return {
                        option: tech.full_name,
                        value: tech.staff_id,
                        selected: tech.staff_id === data?.send_to_technician_id
                    }
                })
            )
        }

        if (e.target.name === 'send_to_technician_id') {
            const selectedTech = techList.filter((obj) => obj?.value === e.target.value)
            setForm({
                ...form,
                [e.target.name]: e.target.value,
                send_to_technician: selectedTech?.[0]?.option || null
            })
        }
    }

    const handleMultiSelect = (selected) => {
        let total = 0
        let status = null

        selected.map((tech) => {
            total = total + Number(tech.priority)
            return tech
        })

        if (total <= ((selected.length * 3) / 3)) {
            status = 'Normal'
        } else if (total <= ((selected.length * 3) / 3) * 2) {
            status = 'Urgent'
        } else {
            status = 'Emergency'
        }

        setForm({
            ...form,
            what_is_complaint: selected.map((tech) => tech.label),
            priority: status
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading('submit')

        if (data?.reg_no) {
            serviceAxios.put('/service-registration', form).then(() => {
                toast.success('Updated!')
                setLoading('')
                setModal({ status: false })
                setData((state) => {
                    return {
                        ...state,
                        [data?.status]: state[data?.status]?.map((obj) => {
                            return obj?.reg_no === data?.reg_no ? { ...obj, ...form } : obj
                        })
                    }
                })
            }).catch((error) => {
                toast.error(error.message)
                setLoading('')
            })
        } else {

            if (form?.service_type === 'complaint' && !form?.what_is_complaint?.[0]) {
                toast.error('Select "What is complaint"')
                setLoading('')
                return;
            }

            serviceAxios.post('/service-registration', form).then((response) => {
                if (data?.status) {
                    setData((state) => {
                        return {
                            ...state,
                            UnRegistered: state['UnRegistered']?.filter((obj) => obj?.cid !== cid),
                            Proceed: [{
                                ...data,
                                reg_no: response.data?.reg_no,
                                registered_date_time: response.data?.registered_date_time,
                                service_index: response.data?.service_index,
                                status: response.data?.status,
                                send_to_zone: form?.send_to_zone || response.data?.send_to_zone,
                                send_to_zone_id: response.data?.send_to_zone,
                                send_to_technician: form?.send_to_technician || response.data?.send_to_technician,
                                send_to_technician_id: response.data?.send_to_technician,
                                additional_contact: form?.additional_contact,
                            }, ...(state['Proceed'] || []),]
                        }
                    })
                }
                if (form?.service_type === 'complaint') {
                    navigate('/admin/complaints-list?status=Proceed', {
                        state: { type: 'complaint', from_date: YYYYMMDDFormat(new Date().setDate(new Date().getDate() - 30)), end_date: YYYYMMDDFormat(new Date()), all: 'NO', }
                    })
                } else {
                    navigate('/admin/service-list?status=Proceed', {
                        state: { type: 'service', from_date: YYYYMMDDFormat(thisMonthFirstDay(new Date())), end_date: YYYYMMDDFormat(thisMonthLastDay(new Date())), all: 'NO', }
                    })
                }
                toast.success('Registration success!')
                setLoading('')
                setModal({ status: false })
            }).catch((error) => {
                toast.error(error.message)
                setLoading('')
            })
        }
    }

    useEffect(() => {
        const complaintReasons = userAxios.get(`/resource/single-with-status/reg-complaint-reasons`).then((response) => {
            const options = response.data?.map((obj) => {
                return {
                    label: obj.option,
                    value: obj.option,
                    priority: obj.status
                }
            })
            setComplaintReasons(options)
        })

        const zoneList = userAxios.get('/zone').then((response) => {

            const options = response.data?.map((obj) => {
                return {
                    option: obj?.zone,
                    value: obj.zone_id,
                    technicians: obj.technicians,
                    selected: obj.zone_id === data?.send_to_zone_id || obj.zone_id === data?.customer_zone_id
                }
            })
            setZoneLists(options)

            const selectedZone = response.data?.filter((obj) => obj?.zone_id === data?.send_to_zone_id)
            setTechList(
                selectedZone?.[0]?.technicians?.map((tech) => {
                    return {
                        option: tech.full_name,
                        value: tech.staff_id,
                        selected: tech.staff_id === data?.send_to_technician_id
                    }
                })
            )
        })


        Promise.all([complaintReasons, zoneList]).then(() => setLoading(''))
    }, [data])

    useEffect(() => {
        if (serviceOnly) {
            setServiceType([
                { option: 'Service / Periodical', value: 'service' },
                { option: 'Renewal', value: 'renewal' }
            ])
        } else {
            setServiceType([
                { option: 'Complaint', value: 'complaint' },
                { option: 'Service / Periodical', value: 'service' },
                { option: 'Renewal', value: 'renewal' }
            ])
        }
    }, [serviceOnly])

    useEffect(() => {
        if (data) {
            setForm({
                ...form,
                reg_no: data?.reg_no || undefined,
                service_type: data?.renewal ? 'renewal' : data?.service ? 'service' : 'complaint',
                description: data?.description || undefined,
                additional_contact: data?.additional_contact || undefined,
                send_to_zone_id: data?.send_to_zone_id || data?.customer_zone_id || undefined,
                send_to_technician_id: data?.send_to_technician_id || undefined,
                status: data?.status || undefined
            })
        }
    }, [data])

    return (
        <div className="create-customer-form-div add-edit-zone-div">
            <form action="" onSubmit={handleSubmit}>
                {!data?.reg_no && <SelectInput label='Service Type*' values={serviceType} firstOption={{ option: 'Choose...', value: '' }}
                    name='service_type' value={form.service_type} onChangeFun={handleChange} />}
                {form?.service_type === 'complaint' && <>
                    {!data?.reg_no && <MultiSelect label='What is the complaint*' name='what_is_complaint' values={complaintReasons} onChangeFun={handleMultiSelect}
                    />}
                    {!data?.reg_no && <InfoBox description={'You can not edit "what is complaints" after registration '} />}
                    <NormalInput label='Complaint description' name='description' value={form.description} type={'text'} onChangeFun={handleChange} isRequired={false} />
                </>}
                <NormalInput label='Additional contact' name='additional_contact' value={form.additional_contact} type={'number'}
                    onChangeFun={handleChange} isRequired={false} min={5000000000} max={9999999999} />
                <SelectInput label='Send to zone*' name='send_to_zone_id' values={zoneLists} firstOption={{ option: 'Choose...', value: '' }}
                    onChangeFun={handleChange} />
                <SelectInput label='Send to technician' name='send_to_technician_id' values={techList} firstOption={{ option: 'Choose...', value: '' }}
                    onChangeFun={handleChange} isRequired={false} />
                <div className="button-div">
                    <button type={loading ? 'button' : 'submit'}>{loading ? <span className='loading-icon'><BiLoaderAlt /></span> : `${data ? 'Submit' : 'Create'}`}</button>
                </div>
            </form>
            {loading === 'fetch' && <div className="loading-pop">
                <p>Loading...</p>
            </div>}
        </div>
    )
}

export default RegisterService