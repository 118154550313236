import React, { useState } from 'react'
import './single-tag.scss'
import { GrEdit } from "react-icons/gr";
import { BsTrash3 } from "react-icons/bs";
import { PiSpinner } from "react-icons/pi";
import AddCidComment from './AddCidComment';
import { customerAxios } from '../../../config/axios';
import toast from 'react-hot-toast';

const SingleTag = ({ cid, data, index, setModal, setData }) => {
    const [loading, setLoading] = useState(false)


    const openModal = (elem, title, data) => {
        setModal({
            content: elem,
            title,
            status: true
        })
    }

    const handleDelete = () => {
        setLoading(true)
        customerAxios.delete(`/tag/unpin?cid=${cid}&tag_id=${data?._id}`,).then((response) => {
            toast.success(response.message)
            setData((state) => state?.map((obj) => {
                if (obj.cid === cid) {
                    return {
                        ...obj,
                        tag_list: obj?.tag_list?.filter((tag) => tag._id !== data?._id)
                    }
                }
                return obj
            }))
            setLoading(false)
            setModal({ status: false })
        })
    }



    return (
        <div className="single-tag-div">
            <div className="text-div">
                <pre>{data?.comment}</pre>
            </div>
            <div className="control-div">
                <span onClick={() => openModal(<AddCidComment cid={cid} setModal={setModal} data={data} setData={setData} />, "Update Comment")}>
                    <GrEdit />
                </span>
                <span className={loading && 'loading-icon'} onClick={handleDelete}>{loading ? <PiSpinner /> : <BsTrash3 />}</span>
            </div>
        </div >
    )
}

export default SingleTag