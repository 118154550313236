import React, { useEffect, useState } from 'react';
import './customer-list.scss';
import AdminPage from '../../../components/common/page/AdminPage'
import TableFilter from '../../../components/common/table-filter/TableFilter'
import Spinner from '../../../components/common/spinners/SpinWithMessage'
import Modal from '../../../components/common/modal/Modal';
import CreateCustomer from '../../../components/admin/create-customer/CreateCustomer';
import RegisterService from '../../../components/admin/register-service/RegisterService';
import InfoBox from '../../../components/common/info-box/InfoBox';
import NormalInput from '../../../components/common/inputs/NormalInput';
import SelectInput from '../../../components/common/inputs/SelectInput'
import CheckboxInput from '../../../components/common/inputs/CheckboxInput'
import AddCidComment from '../../../components/admin/cid-comment/AddCidComment';
import SingleTag from '../../../components/admin/cid-comment/SingleTag';
import { userAxios } from '../../../config/axios';
import { toast } from 'react-hot-toast'
import { AiOutlinePlus } from 'react-icons/ai'
import { FaStar, FaEye, FaPlus } from "react-icons/fa";
import { LuClipboardEdit } from "react-icons/lu";
import { FiEdit2 } from "react-icons/fi";
import { BiLoaderAlt } from 'react-icons/bi'
import { FcSearch, FcDeleteDatabase } from "react-icons/fc";
import { YYYYMMDDFormat } from '../../../assets/javascript/formate-functions';
import { thisMonthFirstDay, thisMonthLastDay } from '../../../assets/javascript/find-functions';
import { useNavigate, useSearchParams } from 'react-router-dom';

const CustomerList = () => {

    const [loading, setLoading] = useState('')
    const [modal, setModal] = useState({ content: null, title: null, status: false })
    const [searchParams, setSearchParams] = useSearchParams()
    const [form, setForm] = useState({
        key: searchParams.get('key') || '',
        customer_status: searchParams.get('customer_status')?.split(' ') || [],
        flt_type: searchParams.get('flt_type') || '',
        flt_from: searchParams.get('flt_from') || YYYYMMDDFormat(thisMonthFirstDay(new Date())),
        flt_to: searchParams.get('flt_to') || YYYYMMDDFormat(thisMonthLastDay(new Date()))
    })
    const [data, setData] = useState([])
    const [ifSearch, setIfSearch] = useState(false)
    const navigate = useNavigate()

    const filtrationTypes = [
        { option: 'Installation', value: 'installed_at' },
        { option: 'Next periodical service', value: 'next_periodical_service_date' },
        { option: 'Package start', value: 'package_started_date' },
        { option: 'Package expiry', value: 'package_expiry_date' },
        { option: 'Carbon usage start', value: 'carbon_filter_start_date' },
        { option: 'Carbon usage expiry', value: 'carbon_filter_expiry_date' },
        { option: 'Tech last visited', value: 'technician_last_visited_date' },
    ]

    const openModal = (elem, title, data) => {
        setModal({
            content: elem,
            title,
            status: true
        })
    }

    const handleChange = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        })
    }

    const handleCustomerStatusChange = (e) => {
        const customer_status = form.customer_status.includes(e.target.value)
        if (customer_status) {
            setForm({
                ...form,
                customer_status: form.customer_status.filter((value) => e.target.value !== value)
            })
        } else {
            setForm({
                ...form,
                customer_status: [...form.customer_status, e.target.value]
            })
        }
    }

    const doFilter = () => {
        setLoading('filter')
        userAxios.get(`/customer/search?key=${form.key}&customer_status=${form.customer_status}&flt_type=${form.flt_type}&flt_from=${form.flt_from}&flt_to=${form.flt_to}`)
            .then((response) => {
                setIfSearch(true)
                setData(response.data)
                setLoading('')
            }).catch((error) => {
                toast.error(error.message)
                setLoading('')
            })
    }

    const handleApply = (e) => {
        e.preventDefault();

        if (form.search && form.search.length < 3) {
            toast.error('Enter at least 3 characters')
            return;
        }

        const newSearchParams = new URLSearchParams(searchParams);
        if (form.key) {
            newSearchParams.set('key', form.key);
        } else {
            newSearchParams.delete('key');
        }
        if (form.customer_status?.[0]) {
            newSearchParams.set('customer_status', form.customer_status.join(' '));
        } else {
            newSearchParams.delete('customer_status');
        }
        if (form.flt_type) {
            newSearchParams.set('flt_type', form.flt_type);
            newSearchParams.set('flt_from', form.flt_from);
            newSearchParams.set('flt_to', form.flt_to);
        } else {
            newSearchParams.delete('flt_type');
            newSearchParams.delete('flt_from');
            newSearchParams.delete('flt_to');
        }
        setSearchParams(newSearchParams);

        doFilter()
    }

    const changeListMode = () => {
        const newSearchParams = new URLSearchParams(searchParams);
        if (searchParams.get('mode') === 'dec') {
            newSearchParams.set('mode', 'reg');
        } else {
            newSearchParams.set('mode', 'dec');
        }
        setSearchParams(newSearchParams);
    }

    useEffect(() => {
        if (form.key || form.customer_status?.[0] || form.flt_type || (form.search && form.search.length < 3)) {
            doFilter()
        }
    }, [])

    return (
        <div className="customer-list-div">
            <Modal modal={modal} setModal={setModal} />
            <AdminPage titleArray={['Customer list']}>
                <div className="search-border-div">
                    <div className="filter-border-div">
                        <div className="create-customer">
                            <button className='add-button'
                                onClick={() => openModal(<CreateCustomer setCustomerList={setData} setModal={setModal} />,
                                    'CREATE CUSTOMER')}><AiOutlinePlus /> New Customer</button>
                        </div>
                        <form action="" onSubmit={handleApply} >
                            <div className="search-section">
                                <h5>Search</h5>
                                <InfoBox description={'Your can search CID, Name, Address, Place, Post and Contacts '} />
                                <NormalInput label='Enter at least 3 characters' name='key' onChangeFun={handleChange} value={form.key} isRequired={false} />
                            </div>

                            <div className="search-section">
                                <h5>Customer status</h5>
                                <CheckboxInput label={'AMC'} name={'customer_status'} checked={form?.customer_status?.includes('AMC')} value={'AMC'} isRequired={false} onChangeFun={handleCustomerStatusChange} />
                                <CheckboxInput label={'SSP'} name={'customer_status'} checked={form?.customer_status?.includes('SSP')} value={'SSP'} isRequired={false} onChangeFun={handleCustomerStatusChange} />
                                <CheckboxInput label={'I/W'} name={'customer_status'} checked={form?.customer_status?.includes('I/W')} value={'I/W'} isRequired={false} onChangeFun={handleCustomerStatusChange} />
                                <CheckboxInput label={'O/W'} name={'customer_status'} checked={form?.customer_status?.includes('O/W')} value={'O/W'} isRequired={false} onChangeFun={handleCustomerStatusChange} />
                                <CheckboxInput label={'O/C'} name={'customer_status'} checked={form?.customer_status?.includes('O/C')} value={'O/C'} isRequired={false} onChangeFun={handleCustomerStatusChange} />
                            </div>

                            <div className="search-section">
                                <h5>More options</h5>
                                <SelectInput label={'Filtration types'} name={'flt_type'} isRequired={false} onChangeFun={handleChange}
                                    firstOption={{ option: 'Choose', value: '' }} values={filtrationTypes} />
                                <NormalInput label={'From date'} name={'flt_from'} value={form?.flt_from || YYYYMMDDFormat(thisMonthFirstDay(new Date()))} type={'date'}
                                    isRequired={false} onChangeFun={handleChange} />
                                <NormalInput label={'To date'} name={'flt_to'} value={form?.flt_to || YYYYMMDDFormat(thisMonthLastDay(new Date()))} type={'date'}
                                    isRequired={false} onChangeFun={handleChange} />
                            </div>

                            <div className="button-div">
                                <button type='submit'>{loading === 'filter' ? <span className='loading-icon'><BiLoaderAlt /></span> : "Apply"}</button>
                            </div>
                        </form>
                    </div>
                    <div className="result-div">
                        {ifSearch ? <h4>{data?.length || '0'} {data?.length > 1 ? 'Results' : 'Result'}</h4>
                            : <h4>Find Purifier Customers</h4>
                        }
                        <div className="items-div">
                            {data?.[0] ? <>
                                <TableFilter topRight={<button type='button' className='right-button'
                                    onClick={changeListMode}>{searchParams.get('mode') === 'dec' ? 'Reg Mode' : 'Dec Mode'}</button>}>
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>CID</th>
                                                <th>Full name</th>
                                                <th>Status</th>
                                                {searchParams.get('mode') === 'dec'
                                                    ? <th>Descriptions</th>
                                                    : <>
                                                        <th>Zone / Post</th>
                                                        <th>Contact</th>
                                                    </>}
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {data?.map((customer) => <tr>
                                                <td style={{ textAlign: 'center' }}>{customer.cid}</td>
                                                <td style={{ textAlign: 'center' }}>
                                                    <span style={{ fontWeight: 700 }}>{customer?.full_name}
                                                        {customer?.star_rate && <small className={`text-badge green-text`}><FaStar /> {customer?.star_rate}</small>}</span>
                                                    <br></br>
                                                    {`${customer?.address?.address || customer?.address?.place}`}
                                                </td>
                                                <td style={{ textAlign: 'center' }}>
                                                    <small className={`text-badge ${customer?.purifier_customer_status}-text`}>{customer?.purifier_customer_status}</small>
                                                </td>
                                                {searchParams.get('mode') === 'dec' ? <>
                                                    <td>
                                                        {customer?.tag_list?.map((obj, index) => <SingleTag cid={customer.cid} data={obj}
                                                            index={index} setModal={setModal} setData={setData} />)}
                                                    </td>
                                                </> : <>
                                                    <td style={{ textAlign: 'center' }}>
                                                        {`${customer?.zone}`}
                                                        <br></br>
                                                        {`${customer?.address?.post} P.O`}
                                                    </td>
                                                    <td style={{ textAlign: 'center' }}>
                                                        {`${customer?.contact1 || ''}`}
                                                        <br></br>
                                                        {`${customer?.contact2 || ''}`}
                                                    </td>
                                                </>}
                                                <td >
                                                    <div className='buttons-div' >
                                                        {searchParams.get('mode') === 'dec' && <button title='Add Comment' className='button-small-icon dec'
                                                            onClick={() => openModal(<AddCidComment cid={customer?.cid} setModal={setModal} setData={setData} />, "Add Comment")}>
                                                            <FaPlus /></button>}
                                                        {searchParams.get('mode') !== 'dec' && <button title='Register - complaint / service /renewal' className='button-small-icon add'
                                                            onClick={() => openModal(<RegisterService cid={customer?.cid} setModal={setModal} data={{ send_to_zone_id: customer?.zone_id }} />, "Register service")}>
                                                            <LuClipboardEdit /></button>}
                                                        <button title='View work card' className='button-small-icon view'
                                                            onClick={() => navigate(`/admin/customer-list/${customer?.cid}/cards`)}>
                                                            <FaEye /></button>
                                                        {searchParams.get('mode') !== 'dec' && <button title='Edit details' className='button-small-icon edit'
                                                            onClick={() => navigate(`/admin/customer-list/${customer?.cid}/view`)}>
                                                            <FiEdit2 /></button>}

                                                    </div>
                                                </td>
                                                {/* For Search */}
                                                <td style={{ display: 'none' }}>
                                                    {customer?.purifier_customer_status} {customer?.address?.address} {customer?.address?.place}
                                                    {customer?.address?.pin_code} {customer?.address?.district} {customer?.address?.land_mark}
                                                    {customer?.full_name}
                                                </td>
                                            </tr>)}

                                        </tbody>
                                    </table>
                                </TableFilter>
                            </>
                                : <>
                                    <Spinner height={'300px'} spin={false} message={ifSearch ? 'No matched data' : 'Search customer'}
                                        icon={ifSearch ? <FcDeleteDatabase /> : <FcSearch />} />
                                </>}
                        </div>
                    </div>
                </div>
            </AdminPage >
        </div >
    )
}

export default CustomerList