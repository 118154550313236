import React, { useEffect, useState } from 'react'
import './red-yellow-list.scss'
import AdminPage from '../../../components/common/page/AdminPage'
import TableFilter from '../../../components/common/table-filter/TableFilter'
import Spinner from '../../../components/common/spinners/SpinWithMessage'
import Modal from '../../../components/common/modal/Modal';
import RegisterService from '../../../components/admin/register-service/RegisterService';
import InfoBox from '../../../components/common/info-box/InfoBox'
import { serviceAxios } from '../../../config/axios'
import { FaEye, FaStar } from 'react-icons/fa'
import { GrList } from 'react-icons/gr'
import { useNavigate } from 'react-router-dom'
import { LuClipboardEdit } from "react-icons/lu";

const RedYellowList = ({ listType }) => {
    const [listData, setListData] = useState([])
    const [loading, setLoading] = useState('fetch')
    const navigate = useNavigate()
    const [modal, setModal] = useState({ content: null, title: null, status: false })

    const openModal = (elem, title, data) => {
        setModal({
            content: elem,
            title,
            status: true
        })
    }

    useEffect(() => {
        if (listType === 'yellow') {
            serviceAxios.get('/yellow-list').then((response) => {
                setListData(response.data)
                setLoading('')
            })
        } else {
            serviceAxios.get('/red-list').then((response) => {
                setListData(response.data)
                setLoading('')
            })
        }

        // eslint-disable-next-line
    }, [])


    return (

        <div className="red-yellow-list-div">
            <AdminPage titleArray={[listType === 'yellow' ? 'Yellow list' : 'Red list']}>
                <Modal modal={modal} setModal={setModal} />
                {listData?.[0] ? <>
                    {listType === 'red' &&
                        <InfoBox title={'Package auto Expire'} description={'The status will automatically change to O/W after the package expire date'} />}

                    <TableFilter srlNo={true}>
                        <table>
                            <thead>
                                <tr>
                                    <th>CID</th>
                                    <th>Full name</th>
                                    <th>Status</th>
                                    <th>Zone / Post</th>
                                    <th>Package expire </th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {listData?.map((customer) => <tr>
                                    <td style={{ textAlign: 'center' }}>{customer.cid}</td>
                                    <td style={{ textAlign: 'center' }}>
                                        <span style={{ fontWeight: 700 }}>{customer?.full_name}
                                            {customer?.star_rate && <small className={`text-badge green-text`}><FaStar /> {customer?.star_rate}</small>}</span>
                                        <br></br>
                                        {`${customer?.place}`}
                                    </td>
                                    <td style={{ textAlign: 'center' }}>
                                        <small className={`text-badge ${customer?.purifier_customer_status}-text`}>{customer?.purifier_customer_status}</small>
                                    </td>
                                    <td style={{ textAlign: 'center' }}>
                                        {`${customer?.zone}`}
                                        <br></br>
                                        {`P.O ${customer?.post}`}
                                    </td>
                                    <td style={{ textAlign: 'center' }}>
                                        {`${customer?.package_expiry_date}`}
                                        <br></br>
                                        {`Service : ${customer?.service_count || 0}`}
                                    </td>
                                    <td >
                                        <div className='buttons-div' >
                                            <button title='Register - complaint / service /renewal' className='button-small-icon add'
                                                onClick={() => openModal(<RegisterService cid={customer?.cid} setModal={setModal} data={{ send_to_zone_id: customer?.zone_id }} />, "Register service")}>
                                                <LuClipboardEdit /></button>
                                            <button title='View work card' className='button-small-icon view'
                                                onClick={() => navigate(`/admin/customer-list/${customer?.cid}/cards`)}>
                                                <FaEye /></button>
                                        </div>
                                    </td>
                                    {/* For Search */}
                                    <td style={{ display: 'none' }}>
                                        {customer?.purifier_customer_status}
                                    </td>
                                </tr>)}

                            </tbody>
                        </table>




                    </TableFilter>
                </>
                    : <>
                        <Spinner height={'300px'} spin={!(!loading)} message={loading ? 'Fetch data' : 'No data'}
                            icon={!loading && <GrList />} />
                    </>}
            </AdminPage>
        </div>
    )
}

export default RedYellowList