import React, { useState } from 'react'
import './style.scss'
import NormalInput from '../../common/inputs/NormalInput'
import { userAxios } from '../../../config/axios'
import { BiLoaderAlt } from 'react-icons/bi'
import toast from 'react-hot-toast'
import { YYYYMMDDFormat } from '../../../assets/javascript/formate-functions'
import SelectInput from '../../common/inputs/SelectInput'
import InfoBox from '../../common/info-box/InfoBox'

const ChangePackage = ({ cid, status, setModal, service_count, complaint_count, fetchData }) => {
    const [form, setForm] = useState({ cid, current_status: status, start_date: YYYYMMDDFormat(new Date()) })
    const [loading, setLoading] = useState(false)

    const packageList = [
        { option: 'AMC', value: 'AMC', },
        { option: 'SSP', value: 'SSP', },
        { option: 'I/W', value: 'I/W', },
        { option: 'O/W', value: 'O/W', },
        { option: 'O/C', value: 'O/C', },
    ]

    const handleChange = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true)

        userAxios.put('/customer/change-package', form).then((response) => {
            toast.success(response.message)
            setLoading(false)
            setModal({ status: false })
            fetchData()
        }).catch((error) => {
            toast.error(error.message)
            setModal({ status: false })
            setLoading(false)
        })
    }

    return (
        <div className="change-package-div">
            <form action="" onSubmit={handleSubmit}>
                {(service_count + complaint_count) > 0 &&
                    <InfoBox type='info' description={'> Current package will expired and new package will start on input date. \n > Only change package related information.'} />
                }
                <div style={{ marginTop: '10px' }}>
                    <NormalInput label='Current package' value={form?.current_status} />
                    <SelectInput label='New package' values={packageList} name='new_status' onChangeFun={handleChange} firstOption={{ option: 'Choose', value: "" }} />
                    {(service_count + complaint_count) > 0 &&
                        <NormalInput label='New package start date' value={form?.start_date} type={'date'} name='start_date' onChangeFun={handleChange} />
                    }
                </div>
                <div className="buttons-div">
                    <button type='submit'>{loading ? <span className='loading-icon'><BiLoaderAlt /></span> : "Submit"}</button>
                </div>
            </form>
        </div>
    )
}

export default ChangePackage