import React, { useState } from 'react'
import './style.scss'
import { BiLoaderAlt } from 'react-icons/bi'
import SelectInput from '../../../components/common/inputs/SelectInput'
import NormalInput from '../../common/inputs/NormalInput'
import InfoBox from '../../common/info-box/InfoBox'
import { toast } from 'react-hot-toast'
import { userAxios } from '../../../config/axios'

const TokenTopUp = ({ setModal }) => {
    const [loading, setLoading] = useState(false)
    const [form, setForm] = useState({})

    const paymentMethods = [
        { option: 'CASH', value: 'CASH' },
        { option: 'BANK', value: 'BANK' },
        { option: 'CASH-AND-BANK', value: 'CASH-AND-BANK' },
        { option: 'CHEQUE', value: 'CHEQUE' }
    ]

    const singleTokenAmt = 200

    const handleChange = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        })

        if (e.target.name === 'number_of') {
            setForm({
                ...form,
                [e.target.name]: e.target.value,
                total_amount: Number(e.target.value) * singleTokenAmt
            })
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        const received = (Number(form?.cash) || 0) + (Number(form?.cheque) || 0) + (Number(form?.bank) || 0)
        if (form.total_amount !== received) {
            toast.error('Received amount not equal to total amount')
            return
        }

        setLoading(true)
        userAxios.post('/package/token-topup', form).then(() => {
            setModal({ status: false })
            setLoading(false)
            toast.success('Top-up Completed!')
        }).catch((error) => {
            setLoading(false)
            toast.error(error.message)
        })
    }

    return (
        <div className="token-top-up-div">
            <form action="" onSubmit={handleSubmit}>

                <div className="amount-div">
                    <p>200 x {form?.number_of || 0}</p>
                    <h3>₹{(form?.total_amount) || 0}</h3>
                </div>
                <NormalInput label='Customer ID' name='cid' type={'number'} value={form?.cid} onChangeFun={handleChange} />
                <NormalInput label='Number of token' name='number_of' type={'number'} value={form?.number_of} onChangeFun={handleChange} min={1} max={3} />
                <SelectInput label='Payment method' name='received_method' values={paymentMethods} onChangeFun={handleChange} firstOption={{ value: '', option: 'Choose...' }} />
                <div className="two-input-single-line-div">
                    {(form?.received_method === 'CASH-AND-BANK' || form?.received_method === 'CASH') &&
                        <NormalInput label='As cash (₹)' type={'number'} name='cash' value={form?.cash} onChangeFun={handleChange} />}
                    {(form?.received_method === 'BANK' || form?.received_method === 'CASH-AND-BANK') &&
                        <NormalInput label='To bank (₹)' type={'number'} name='bank' value={form?.bank} onChangeFun={handleChange} />}
                    {(form?.received_method === 'CHEQUE') &&
                        <NormalInput label='As Cheque (₹)' type={'number'} name='cheque' value={form?.cheque} onChangeFun={handleChange} />}
                </div>
                {(form?.received_method === 'BANK' || form?.received_method === 'CASH-AND-BANK' || form?.received_method === 'CHEQUE') &&
                    <NormalInput label='Transaction ID / Cheque no' type={'text'} name='tran_track_no' value={form?.tran_track_no} onChangeFun={handleChange} />}
                <InfoBox description={'Credit not allowed for this payment'} />
                <div className="button-div" >
                    <button type='submit'>{loading ? <span className='loading-icon'><BiLoaderAlt /></span> : "Submit"}</button>
                </div>
            </form>
        </div>
    )
}

export default TokenTopUp