import React, { useEffect, useState } from 'react'
import './home.scss'
import SinglePage from '../../../components/common/page/SinglePage'
import Spinner from '../../../components/common/spinners/SpinWithMessage'
import Modal from '../../../components/common/modal/Modal'
import TokenTopUp from '../../../components/user/token-top-up/TokenTopUp'
import { useSelector } from 'react-redux'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { TbMapPinQuestion } from 'react-icons/tb'
import ServicedChooseBox from '../../../components/user/choose-box-dar-list/ServicedChooseBox'


function Home() {
    const { user } = useSelector((state) => state.userAuth)
    const [searchParams, setSearchParams] = useSearchParams();
    const [modal, setModal] = useState({ content: null, title: null, status: false })
    const navigate = useNavigate()

    const openModal = (elem, head, data) => {
        setModal({
            content: elem,
            title: head,
            status: true
        })
    }

    useEffect(() => {
        const id = searchParams.get('id');
        if (!id && user._id) {
            setSearchParams(`id=${user._id}`)
        }
        // eslint-disable-next-line
    }, [])


    return (
        <div className="home-page-div">
            <Modal modal={modal} setModal={setModal} />
            <SinglePage titleArray={[`Name : ${user.first_name} ${user.last_name}`, `Zone : ${user?.active_zone?.zone || 'Nill'}`]}>
                {user?.active_zone?.zone ?
                    <div className="home-border">
                        <div className="button-div" >
                            <button onClick={() => navigate('/scheduled-works')} >SCHEDULED WORKS
                            </button>
                        </div>
                        <div className="button-div" >
                            <button onClick={() => navigate('/pending-service-list?page=complaint')} >PENDING SERVICE LIST
                            </button>
                        </div>
                        <div className="button-div" >
                            <button onClick={() => navigate('/kms')} >ENTER Kms
                            </button>
                        </div>
                        <div className="button-div" >
                            <button onClick={() => openModal(<ServicedChooseBox setModal={setModal} />, 'CHOOSE DATE')} >DAR LIST
                            </button>
                        </div>
                        <div className="button-div" >
                            <button onClick={() => openModal(<TokenTopUp setModal={setModal} />, 'TOKEN TOP-UP')} >TOKEN TOP-UP
                            </button>
                        </div>
                        <div className="button-div" >
                            <button onClick={() => navigate('/settings')} >SETTINGS
                            </button>
                        </div>

                    </div>
                    : <Spinner message='No active zone' icon={<TbMapPinQuestion />} spin={false} />
                }
            </SinglePage >
        </div >
    )
}

export default Home