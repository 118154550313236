import React, { useState, useEffect, useRef } from 'react'
import './timer.scss'

const Timer = () => {
    const [time, setTime] = useState({ minutes: 0, seconds: 0, milliseconds: 0 });
    const [isActive, setIsActive] = useState(false);
    const intervalRef = useRef(null);

    useEffect(() => {
        return () => {
            clearInterval(intervalRef.current);
        };
    }, []);

    const startTimer = () => {
        setIsActive(true);
        intervalRef.current = setInterval(() => {
            setTime((prevTime) => {
                let newMilliseconds = prevTime.milliseconds + 10;
                let newSeconds = prevTime.seconds;
                let newMinutes = prevTime.minutes;

                if (newMilliseconds === 1000) {
                    newSeconds += 1;
                    newMilliseconds = 0;
                }

                if (newSeconds === 60) {
                    newMinutes += 1;
                    newSeconds = 0;
                }

                return { minutes: newMinutes, seconds: newSeconds, milliseconds: newMilliseconds };
            });
        }, 10);
    };

    const pauseTimer = () => {
        setIsActive(false);
        clearInterval(intervalRef.current);
    };

    const resetTimer = () => {
        setIsActive(false);
        clearInterval(intervalRef.current);
        setTime({ minutes: 0, seconds: 0, milliseconds: 0 });
    };

    const stopTimer = () => {
        pauseTimer();
        resetTimer();
    };

    return (
        <div className='timer-div'>
            <div className='timer-top'>
                <span>{String(time.minutes).padStart(2, '0')}</span>
                <span> : {String(time.seconds).padStart(2, '0')}</span>
                <span className='milliseconds'>{String(time.milliseconds).padStart(3, '0').slice(0, 2)}</span>
            </div>
            <div className='timer-buttons'>
                {isActive ? <>
                    <button onClick={pauseTimer} class={'stop'}>
                        Stop
                    </button>
                </> : <>
                    {(time?.milliseconds || time?.minutes || time?.seconds) ? <button onClick={resetTimer} class={'reset'}>
                        Reset
                    </button> : ''}
                    <button onClick={startTimer} class={(time?.milliseconds || time?.minutes || time?.seconds) ? "resume" : "start"}>
                        {(time?.milliseconds || time?.minutes || time?.seconds) ? "Resume" : "Start"}
                    </button>
                </>}
            </div>
        </div>
    );
}

export default Timer