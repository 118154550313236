import React, { useState } from 'react'
import './add-description.scss'
import NormalInput from '../../common/inputs/NormalInput'
import { useSelector } from 'react-redux'
import { userAxios } from '../../../config/axios'
import { BiLoaderAlt } from 'react-icons/bi'
import toast from 'react-hot-toast'

const AddDescription = ({ cid, setModal, setCustomer }) => {
    const [text, setText] = useState('')
    const { user } = useSelector((state) => state.userAuth)
    const [loading, setLoading] = useState(false)


    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true)
        let obj = {
            cid,
            by_id: user?._id,
            by: 'Technician',
            text
        }
        userAxios.post('/technician/description', obj).then((response) => {
            toast.success(response.message)
            setLoading(false)
            setModal({ status: false })
            setCustomer((state) => ({
                ...state,
                purifier_work_description: [
                    ...(state?.purifier_work_description || []),
                    { ...obj, date: new Date(), description: text }
                ]
            }))
        })
    }

    return (
        <div className="add-description-div">
            <form action="" onSubmit={handleSubmit}>
                <NormalInput label='Description' value={text} name='description' onChangeFun={(e) => setText(e.target.value)} />
                <div className="button-div">
                    <button type='submit'>{loading ? <span className='loading-icon'><BiLoaderAlt /></span> : "Submit"}</button>
                </div>
            </form>
        </div>
    )
}

export default AddDescription