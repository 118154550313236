import React, { useState, useEffect } from 'react'
import './service.scss'
import SinglePage from '../../../components/common/page/SinglePage'
import { useLocation, useNavigate } from 'react-router-dom'
import SpinWithMessage from '../../../components/common/spinners/SpinWithMessage'
import ScheduledDetails from '../../../components/user/service-details/ScheduledDetails'
import CancelSchedule from '../../../components/user/cancel-schedule/CancelSchedule'
import Modal from '../../../components/common/modal/Modal'
import InfoBox from '../../../components/common/info-box/InfoBox'
import { serviceAxios, customerAxios } from '../../../config/axios'
import { TbClockPlay, TbCalendarX, TbRotateClockwise2 } from 'react-icons/tb'
import { AiOutlineClear } from 'react-icons/ai'
import { BiLoaderAlt, BiReset } from 'react-icons/bi'
import { useDispatch, useSelector } from 'react-redux'
import { clearFormData } from '../../../redux/features/user/serviceSlice'
import toast from 'react-hot-toast'


const ScheduledService = () => {
    const location = useLocation()
    const navigate = useNavigate()
    const { internet } = useSelector((state) => state.network)
    const { urlSections, serviceForm } = useSelector((state) => state.serviceData)
    const [loading, setLoading] = useState('fetch')
    const [customer, setCustomer] = useState({})
    const [purifier, setPurifier] = useState({})
    const [regDetails, setRegDetails] = useState({})
    const [eligibility, setEligibility] = useState({})
    const [modal, setModal] = useState({ content: null, title: null, status: false })
    const dispatch = useDispatch();

    const openModel = (element, title) => {
        setModal({
            content: element,
            title: title,
            status: true
        })
    }

    useEffect(() => {
        if (!location?.state?.cid) {
            navigate('/pending-service-list?page=complaint')
        }

        const apiPromises = [];
        // Customer details
        const profile = customerAxios.get(`/profile?cid=${location?.state?.cid}&detail=YES`).then((response) => {
            setCustomer(response.data)
        })
        apiPromises.push(profile)

        const product = customerAxios.get(`/service/purifier?cid=${location?.state?.cid}`).then((response) => {
            setPurifier(response.data)
        })
        apiPromises.push(product)

        const eligibility = customerAxios.get(`/service/purifier/eligibility?cid=${location?.state?.cid}`).then((response) => {
            setEligibility(response.data)
        })
        apiPromises.push(eligibility)

        if (location?.state?.reg_no) {
            const regDetails = serviceAxios.get(`/registered/${location?.state?.type}?reg_no=${location?.state?.reg_no}`).then((response) => {
                setRegDetails(response.data)
            })
            apiPromises.push(regDetails)
        }

        Promise.all(apiPromises).then(() => setLoading(''))
        // eslint-disable-next-line
    }, [])

    const attendTheWork = () => {
        if (internet && loading !== 'attend') {
            setLoading('attend')

            serviceAxios.post('/attend', { type: location?.state?.type, reg_no: location?.state?.reg_no }).then((response) => {
                navigate('/attending?section=current_condition', {
                    state: {
                        in_time: new Date(),
                        reg_no: location?.state?.reg_no,
                        type: location?.state?.type,
                        cid: location?.state?.cid
                    }
                })
                setLoading('')
            }).catch((error) => {
                toast.error(error.message)
                setLoading('')
            })
        } else if (!internet) {
            toast.error('No Internet')
            setLoading('')
        }
    }

    const clearWork = () => {
        const ask = window.confirm('Are you clearing current activity data ?')
        if (ask) {
            if (internet && loading !== 'attend') {
                setLoading('clear')
                serviceAxios.post('/attend/clear', { type: location?.state?.type, reg_no: location?.state?.reg_no }).then((response) => {
                    setRegDetails((state) => ({ ...state, status: 'Scheduled' }))
                    dispatch(clearFormData())
                    setLoading('')
                }).catch((error) => {
                    toast.error(error.message)
                    setLoading('')
                })
            } else if (!internet) {
                toast.error('No Internet')
            }
        }
    }



    return (
        <div className='pending-service-div'>
            <Modal modal={modal} setModal={setModal} />
            <SinglePage titleArray={[`CID : ${location?.state?.cid}`, `Type : ${location?.state?.type}`]}>
                {loading === 'fetch'
                    ? <>
                        <SpinWithMessage message='fetch data...' />
                    </>
                    : <>
                        <div className='service-details-main-div'>
                            <div className="page-border-div">
                                <div className="content-div">
                                    <ScheduledDetails customer={customer} purifier={purifier} regDetails={regDetails} type={location?.state?.type}
                                        eligibility={eligibility} />
                                </div>

                                {purifier?.pending_ssp_token <= 1 && purifier?.purifier_customer_status === 'SSP' && !eligibility?.renewal &&
                                    <InfoBox type='warning' title={'Token expiring soon...'} description={`This is the customer's last token and informs to top-up the token for balance services.
                                    Top-up must be done before this service`} />}

                                {regDetails.status === 'Attending'
                                    ? <div className="button-div">
                                        <button onClick={clearWork}>
                                            {loading === 'clear' ? <span className='loading-icon'><BiLoaderAlt /></span> : serviceForm?.cid === location?.state?.cid ? <AiOutlineClear /> : <BiReset />}
                                            <span>{serviceForm?.cid === location?.state?.cid ? 'Clear work data' : "Reset data"}</span></button>

                                        {serviceForm?.cid === location?.state?.cid && <button className='green' onClick={() => navigate(`/attending?section=${urlSections?.[urlSections.length - 1] || 'current_condition'}`)}>
                                            <TbRotateClockwise2 /><span>Continue work</span></button>}
                                    </div>
                                    : <div className="button-div">
                                        <button className='cancel-button' onClick={() => openModel(<CancelSchedule setModal={setModal} type={location?.state?.type}
                                            reg_no={regDetails?.reg_no} />, 'Cancel schedule')}>
                                            <TbCalendarX /><span>Cancel schedule</span></button>

                                        <button className='green' onClick={attendTheWork}>
                                            {loading === 'attend' ? <span className='loading-icon'><BiLoaderAlt /></span> : <TbClockPlay />}<span>Attend work</span></button>
                                    </div>
                                }
                            </div>
                        </div>
                    </>}
            </SinglePage >
        </div >
    )
}

export default ScheduledService