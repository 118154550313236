import React, { useState } from 'react'
import './add-comment.scss'
import TextAreaInput from '../../common/inputs/TextArea'
import { useSelector } from 'react-redux'
import { customerAxios } from '../../../config/axios'
import { BiLoaderAlt } from 'react-icons/bi'
import toast from 'react-hot-toast'

const AddCidComment = ({ cid, setModal, setData, data }) => {
    const [text, setText] = useState(data?.comment || '')
    const { admin } = useSelector((state) => state.adminAuth)
    const [loading, setLoading] = useState(false)


    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true)

        if (data) {
            let obj = {
                cid,
                comment: text,
                tag_id: data?._id
            }

            customerAxios.put('/tag/re-pin', obj).then((response) => {
                toast.success(response.message)
                setData((state) => state.map((obj) => {
                    if (obj.cid === cid) {
                        return {
                            ...obj,
                            tag_list: obj?.tag_list?.map((tag) => {
                                if (tag._id === data?._id) {
                                    return {
                                        ...tag,
                                        comment: text
                                    }
                                }
                                return tag
                            })
                        }
                    }
                    return obj
                }))
                setLoading(false)
                setModal({ status: false })
            })
        } else {
            let obj = {
                cid,
                comment: text,
                tag_type: 'pr_admin',
                tagged_by: admin._id
            }
            customerAxios.post('/tag/pin', obj).then((response) => {
                toast.success(response.message)
                setData((state) => state?.map((obj) => {
                    if (obj.cid === cid) {
                        return {
                            ...obj,
                            tag_list: Array.isArray(obj.tag_list) ? [...obj.tag_list, response?.data] : [response?.data]
                        }
                    }
                    return obj
                }))
                setLoading(false)
                setModal({ status: false })
            })
        }

    }

    return (
        <div className="add-description-div">
            <form action="" onSubmit={handleSubmit}>
                <TextAreaInput label='Type Comment' value={text} name='description' onChangeFun={(e) => setText(e.target.value)} />
                <div className="buttons-div">
                    <button type='submit'>{loading ? <span className='loading-icon'><BiLoaderAlt /></span> : "Submit"}</button>
                </div>
            </form>
        </div>
    )
}

export default AddCidComment