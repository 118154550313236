import React, { useState } from 'react'
import NormalInput from '../../common/inputs/NormalInput'
import './schedule-now.scss'
import { ISOtoDateTimeInput } from '../../../assets/javascript/formate-functions'
import { BsInfoCircle } from 'react-icons/bs'
import { serviceAxios } from '../../../config/axios'
import toast from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'
import { BiLoaderAlt } from 'react-icons/bi'

const ScheduleNow = ({ reg_no, cid, type, zone_id, service_index }) => {
    const [date, setDate] = useState('')
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true)
        const ISOdate = new Date(date)
        serviceAxios.post('/schedule', { reg_no, cid, type, zone_id, service_index, dateTime: ISOdate }).then((response) => {
            toast.success('Successfully scheduled')
            navigate('/pending-service-list?page=complaint')
            setLoading(false)
        }).catch((error) => {
            toast.error(error.message)
            setLoading(false)
        })
    }

    return (
        <div className="schedule-now-div">
            <form action="" onSubmit={handleSubmit}>
                <small><BsInfoCircle /> Select a approximate time </small>
                <NormalInput label='Date and Time' type={'datetime-local'} value={date} name='date' min={ISOtoDateTimeInput(new Date())}
                    onChangeFun={(e) => setDate(e.target.value)} />
                <div className="button-div">
                    <button type={loading ? 'button' : 'submit'}>{loading ? <span className='loading-icon'><BiLoaderAlt /></span> : "Schedule"}</button>
                </div>
            </form>
        </div>
    )
}

export default ScheduleNow