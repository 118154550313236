import React, { useEffect, useState } from 'react'
import ServiceFullData from '../../../components/admin/service-full-data/ServiceFullData'
import AdminPage from '../../../components/common/page/AdminPage'
import { servicedAxios } from '../../../config/axios'
import { useNavigate, useParams } from 'react-router-dom';
import SpinWithMessage from '../../../components/common/spinners/SpinWithMessage'


const ServicedData = () => {
    const { srl_number } = useParams();
    const [serviceDetails, setServiceDetails] = useState({})
    const navigate = useNavigate()
    const [loading, setLoading] = useState('fetch')

    useEffect(() => {
        servicedAxios.get(`/details?srl_number=${srl_number}`).then((response) => {
            setLoading('')
            setServiceDetails(response.data)
        }).catch(() => {
            navigate('/admin/serviced-list')
        })
    }, [])


    return (
        <div className="service-data-div">
            <AdminPage titleArray={[`SRL NO : ${srl_number}`]}>
                {loading
                    ? <SpinWithMessage message='Fetch data...'/>
                    : <ServiceFullData data={serviceDetails} admin={true} />
                }
            </AdminPage>
        </div>
    )
}

export default ServicedData