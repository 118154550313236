import React, { useEffect, useState } from 'react'
import './product-choose.scss'
import { changeFormValue } from '../../../redux/features/user/serviceSlice'
import { useDispatch, useSelector } from 'react-redux'
import SelectInput from '../../../components/common/inputs/SelectInput'
import InfoBox from '../../common/info-box/InfoBox'
import { product_usages } from '../../../assets/javascript/const-data'


const ProductChoose = React.memo(({ product_list, setProductPopup, setProductDetails, setShowInput }) => {
    const dispatch = useDispatch();
    const { serviceForm } = useSelector((state) => state.serviceData)
    const [productList, setProductList] = useState([])
    const [productUsageList, setProductUsageList] = useState([])
    const [form, setForm] = useState({ product_id: null, product_usage: null })


    const handleChange = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        })
    }


    useEffect(() => {
        setProductList(product_list?.map((product) => ({
            option: `${product.product_name} (${product.product_category})`,
            value: product._id
        })))

        setProductUsageList(product_usages.map((item) => (
            { option: item, value: item }
        )))
    }, [product_list])


    const handleSectionSubmit = (e) => {
        e.preventDefault()

        dispatch(changeFormValue({
            ...serviceForm,
            cabin_details: form,
            product_added: true
        }))

        const product = product_list.filter((item) => item._id == form.product_id)
        setProductDetails(product?.[0])

        if (['UV', 'UV+UF'].includes(product?.[0]?.product_category)) {
            setShowInput((state) => ({
                ...state,
                pump: false,
                fr: false,
                rejectWater: false,
                ph: false
            }))
        } else if (['RO+ALKALINE', 'RO+UV+ALKALINE', 'RO+UV+UF+ALKALINE'].includes(product?.[0]?.product_category)) {
            setShowInput((state) => ({
                ...state,
                pump: true, fr: true, rejectWater: true, ph: true
            }))
        } else {
            setShowInput((state) => ({
                ...state,
                pump: true, fr: true, rejectWater: true, ph: false
            }))
        }

        setProductPopup(false)
    }


    return (
        <div className="service-form-section-div">
            <div className="title-section">
                <h3>Enter product details</h3>
                <p>This customer product details not available. You must add the details to continue the service.</p>
            </div>
            <br></br>
            <InfoBox type={'info'} description={'If you exit the service before submission, You will be prompted for this again when continuing the same service.'} />

            <div className="form-div">
                <form action="" onSubmit={handleSectionSubmit}>

                    {/* Leak */}
                    <div className="radio-input-border-div">
                        <div className="sub-title"><h4>Select customer product</h4></div>
                        <SelectInput label='Product name' name='product_id'
                            values={productList} firstOption={{ option: 'Choose...', value: '' }}
                            onChangeFun={handleChange} />
                        <SelectInput label='Product usage' name='product_usage'
                            values={productUsageList} firstOption={{ option: 'Choose...', value: '' }}
                            onChangeFun={handleChange} />
                    </div>



                    {/* Next Buttons */}
                    <div className="handle-buttons-div">
                        <button type='submit' className='next'>Next</button>
                    </div>

                </form>
            </div >
        </div >
    )
})

export default ProductChoose