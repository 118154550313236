import React, { useEffect, useState } from 'react'
import SinglePage from '../../../components/common/page/SinglePage'
import ServiceFullData from '../../../components/admin/service-full-data/ServiceFullData'
import { servicedAxios } from '../../../config/axios'
import { useNavigate, useParams } from 'react-router-dom';
import SpinWithMessage from '../../../components/common/spinners/SpinWithMessage'

const ServiceData = () => {
    const { srl_number } = useParams();
    const [serviceDetails, setServiceDetails] = useState({})
    const navigate = useNavigate()
    const [loading, setLoading] = useState('fetch')

    useEffect(() => {
        servicedAxios.get(`/details?srl_number=${srl_number}`).then((response) => {
            setServiceDetails(response.data)
            setLoading('')
        }).catch(() => {
            navigate('/dar-list')
        })
    }, [])

    return (
        <SinglePage titleArray={[`SRL NO : ${srl_number}`]}>
            {loading
                ? <SpinWithMessage message='Fetch data...' />
                : <ServiceFullData data={serviceDetails} />
            }
        </SinglePage>
    )
}

export default ServiceData