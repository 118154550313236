import React, { useEffect, useState } from 'react'
import './cancel-schedule.scss'
import SelectInput from '../../../components/common/inputs/SelectInput'
import NormalInput from '../../common/inputs/NormalInput'
import { objectToSelectOptions } from '../../../assets/javascript/find-functions'
import { BiLoaderAlt } from 'react-icons/bi'
import { serviceAxios, userAxios } from '../../../config/axios'
import { toast } from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'



const CancelSchedule = ({ setModel, type, reg_no }) => {
    const [option, setOption] = useState('')
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState([])
    const navigate = useNavigate()
   

    const handleChange = (e) => {
        setOption(e.target.value)
    }


    const handelSubmit = (e) => {
        e.preventDefault()
        setLoading(true)
        serviceAxios.delete(`/schedule?type=${type}&reg_no=${reg_no}&reason=${option}`).then((response) => {
            navigate('/scheduled-works')
            toast.success(response.message)
            setLoading(false)
        }).catch((error) => {
            toast.error(error.message)
            setLoading(false)
        })
    }

    useEffect(() => {
        setLoading(true)
        userAxios.get(`/resource/single/cancel_schedules_reasons`).then((response) => {
            setData(objectToSelectOptions(response.data, 'option'))
            setLoading(false)
        })
    }, [])


    return (
        <div className="cancel-schedule-div">
            <form action="" onSubmit={handelSubmit}>
                <SelectInput label='Reason' name='reason' values={data} onChangeFun={handleChange} firstOption={{ value: '', option: 'Choose...' }} />
                <div className="button-div">
                    <button type='submit'>{loading ? <span className='loading-icon'><BiLoaderAlt /></span> : "Cancel schedule"}</button>
                </div>
            </form>
        </div>
    )
}

export default CancelSchedule