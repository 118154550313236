import React, { useEffect, useState } from 'react'
import './style.scss'
import { changeFormValue, nextAction } from '../../../redux/features/user/serviceSlice'
import { useDispatch, useSelector } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import SelectInput from '../../common/inputs/SelectInput'
import MultiSelect from '../../common/inputs/MultiSelect'
import NormalInput from '../../common/inputs/NormalInput'
import toast from 'react-hot-toast'

const SectionFour = React.memo(({ resources, purifier, showInput }) => {
    const dispatch = useDispatch();
    const { serviceForm } = useSelector((state) => state.serviceData)
    // eslint-disable-next-line
    const [searchParams, setSearchParams] = useSearchParams();
    const [natureList, setNatureList] = useState([])
    const [reasonList, setReasonList] = useState([])
    const [solutionList, setSolutionList] = useState([])
    const [noComplaint, setNoComplaint] = useState(false)

    const actionList = [
        { option: 'Completed', value: 'Completed', selected: "Completed" === serviceForm?.inspection_report?.action },
        { option: 'Not completed', value: 'Not completed', selected: "Not completed" === serviceForm?.inspection_report?.action }
    ]

    const handleSectionSubmit = (e) => {
        e.preventDefault()

        if (!noComplaint && !serviceForm?.inspection_report?.reason_of_complaint?.[0]) {
            toast.error('Select any reasons')
            return
        }
        if (!noComplaint && !serviceForm?.inspection_report?.solution_of_complaint?.[0]) {
            toast.error('Select any solutions')
            return
        }

        // to section five
        dispatch(nextAction(['evaluation', 'inspection_report']))
        setSearchParams(`section=evaluation`)

    }

    const handleChangeNormal = (e) => {
        dispatch(changeFormValue({
            ...serviceForm,
            inspection_report: {
                ...serviceForm?.inspection_report,
                [e.target.name]: e.target.value
            }
        }))

        if (e.target.name === 'action') {
            dispatch(changeFormValue({
                ...serviceForm,
                inspection_report: {
                    ...serviceForm?.inspection_report,
                    [e.target.name]: e.target.value,
                    action_description: undefined
                }
            }))
        }
    }

    const handleSelectMulti = (selected, name) => {
        let selectedOptions = []
        let ReasonList = []
        let SolutionList = []
        selected.map((obj) => {
            if (name === 'nature_of_complaint') {
                let selectedItem = resources?.complaint_nature?.filter((item) => item?.option === obj.value)
                selectedItem?.[0]?.sub_options_one?.map((option) => ReasonList.push({
                    label: option,
                    value: option
                }))
                selectedItem?.[0]?.sub_options_two?.map((option) => SolutionList.push({
                    label: option,
                    value: option
                }))
            }
            selectedOptions.push(obj.value)
            return obj
        })
        
        if (name === 'nature_of_complaint') {
            setReasonList(ReasonList)
            setSolutionList(SolutionList)
        }

        dispatch(changeFormValue({
            ...serviceForm,
            inspection_report: {
                ...serviceForm?.inspection_report,
                [name]: selectedOptions
            }
        }))
    }

    useEffect(() => {
        let ReasonList = []
        let SolutionList = []
        setNatureList(resources?.complaint_nature?.map((item) => {
            if (serviceForm?.inspection_report?.nature_of_complaint?.includes(item.option)) {
                item?.sub_options_one?.map((option) => ReasonList.push({
                    label: option,
                    value: option
                }))
                item?.sub_options_two?.map((option) => SolutionList.push({
                    label: option,
                    value: option
                }))
            }

            return {
                label: item.option,
                value: item.option,
            }
        }))
        setReasonList(ReasonList)
        setSolutionList(SolutionList)

        if (serviceForm?.before_checking_data?.tds_status === 'Normal' &&
            serviceForm?.before_checking_data?.filtered_water_flow_status === 'Normal' &&
            (serviceForm?.before_checking_data?.reject_water_flow_status === 'Normal'
                || !showInput?.rejectWater) &&
            !serviceForm?.before_checking_data?.leak &&
            !serviceForm?.before_checking_data?.scratch_crack) {
            setNoComplaint(true)
        } else {
            setNoComplaint(false)
        }
        // eslint-disable-next-line
    }, [resources])


    return (
        <div className="service-form-section-div">
            <div className="title-section">
                <h3>Inspection report</h3>
                {/* <p>Description</p> */}
            </div>
            <div className="form-div">
                <form action="" onSubmit={handleSectionSubmit}>

                    {!noComplaint && <>
                        <MultiSelect label='Nature of complaint' name='nature_of_complaint' values={natureList}
                            selectedValue={serviceForm?.inspection_report?.nature_of_complaint?.map((value) => ({
                                label: value, value: value
                            }))} onChangeFun={(selected) => handleSelectMulti(selected, 'nature_of_complaint')} />
                        <MultiSelect label='Reason of complaint' name='reason_of_complaint' values={reasonList}
                            selectedValue={serviceForm?.inspection_report?.reason_of_complaint?.map((value) => ({
                                label: value, value: value
                            }))} onChangeFun={(selected) => handleSelectMulti(selected, 'reason_of_complaint')} />
                        <MultiSelect label='Solution of complaint' name='solution_of_complaint' values={solutionList}
                            selectedValue={serviceForm?.inspection_report?.solution_of_complaint?.map((value) => ({
                                label: value, value: value
                            }))} onChangeFun={(selected) => handleSelectMulti(selected, 'solution_of_complaint')} />
                    </>}

                    <SelectInput label='Action' name='action' values={actionList} firstOption={{ option: 'Choose...', value: '' }}
                        onChangeFun={handleChangeNormal} />
                    {serviceForm?.inspection_report?.action === 'Not completed' && <NormalInput label='Action description' name='action_description' value={serviceForm?.inspection_report?.action_description}
                        onChangeFun={handleChangeNormal} />}



                    {/* Next Buttons */}
                    <div className="handle-buttons-div">
                        <button type='submit' className='next'>Next</button>
                    </div>
                </form>
            </div>
        </div>
    )
})

export default SectionFour