import React from 'react'
import Spinner from '../../../components/common/spinners/SpinWithMessage'
import TableFilter from '../../../components/common/table-filter/TableFilter'
import { RxDashboard } from 'react-icons/rx'
import { FiEdit2 } from 'react-icons/fi'
import { LuExpand } from "react-icons/lu";
import { AiOutlineClose } from "react-icons/ai";
import { RiChatNewLine } from 'react-icons/ri'
import { formatStringDate } from '../../../assets/javascript/formate-functions'
import RegFullView from '../../../components/admin/reg-full-view/RegFullView'
import RegisterService from '../../../components/admin/register-service/RegisterService'
import ServiceCancel from '../../../components/admin/service-cancel/ServiceCancel'
import AddDescription from '../../../components/admin/add-description/AddDescription'
import { downloadRegDataXl, regDataExportToExcel } from '../../../assets/javascript/reg-xl-functions';

const TableView = ({ type, status, data, doRefresh, setModal, setData, dataList }) => {

    const openModal = (elem, head, width) => {
        setModal({
            content: elem,
            title: head,
            status: true,
            width: width
        })
    }

    const handleDownloadButton = () => {
        const workbook = regDataExportToExcel(dataList, type);
        downloadRegDataXl(workbook, `${type === 'complaint' ? 'Complaints Reg List' : 'Service & Renewals Reg List'}`)
    }
    

    return (
        <div className="table-view-div">
            {data?.[0]
                ? <TableFilter topRight={<div className='buttons-div' style={{ display: 'flex', gap: '10px' }} >
                    <button title='Refresh' className='re-button' onClick={doRefresh}>Refresh</button>
                    <button title='Refresh' className='re-button' onClick={handleDownloadButton}>Download XL</button>
                </div>}>
                    <table >
                        <thead>
                            <tr>
                                <th>Reg no. / Date</th>
                                <th>Name</th>
                                {type === 'complaint' ? <th>What is the complaint /<br></br> Priority / Index</th>
                                    : <th>Service</th>}
                                <th>Send zone /<br></br> Technician</th>
                                {(status === 'Scheduled' || status === 'Attending') && <th>Scheduled Date /<br></br> Technician</th>}
                                {status === 'Closed' && <th>Closed Date /<br></br> By staff</th>}
                                {status === 'Cancelled' && <th>Cancelled Date /<br></br> By staff</th>}
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody >
                            {data?.map((obj) => <tr>
                                <td style={{ textAlign: 'center' }}>
                                    <span style={{ fontWeight: 700 }}>{obj?.reg_no}</span>
                                    <br></br>
                                    {formatStringDate(new Date(obj?.registered_date_time))}
                                    <br></br>
                                    {new Date(obj?.registered_date_time).toLocaleTimeString()}
                                    <br></br>
                                    {obj?.customer_reg && <small className={`text-badge pink-text`}>{'By customer'}</small>}
                                </td>

                                <td style={{ textAlign: 'center' }}>
                                    CID :  {obj.cid}  <small className={`text-badge ${obj?.purifier_customer_status}-text`}>{obj?.purifier_customer_status}</small>
                                    <br></br>
                                    <span style={{ fontWeight: 700 }}>{obj?.full_name}</span>
                                    <br></br>
                                    {obj?.address?.post && `${obj?.address?.post} P.O`} ({obj?.customer_zone})
                                </td>

                                {type === 'complaint' ? <td style={{ textAlign: 'center' }}>
                                    {obj?.what_is_complaint?.map((value) => `${value}, `)}
                                    <br></br>
                                    <small className={`text-badge ${obj?.priority}-text`}>{obj?.priority}</small>
                                    <small className={`text-badge no-text`}>C{obj?.complaint_index}</small>
                                </td>
                                    : <td style={{ textAlign: 'center' }}>
                                        {obj?.renewal ? 'Renewal' : "Service / Periodical"}
                                        <br></br>
                                        <small className={`text-badge no-text`}>S{obj?.service_index}</small>
                                    </td>}

                                <td style={{ textAlign: 'center' }}>
                                    {`${obj?.send_to_zone}`} <br></br>
                                    {obj?.send_to_technician}
                                </td>

                                {(status === 'Scheduled' || status === 'Attending') && <td style={{ textAlign: 'center' }}>
                                    {formatStringDate(new Date(obj?.scheduled_date_time))}
                                    <br></br>
                                    {new Date(obj?.scheduled_date_time).toLocaleTimeString()}
                                    <br></br>
                                    {`(${obj?.scheduled_tech})`}
                                </td>}

                                {status === 'Closed' && <td style={{ textAlign: 'center' }}>
                                    {formatStringDate(new Date(obj?.closed_date_time))}
                                    <br></br>
                                    {new Date(obj?.closed_date_time).toLocaleTimeString()}
                                    <br></br>
                                    {obj?.closed_by && `(${obj?.closed_by})`}
                                </td>}

                                {status === 'Cancelled' && <td style={{ textAlign: 'center' }}>
                                    {formatStringDate(new Date(obj?.cancelled_date_time))}
                                    <br></br>
                                    {new Date(obj?.cancelled_date_time).toLocaleTimeString()}
                                    <br></br>
                                    {obj?.cancelled_by && `(${obj?.cancelled_by})`}
                                    {obj?.customer_cancel && <small className={`text-badge pink-text`}>{'Self cancel'}</small>}
                                </td>}


                                {/* Hide Td for search */}
                                <td style={{ display: 'none' }}>
                                    {obj?.reg_no} {obj?.purifier_customer_status} {obj?.full_name}
                                    {obj?.priority}  {obj?.status} {new Date(obj?.scheduled_at).toLocaleString()} {obj?.scheduled_tech}
                                    {obj?.closed_by} {new Date(obj?.closed_date_time).toLocaleString()}
                                    {obj?.customer_cancel ? 'Self cancel' : obj?.cancelled_by}  {new Date(obj?.cancelled_date_time).toLocaleString()}
                                    {(obj?.customer_reg) && 'By customer'}
                                </td>
                                {/* End Hide */}

                                <td>
                                    <div className='buttons-div' >
                                        <button title='View' className='button-small-icon view'
                                            onClick={() => openModal(<RegFullView reg_no={obj?.reg_no} type={obj?.renewal ? 'renewal' : obj?.service ? "service" : 'complaint'}
                                                cid={obj?.cid} />, 'Expand View', '700px')}> <LuExpand /></button>

                                        {(status === 'Pending' || status === 'Proceed' || status === 'Scheduled') && <button title='Add Description' className='button-small-icon add'
                                            onClick={() => openModal(<AddDescription cid={obj?.cid} setModal={setModal} />, 'Add Description')}><RiChatNewLine /></button>}

                                        {(status === 'Pending' || status === 'Proceed') && <button title='Edit' className='button-small-icon edit'
                                            onClick={() => openModal(<RegisterService cid={obj?.cid} data={obj} setModal={setModal} setData={setData} />, 'Edit service')}><FiEdit2 /></button>}

                                        {(status === 'Pending' || status === 'Proceed') && <button title='Cancel' className='button-small-icon delete'
                                            onClick={() => openModal(<ServiceCancel data={obj} setData={setData} setModal={setModal} />, 'Cancel service')}><AiOutlineClose /></button>}
                                    </div>
                                </td>

                            </tr>)}
                        </tbody>
                    </table>
                </TableFilter>
                : <>
                    <Spinner message={`Empty ${status}`} icon={<RxDashboard />} spin={false} bottomContent={<div className='buttons-div' >
                        <button title='Refresh' className='re-button' onClick={doRefresh}>Refresh</button>
                    </div>} />

                </>}
        </div>
    )
}

export default TableView