import React from 'react'
import './style.scss'
import { changeFormValue, nextAction } from '../../../redux/features/user/serviceSlice'
import { useDispatch, useSelector } from 'react-redux'
import RadioInput from '../../common/inputs/RadioInput'
import NormalInput from '../../../components/common/inputs/NormalInput'
import SelectInput from '../../../components/common/inputs/SelectInput'
import { useSearchParams } from 'react-router-dom'
import toast from 'react-hot-toast'


const SectionFive = React.memo(({ resources, purifier, showInput }) => {
    const dispatch = useDispatch();
    const { serviceForm } = useSelector((state) => state.serviceData)
    // eslint-disable-next-line
    const [searchParams, setSearchParams] = useSearchParams();


    const pumpList = [
        { option: 'E-CHEN', value: 'E-CHEN', selected: "E-CHEN" === serviceForm?.evaluation_data?.pump },
        { option: 'KEMFLOW', value: 'KEMFLOW', selected: "KEMFLOW" === serviceForm?.evaluation_data?.pump },
        { option: 'E-FLOW', value: 'E-FLOW', selected: "E-FLOW" === serviceForm?.evaluation_data?.pump },
        { option: 'CRUZE', value: 'CRUZE', selected: "CRUZE" === serviceForm?.evaluation_data?.pump },
        { option: 'WAVE', value: 'WAVE', selected: "'WAVE" === serviceForm?.evaluation_data?.pump },
        { option: 'PENTAIR', value: 'PENTAIR', selected: "PENTAIR" === serviceForm?.evaluation_data?.pump },
        { option: 'other', value: 'other', selected: "other" === serviceForm?.evaluation_data?.pump },
    ]

    const frList = [
        { option: '300', value: '300', selected: "300" === serviceForm?.evaluation_data?.fr },
        { option: '350', value: '350', selected: "350" === serviceForm?.evaluation_data?.fr },
        { option: '400', value: '400', selected: "400" === serviceForm?.evaluation_data?.fr },
        { option: '450', value: '450', selected: "450" === serviceForm?.evaluation_data?.fr },
    ]

    // Handle change 

    const handleNormalChange = (e) => {

        dispatch(changeFormValue({
            ...serviceForm,
            evaluation_data: {
                ...serviceForm?.evaluation_data,
                [e.target.name]: e.target.value
            }
        }))

        if (e.target.name === 'filtered_water_flow_ltrs') {
            let status = null
            if (Number(e.target.value) === 0) {
                status = 'No'
            } else if (Number(e.target.value) < 11) {
                status = 'Low'
            } else if (Number(e.target.value) >= 11) {
                status = 'Normal'
            }

            dispatch(changeFormValue({
                ...serviceForm,
                evaluation_data: {
                    ...serviceForm?.evaluation_data,
                    filtered_water_flow_status: status,
                    [e.target.name]: e.target.value
                }
            }))
        }

        if (e.target.name === 'reject_water_flow_ltrs') {
            let status = null
            if (Number(e.target.value) === 0) {
                status = 'No'
            } else if (Number(e.target.value) < Number(serviceForm?.evaluation_data?.fr || 0)) {
                status = 'Low'
            } else if (Number(e.target.value) >= Number(serviceForm?.evaluation_data?.fr || 0)) {
                status = 'Normal'
            }

            dispatch(changeFormValue({
                ...serviceForm,
                evaluation_data: {
                    ...serviceForm?.evaluation_data,
                    reject_water_flow_status: status,
                    [e.target.name]: e.target.value
                }
            }))
        }

        if (e.target.name === 'fr') {
            let status = null
            if (Number(e.target.value || 0) === 0) {
                status = 'No'
            } else if (Number(e.target.value) > Number(serviceForm?.evaluation_data?.reject_water_flow_ltrs || 0)) {
                status = 'Low'
            } else if (Number(e.target.value) <= Number(serviceForm?.evaluation_data?.reject_water_flow_ltrs || 0)) {
                status = 'Normal'
            }

            dispatch(changeFormValue({
                ...serviceForm,
                evaluation_data: {
                    ...serviceForm?.evaluation_data,
                    reject_water_flow_status: status,
                    [e.target.name]: e.target.value
                }
            }))
        }

        if (e.target.name === 'input_tds') {
            let status = null
            if (!Number(e.target.value) || !Number(serviceForm?.evaluation_data?.output_tds)) {
                status = 'No'
            } else if ((Number(e.target.value) * 0.15) >= Number(serviceForm?.evaluation_data?.output_tds || 0)) {
                status = 'Normal'
            } else if ((Number(e.target.value) * 0.15) < Number(serviceForm?.evaluation_data?.output_tds || 0)) {
                status = 'High'
            }

            dispatch(changeFormValue({
                ...serviceForm,
                evaluation_data: {
                    ...serviceForm?.evaluation_data,
                    tds_status: status,
                    [e.target.name]: e.target.value
                }
            }))
        }

        if (e.target.name === 'output_tds') {
            let status = null
            if (!Number(serviceForm?.evaluation_data?.input_tds) || !Number(e.target.value)) {
                status = 'No'
            } else if (5 >= Number(e.target.value)) {
                status = 'Low'
            } else if ((Number(serviceForm?.evaluation_data?.input_tds || 0) * 0.15) >= Number(e.target.value)) {
                status = 'Normal'
            } else if ((Number(serviceForm?.evaluation_data?.input_tds || 0) * 0.15) < Number(e.target.value)) {
                status = 'High'
            }

            dispatch(changeFormValue({
                ...serviceForm,
                evaluation_data: {
                    ...serviceForm?.evaluation_data,
                    tds_status: status,
                    [e.target.name]: e.target.value
                }
            }))
        }

        if (e.target.name === 'ph') {
            let status = null
            if (!Number(e.target.value)) {
                status = 'No'
            } else if (6.5 > Number(e.target.value)) {
                status = 'Low'
            } else if (8.5 < Number(e.target.value)) {
                status = 'High'
            } else {
                status = 'Normal'
            }

            dispatch(changeFormValue({
                ...serviceForm,
                evaluation_data: {
                    ...serviceForm?.evaluation_data,
                    ph_status: status,
                    [e.target.name]: e.target.value
                }
            }))
        }

    }

    const handleSectionSubmit = (e) => {
        e.preventDefault()

        if (!serviceForm?.evaluation_data.water_quality) {
            toast.error('Select water quality')
            return;
        }

        // to section six
        dispatch(nextAction(['work_category', 'evaluation']))
        setSearchParams(`section=work_category`)

    }


    return (
        <div className="service-form-section-div">
            <div className="title-section">
                <h3>Evaluation</h3>
                {/* <p>Enter current condition for purifier</p> */}
            </div>
            <div className="form-div">
                <form action="" onSubmit={handleSectionSubmit}>

                    {/* Input Output */}
                    <div>
                        <div className="sub-title">
                            <h4>TDS</h4>
                            {serviceForm?.evaluation_data?.tds_status &&
                                <span className={`text-badge ${serviceForm?.evaluation_data?.tds_status}-text`}>{serviceForm?.evaluation_data?.tds_status}</span>}
                        </div>
                        <div className="two-input-single-line-div">
                            <NormalInput label='Input TDS' name='input_tds' value={serviceForm?.evaluation_data?.input_tds || null} type={'number'} min={"0"} step={"0.1"}
                                onChangeFun={handleNormalChange} />
                            <NormalInput label='Output TDS' name='output_tds' value={serviceForm?.evaluation_data?.output_tds || null} type={'number'} min={"0"} step={"0.1"}
                                onChangeFun={handleNormalChange} />
                        </div>
                    </div>

                    {/* Filtered water flow */}
                    <div>
                        <div className="sub-title">
                            <h4>Filtered water flow</h4>
                            {serviceForm?.evaluation_data?.filtered_water_flow_status &&
                                <span className={`text-badge ${serviceForm?.evaluation_data?.filtered_water_flow_status}-text`}>
                                    {serviceForm?.evaluation_data?.filtered_water_flow_status}</span>}
                        </div>
                        <div className="two-input-single-line-div">
                            <NormalInput label='Current flow' name='filtered_water_flow_ltrs'
                                value={serviceForm?.evaluation_data?.filtered_water_flow_ltrs || null} type={'number'} min={"0"} step={"0.1"}
                                onChangeFun={handleNormalChange} />
                            {showInput?.pump && <SelectInput label='Pump' name='pump' values={pumpList} firstOption={{ option: 'Choose...', value: '' }}
                                onChangeFun={handleNormalChange} />}
                        </div>
                    </div>

                    {/*Reject water flow */}
                    {showInput?.rejectWater && <div>
                        <div className="sub-title">
                            <h4>Reject water flow</h4>
                            {serviceForm?.evaluation_data?.reject_water_flow_status &&
                                <span className={`text-badge ${serviceForm?.evaluation_data?.reject_water_flow_status}-text`}>
                                    {serviceForm?.evaluation_data?.reject_water_flow_status}</span>}
                        </div>
                        <div className="two-input-single-line-div">
                            <NormalInput label='Current flow' name='reject_water_flow_ltrs'
                                value={serviceForm?.evaluation_data?.reject_water_flow_ltrs || null} type={'number'} min={"0"} step={"0.1"}
                                onChangeFun={handleNormalChange} />
                            <SelectInput label='FR' name='fr' values={frList} firstOption={{ option: 'Choose...', value: '' }}
                                onChangeFun={handleNormalChange} />
                        </div>
                    </div>}

                    {/* PH */}
                    <div>
                        {showInput.ph && <div className="sub-title">
                            <h4>Potential of Hydrogen (PH)</h4>
                            {serviceForm?.evaluation_data?.ph_status &&
                                <span className={`text-badge ${serviceForm?.evaluation_data?.ph_status}-text`}>
                                    {serviceForm?.evaluation_data?.ph_status}</span>}
                        </div>}
                        <div className="two-input-single-line-div">
                            {showInput.ph &&
                                <NormalInput label='' name='ph'
                                    value={serviceForm?.evaluation_data?.ph || null} type={'number'} min={"0"} max={14} step={"0.1"}
                                    onChangeFun={handleNormalChange} />}
                        </div>
                    </div>

                    {/* Water Quality */}
                    <div className="radio-input-border-div">
                        <div className="sub-title"><h4>Water quality</h4></div>
                        <div className="radio-inputs">
                            <RadioInput label={"Good"} name={'water_quality'} value={'good'} onChangeFun={handleNormalChange}
                                checked={serviceForm?.evaluation_data?.water_quality === 'good'} isRequired={false} />
                            <RadioInput label={"Poor"} name={'water_quality'} value={'poor'} onChangeFun={handleNormalChange}
                                checked={serviceForm?.evaluation_data?.water_quality === 'poor'} isRequired={false} />
                        </div>
                        {serviceForm?.evaluation_data?.water_quality === 'poor' && <div className="two-input-single-line-div">
                            <NormalInput label='Reason of poor' name='poor_reason'
                                value={serviceForm?.evaluation_data?.poor_reason || null} type={'text'} onChangeFun={handleNormalChange} />
                        </div>}

                    </div>

                    {/* Next Buttons */}
                    <div className="handle-buttons-div">
                        <button type='submit' className='next'>Next</button>
                    </div>

                </form>
            </div >
        </div >
    )
})

export default SectionFive