import React, { useState } from 'react'
import './service-details.scss'
import { TbCircleDotFilled } from 'react-icons/tb'
import { YYYYMMDDFormat } from '../../../assets/javascript/formate-functions'
import CustomerProfileCard1 from '../customer-profile-card-1/CustomerProfileCard1'
import InfoBox from '../../../components/common/info-box/InfoBox'
import { LuCopy } from "react-icons/lu";
import { IoCall } from "react-icons/io5";
import toast from 'react-hot-toast'

const ServiceDetails = ({ customer, purifier, regDetails, type, blacked, before_status }) => {
    const [copied, setCopied] = useState(0)

    const handleCopy = (copyText, index) => {
        if (copyText) {
            navigator.clipboard.writeText(copyText)
                .then(() => {
                    setCopied(index)
                    setTimeout(() => setCopied(0), 3000);
                })
                .catch(() => toast.error('Try now !'));
        }
    }


    return (
        <div className='service-details-div'>
            <div className="border-div">
                <CustomerProfileCard1 imgObj={customer?.profile_image} fullName={customer?.full_name} customerStatus={customer?.purifier_customer_status}
                    starRate={customer?.star_rate} cid={customer?.cid} blacked={blacked} before_status={before_status} />

                {/* Customer Details */}
                <div className="section-div">
                    <div className="section-tit">
                        <TbCircleDotFilled />
                        <h4>Customer details</h4>
                    </div>
                    {/* Address */}
                    <div className="list-div">
                        <div className="label-div">
                            <p>Address</p>
                        </div>
                        <div className="input-div">
                            <p>: {customer?.address?.address}, {customer?.address?.place}, {customer?.address?.post} (P.O), {customer?.address?.district} - {customer?.address?.land_mark} (land mark)</p>
                        </div>
                    </div>
                    {/* Contact  */}
                    {customer?.contact1 ? <div className="list-div">
                        <div className="label-div">
                            <p>Contact (Main)</p>
                        </div>
                        <div className="input-div">
                            <p>: {customer.contact1}</p>
                            <a className='call-icon' href={`tel:${customer?.contact1}`}><IoCall /></a>
                            <span className='copy-icon' onClick={() => handleCopy(customer?.contact1, 1)}><LuCopy /></span>
                            <small>{copied === 1 && 'Copied!'}</small>
                        </div>
                    </div> : ''}
                    {customer?.contact2 ? <div className="list-div">
                        <div className="label-div">
                            <p>Contact (Sec)</p>
                        </div>
                        <div className="input-div">
                            <p>: {customer.contact2}</p>
                            <a className='call-icon' href={`tel:${customer?.contact2}`}><IoCall /></a>
                            <span className='copy-icon' onClick={() => handleCopy(customer?.contact2, 2)}><LuCopy /></span>
                            <small>{copied === 2 && 'Copied!'}</small>
                        </div>
                    </div> : ''}
                    {regDetails?.additional_contact ? <div className="list-div">
                        <div className="label-div">
                            <p>Contact (Additional)</p>
                        </div>
                        <div className="input-div">
                            <p>: {regDetails?.additional_contact}</p>
                            <a className='call-icon' href={`tel:${customer?.additional_contact}`}><IoCall /></a>
                            <span className='copy-icon' onClick={() => handleCopy(regDetails?.additional_contact, 3)}><LuCopy /></span>
                            <small>{copied === 3 && 'Copied!'}</small>
                        </div>
                    </div> : ''}

                </div>

                {/* Service Details */}
                <div className="section-div">
                    <div className="section-tit">
                        <TbCircleDotFilled />
                        <h4>Service details</h4>
                    </div>
                    {regDetails?.reg_no ? <div className="list-div">
                        <div className="label-div">
                            <p>Reg No</p>
                        </div>
                        <div className="input-div">
                            <p>: {regDetails?.reg_no}</p>
                        </div>
                    </div> : ''}
                    <div className="list-div">
                        <div className="label-div">
                            <p>{regDetails?.registered_date_time ? 'Reg date & time' : 'Next service date'}</p>
                        </div>
                        <div className="input-div">
                            <p>: {regDetails?.registered_date_time
                                ? `${new Date(regDetails?.registered_date_time).toDateString()}, ${new Date(regDetails?.registered_date_time).toLocaleTimeString()}`
                                : purifier?.next_periodical_service_date}</p>
                        </div>
                    </div>
                    {regDetails?.what_is_complaint ? <div className="list-div">
                        <div className="label-div">
                            <p>What is complaint </p>
                        </div>
                        <div className="input-div">
                            <p>: {regDetails?.what_is_complaint}</p>
                        </div>
                    </div> : ''}
                    {regDetails?.description ? <div className="list-div">
                        <div className="label-div">
                            <p>Description</p>
                        </div>
                        <div className="input-div">
                            <p>: {regDetails?.description}</p>
                        </div>
                    </div> : ''}
                    <div className="list-div">
                        <div className="label-div">
                            <p>Work type </p>
                        </div>
                        <div className="input-div">
                            <p>: {type}</p>
                        </div>
                    </div>
                    {regDetails?.priority ? <div className="list-div">
                        <div className="label-div">
                            <p>Priority</p>
                        </div>
                        <div className="input-div">
                            <p>: {regDetails?.priority}</p>
                        </div>
                    </div> : ''}

                    {type === 'complaint'
                        ? <div className="list-div">
                            <div className="label-div">
                                <p>Complaint index</p>
                            </div>
                            <div className="input-div">
                                <p>: {(purifier.complaint_count?.length || 0) + 1}</p>
                            </div>
                        </div>
                        : <div className="list-div">
                            <div className="label-div">
                                <p>Service index</p>
                            </div>
                            <div className="input-div">
                                <p>: {(purifier.service_count?.length || 0) + 1}</p>
                            </div>
                        </div>
                    }

                    {customer?.purifier_customer_status === 'SSP'
                        && <div className="list-div">
                            <div className="label-div">
                                <p>Token index</p>
                            </div>
                            <div className="input-div">
                                <p>: {(purifier.complaint_count?.length || 0) + (purifier.service_count?.filter((obj) => obj.status)?.length || 0) + 1}</p>
                            </div>
                        </div>
                    }

                    {type === 'complaint' && <div className="list-div">
                        <div className="label-div">
                            <p>Next service date</p>
                        </div>
                        <div className="input-div">
                            <p>: {purifier?.next_periodical_service_date}</p>
                        </div>
                    </div>}
                    <div className="list-div">
                        <div className="label-div">
                            <p>Package expiry date</p>
                        </div>
                        <div className="input-div">
                            <p>: {purifier?.package_expiry_date || 'No Package'}</p>
                        </div>
                    </div>
                    <div className="list-div">
                        <div className="label-div">
                            <p>Carbon expiry date</p>
                        </div>
                        <div className="input-div">
                            <p>: {purifier?.carbon_filter_expiry_date}</p>
                        </div>
                    </div>
                </div>


                {/* Description Data */}
                {customer?.purifier_work_description?.[0] && <div className="section-div">
                    <div className="section-tit">
                        <TbCircleDotFilled />
                        <h4>Descriptions</h4>
                    </div>
                    {customer?.purifier_work_description
                        ?.sort((a, b) => new Date(b?.date) - new Date(a?.date))
                        .map((description) => <div className="list-div">
                            <div className="label-div">
                                <p>{YYYYMMDDFormat(new Date(description.date))}</p>
                            </div>
                            <div className="input-div">
                                <p>: {description.description} ({description.by})</p>
                            </div>
                        </div>)}
                </div>}

                {((purifier?.pending_ssp_token || 0) < 1 && customer?.purifier_customer_status === 'SSP') &&
                    <InfoBox type='warning' description={'This customer ssp token is completed. You can not schedule this service.'} />}

                {blacked &&
                    <InfoBox type='info' title={'How to work with black list ?'}
                        description={`This is the blacklisted customer and you can now schedule the O/W service using schedule button. ${before_status} service can be scheduled after contacting admin section.`} />}


            </div>
        </div >
    )
}

export default ServiceDetails