import React, { useEffect, useState } from 'react';
import '../service-form-comp/style.scss';
import './spare-list.scss';
import { LuPlus, LuMinus } from "react-icons/lu";
import { useDispatch, useSelector } from 'react-redux';
import { IoTrashOutline } from "react-icons/io5";
import { RxComponent2 } from "react-icons/rx";
import { YYYYMMDDFormat } from '../../../assets/javascript/formate-functions';
import { getDaysFromISODate } from '../../../assets/javascript/find-functions';
import { changeFormValue } from '../../../redux/features/user/serviceSlice'
import toast from 'react-hot-toast';
import { FaBullseye } from 'react-icons/fa';
import { calculateTotalAmount, sspItemsPriceRounding } from '../../../assets/javascript/calc-functions';

const SpareList = ({ customerSpare, allSpare, packageSpare, spareType, aboutWork, setSpareType, product }) => {

    const { serviceForm } = useSelector((state) => state.serviceData)
    const [customerSpareList, setCustomerSpareList] = useState([])
    const [moreSpareList, setMoreSpareList] = useState([])
    const [more, setMore] = useState(false)
    const [flag, setFlag] = useState('')
    const [loading, setLoading] = useState(true)
    const dispatch = useDispatch()

    const chooseSpare = (spare, count, type) => {

        const rateType = spare?.warranty_expire_date >= YYYYMMDDFormat(new Date()) ? null : aboutWork?.price?.[spareType.toLowerCase()]
        const tallyRateType = spare?.warranty_expire_date >= YYYYMMDDFormat(new Date())
            ? 'purchase_rate'
            : aboutWork?.price?.['tally_' + spareType.toLowerCase()]

        const setFnSpareList = type === 'more' ? setMoreSpareList : setCustomerSpareList

        const updateSpareListState = (spare_id, count) => {
            setFnSpareList((state) => {
                return state?.map((elem) => {
                    if (elem.spare_id === spare_id) {
                        return { ...elem, quantity: (elem?.quantity || 0) + count }
                    }
                    return elem;
                })
            })
        }

        // Check Count 1 or -1
        if (count === 1) {
            // Check quantity is 0 or above 1
            if (!spare?.quantity) {
                let obj = {
                    spare_id: spare?.spare_id,
                    spare_name: spare?.spare_name,
                    spare_category: spare?.spare_category,
                    type: spare?.spare_types,
                    mrp: spare?.normal_rate,
                    tally_rate: spare?.[tallyRateType],
                    rate: spare?.[rateType] ? spare?.[rateType] : 0,
                    quantity: 1,
                    discount_percentage: aboutWork?.price?.[spareType.toLowerCase()] === 'ssp_rate' ? spare?.ssp_disc_pct : undefined,
                    warranty: spare?.warranty_expire_date >= YYYYMMDDFormat(new Date()) ? true : undefined,
                    new_warranty_date: spare?.warranty_for?.includes(serviceForm?.purifier_customer_status) &&
                        aboutWork?.warranty_for?.includes(spareType)
                        ? getDaysFromISODate(new Date(), Number(spare?.warranty_period_days))
                        : undefined,
                    new_one: type === 'more'
                }

                // Add to State
                updateSpareListState(spare?.spare_id, 1)

                dispatch(changeFormValue({
                    ...serviceForm || {},
                    [`${spareType.toLowerCase()}_spares`]: [
                        ...(serviceForm?.[`${spareType.toLowerCase()}_spares`] || []),
                        obj
                    ],
                    bill_data: {
                        ...serviceForm?.bill_data || {},
                        service_charge_est: ((spare.spare_category === 'membrane' && !serviceForm?.service_data?.carbon_filter_changed) || spare.spare_category === 'pump')
                            ? aboutWork?.service_charge_est?.[1] : serviceForm?.bill_data?.service_charge_est,
                        service_charge_tally: ((spare.spare_category === 'membrane' && !serviceForm?.service_data?.carbon_filter_changed) || spare.spare_category === 'pump')
                            ? aboutWork?.service_charge_receivable?.[1] : serviceForm?.bill_data?.service_charge_tally,
                        service_charge_receivable: ((spare.spare_category === 'membrane' && !serviceForm?.service_data?.carbon_filter_changed) || spare.spare_category === 'pump')
                            ? aboutWork?.service_charge_receivable?.[1] : serviceForm?.bill_data?.service_charge_receivable
                    },
                    call_details: {
                        primary_secondary: ((spare.spare_category === 'membrane' && !serviceForm?.service_data?.carbon_filter_changed) || spare.spare_category === 'pump')
                            ? aboutWork?.call?.[1] : serviceForm?.call_details.primary_secondary
                    }
                }))

            } else {

                updateSpareListState(spare?.spare_id, 1)

                dispatch(changeFormValue({
                    ...serviceForm || {},
                    [`${spareType.toLowerCase()}_spares`]: [
                        ...(serviceForm?.[`${spareType.toLowerCase()}_spares`]?.map((elem) => {
                            if (elem.spare_id === spare.spare_id) {
                                return { ...elem, quantity: elem.quantity + 1 }
                            }
                            return elem;
                        }) || [])
                    ]
                }))
            }
        } else if (count === -1) {
            // Check quantity is 1 , less than 1 or above 1
            if (spare?.quantity === 1) {

                updateSpareListState(spare?.spare_id, 1)

                dispatch(changeFormValue({
                    ...serviceForm || {},
                    [`${spareType.toLowerCase()}_spares`]: [
                        ...(serviceForm?.[`${spareType.toLowerCase()}_spares`]?.filter((elem) => elem.spare_id !== spare.spare_id) || []),
                    ],
                    bill_data: {
                        ...serviceForm?.bill_data || {},
                        service_charge_est: ((spare.spare_category === 'membrane' && !serviceForm?.service_data?.carbon_filter_changed) || spare.spare_category === 'pump')
                            ? aboutWork?.service_charge_est?.[0] : serviceForm?.bill_data?.service_charge_est,
                        service_charge_tally: ((spare.spare_category === 'membrane' && !serviceForm?.service_data?.carbon_filter_changed) || spare.spare_category === 'pump')
                            ? aboutWork?.service_charge_receivable?.[0] : serviceForm?.bill_data?.service_charge_tally,
                        service_charge_receivable: ((spare.spare_category === 'membrane' && !serviceForm?.service_data?.carbon_filter_changed) || spare.spare_category === 'pump')
                            ? aboutWork?.service_charge_receivable?.[0] : serviceForm?.bill_data?.service_charge_receivable
                    },
                    call_details: {
                        primary_secondary: ((spare.spare_category === 'membrane' && !serviceForm?.service_data?.carbon_filter_changed) || spare.spare_category === 'pump')
                            ? aboutWork?.call?.[0] : serviceForm?.call_details.primary_secondary
                    }
                }))

            } else if (spare?.quantity < 1) {
                // Chance of any mistakes 
                toast.error('Refresh now !')
            } else {

                updateSpareListState(spare?.spare_id, 1)

                dispatch(changeFormValue({
                    ...serviceForm || {},
                    [`${spareType.toLowerCase()}_spares`]: [
                        ...(serviceForm?.[`${spareType.toLowerCase()}_spares`]?.map((elem) => {
                            if (elem.spare_id === spare.spare_id) {
                                return { ...elem, quantity: elem.quantity - 1 }
                            }
                            return elem;
                        }) || [])
                    ]
                }))
            }
        }

        if (spareType === 'Primary' && serviceForm?.work_category_selected === 'ssp') {
            setFlag(spare?.spare_id + spare?.quantity)
        }

    }
    // spare_category    "membrane"    "pump"
    const handleDoWarranty = (e, spare) => {
        if (e.target.checked) {
            dispatch(changeFormValue({
                ...serviceForm || {},
                [`${spareType.toLowerCase()}_spares`]: [
                    ...(serviceForm?.[`${spareType.toLowerCase()}_spares`]?.map((elem) => {
                        if (elem.spare_id === spare.spare_id) {
                            return {
                                ...elem,
                                warranty: true,
                                rate: 0,
                                discount_percentage: undefined,
                                tally_rate: spare?.purchase_rate,
                                new_warranty_date: undefined
                            }
                        }
                        return elem;
                    }) || [])
                ]
            }))
        } else {
            const rateType = aboutWork?.price?.[spareType.toLowerCase()]
            const tallyRateType = aboutWork?.price?.['tally_' + spareType.toLowerCase()]

            dispatch(changeFormValue({
                ...serviceForm || {},
                [`${spareType.toLowerCase()}_spares`]: [
                    ...(serviceForm?.[`${spareType.toLowerCase()}_spares`]?.map((elem) => {
                        if (elem.spare_id === spare.spare_id) {
                            return {
                                ...elem, warranty: undefined, tally_rate: spare?.[tallyRateType],
                                rate: spare?.[rateType] ? spare?.[rateType] : 0,
                                new_warranty_date: getDaysFromISODate(new Date(), Number(spare?.warranty_period_days))

                            }
                        }
                        return elem;
                    }) || [])
                ]
            }))
        }

        if (spareType === 'Primary' && serviceForm?.work_category_selected === 'ssp') {
            setFlag(spare?.spare_id + spare?.quantity)
        }
    }

    const removeSpare = (spareId) => {
        const ask = window.confirm('Are you delete this spare ?')
        if (ask) {
            setCustomerSpareList((state) => {
                return state.map((spare) => {
                    if (spare?.spare_id === spareId) {
                        return {
                            ...spare,
                            delete: true
                        }
                    }
                    return spare;
                })
            })

            dispatch(changeFormValue({
                ...serviceForm || {},
                removed_spare_ids: [...(serviceForm.removed_spare_ids || []), spareId]
            }))
        }
    }

    const undoDelete = (spareId) => {

        setCustomerSpareList((state) => {
            return state.map((spare) => {
                if (spare?.spare_id === spareId) {
                    return {
                        ...spare,
                        delete: FaBullseye
                    }
                }
                return spare;
            })
        })

        dispatch(changeFormValue({
            ...serviceForm || {},
            removed_spare_ids: serviceForm.removed_spare_ids?.filter((id) => id !== spareId)
        }))

    }

    useEffect(() => {

        // Customer spare listing
        const customerArray = customerSpare.map((obj) => {
            const matchingArray = serviceForm?.[`${spareType.toLowerCase()}_spares`]?.find((obj2) => obj.spare_id === obj2.spare_id)
            const ifDeleted = serviceForm?.removed_spare_ids?.includes(obj?.spare_id)
            if (ifDeleted) {
                return { ...obj, delete: true }
            }
            return { ...obj, quantity: matchingArray?.quantity || 0 }
        })
        setCustomerSpareList(customerArray)

        // More Spare Listing
        const filterMore = allSpare?.filter((obj1) => !customerSpare.some((obj2) => obj1.spare_id === obj2.spare_id))
        const moreArray = filterMore.map((obj) => {
            const matchingArray2 = serviceForm?.[`${spareType.toLowerCase()}_spares`]?.find((obj2) => obj.spare_id === obj2.spare_id)
            if (matchingArray2) {
                setMore(true)
            }
            return { ...obj, quantity: matchingArray2?.quantity || 0, warranty: matchingArray2?.warranty }
        })
        setMoreSpareList(moreArray)

        setLoading(false)
        // eslint-disable-next-line
    }, [customerSpare, allSpare])

    useEffect(() => {
        if (spareType === 'Primary' && serviceForm?.work_category_selected === 'ssp' && serviceForm?.primary_spares?.[0]) {
            const totalAmount = calculateTotalAmount(serviceForm?.primary_spares || [], 'mrp')
            const newRateArray = sspItemsPriceRounding(JSON.parse(JSON.stringify(serviceForm?.primary_spares || [])), product?.ssp_spare_amt || 0, totalAmount)

            dispatch(changeFormValue({
                ...serviceForm || {},
                [`${spareType.toLowerCase()}_spares`]: newRateArray
            }))
        }
        // eslint-disable-next-line
    }, [flag])

    return (
        <div className="service-form-section-div spare-listing-div">
            <div className="form-div">
                <div className="section-one-div">
                    {customerSpareList?.[0] && customerSpareList?.map((spare) => <div className="spare-box-div customer-spare">
                        <p className="spare-id">{spare?.spare_id}</p>
                        <h4 className="spare_name">{spare?.spare_name}</h4>
                        <div className={aboutWork?.price?.[spareType.toLowerCase()] === 'ssp_rate' ? "price-div ssp-div" : "price-div"}>
                            <p>₹{spare?.normal_rate}</p>
                            {aboutWork?.price?.[spareType.toLowerCase()] === 'ssp_rate' && <p>₹{spare?.ssp_rate}</p>}
                            {aboutWork?.price?.[spareType.toLowerCase()] === 'ssp_rate' && <span>({spare?.ssp_disc_pct}%)</span>}
                        </div>
                        <div className="button-div">
                            {spare?.quantity ? <div className="count-div">
                                <button onClick={() => chooseSpare(spare, -1, 'customer')}><LuMinus /></button>
                                <span>{spare?.quantity || '00'}</span>
                                <button onClick={() => chooseSpare(spare, 1, 'customer')}><LuPlus /></button>
                            </div>
                                : spare?.delete
                                    ? <div className="delete-div">
                                        <button onClick={() => undoDelete(spare?.spare_id)}>Undo</button>
                                    </div>
                                    : <div className="add-div">
                                        <button onClick={() => chooseSpare(spare, 1, 'customer')}>Select</button>
                                        <button onClick={() => removeSpare(spare?.spare_id)}><IoTrashOutline /></button>
                                    </div>}
                        </div>
                        {spare?.warranty_expire_date >= YYYYMMDDFormat(new Date()) && <div className="warranty-div">
                            <p>W</p>
                        </div>}
                    </div>)}
                    {!more && <div className="spare-box-div more-div" onClick={() => setMore(true)}>
                        <div className="icon-div">
                            <RxComponent2 />
                        </div>
                        <p>More spare</p>
                    </div>}
                    {more && moreSpareList?.[0] && moreSpareList?.map((spare) => <div className="spare-box-div more-spare">
                        <p className="spare-id">{spare?.spare_id}</p>
                        <h4 className="spare_name">{spare?.spare_name}</h4>
                        <div className={aboutWork?.price?.[spareType.toLowerCase()] === 'ssp_rate' ? "price-div ssp-div" : "price-div"}>
                            <p>₹{spare?.normal_rate}</p>
                            {aboutWork?.price?.[spareType.toLowerCase()] === 'ssp_rate' && <p>₹{spare?.ssp_rate}</p>}
                            {aboutWork?.price?.[spareType.toLowerCase()] === 'ssp_rate' && <span>({spare?.ssp_disc_pct}%)</span>}
                        </div>
                        <div className="button-div">
                            {spare?.quantity ? <div className="count-div">
                                <button onClick={() => chooseSpare(spare, -1, 'more')}><LuMinus /></button>
                                <span>{spare?.quantity || '00'}</span>
                                <button onClick={() => chooseSpare(spare, 1, 'more')}><LuPlus /></button>
                            </div>
                                : <div className="add-div">
                                    <button onClick={() => chooseSpare(spare, 1, 'more')}>Select</button>
                                </div>}
                        </div>
                        {spare?.warranty_for?.includes(serviceForm?.purifier_customer_status) &&
                            aboutWork?.warranty_for?.includes(spareType) && <div className="warranty-div">
                                <p>W {spare?.warranty_period_days}d</p>
                            </div>}
                        {spare?.warranty_for?.includes(serviceForm?.purifier_customer_status) &&
                            aboutWork?.warranty_for?.includes(spareType) && !(!spare?.quantity) &&
                            <div className="warranty-radio">
                                <input type="checkbox" name='is_warranty' checked={spare?.warranty} onChange={(e) => handleDoWarranty(e, spare)} />
                            </div>}
                    </div>)}
                </div>

                {/* Done Buttons */}
                <div className="handle-buttons-div">
                    <button type='submit' className='next' onClick={() => setSpareType('')}>
                        {loading ? 'Loading...' : 'Done'}
                    </button>
                </div>
            </div>
        </div >
    )
}

export default SpareList