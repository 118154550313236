import React, { useEffect, useState } from 'react';
import './dar-list.scss';
import AdminPage from '../../../components/common/page/AdminPage';
import TableFilter from '../../../components/common/table-filter/TableFilter';
import { servicedAxios } from '../../../config/axios';
import Spinner from '../../../components/common/spinners/SpinWithMessage'
import { MdOutlineMiscellaneousServices } from "react-icons/md";
import { FaEye, FaCheckCircle } from 'react-icons/fa'
import { useLocation, useNavigate } from 'react-router-dom';

const ServicedList = () => {

    const location = useLocation()
    const [darList, setDarList] = useState([])
    const [loading, setLoading] = useState(true)
    const navigate = useNavigate()


    useEffect(() => {
        if (!location?.state) {
            navigate('/admin')
        }

        setLoading(true)
        servicedAxios.get(`/dar/zone-basie?zone_id=${location?.state?.zone_id}&from_date=${location?.state?.from_date}&to_date=${location?.state?.end_date}`)
            .then((response) => {
                setDarList(response.data)
                setLoading(false)
            })
        // eslint-disable-next-line
    }, [])


    return (
        <div className="dar-list-div">
            <AdminPage titleArray={['DAR List']}>
                <div className="info-table">
                    <p>{location?.state?.from_date} - {location?.state?.end_date}</p>
                    <p>{location?.state?.zone}</p>
                </div>
                {darList?.[0] ?
                    <TableFilter>
                        <table>
                            <thead>
                                <tr>
                                    <th>Date</th>
                                    <th>CID / <br></br>Customer Name</th>
                                    <th>Technician</th>
                                    <th>IN / OUT</th>
                                    <th>Work</th>
                                    <th>Srl Number</th>
                                    <th>Call</th>
                                    <th>Amount</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {darList.map((service, index) => <tr key={index}>
                                    <td style={{ textAlign: "center" }}>{service?.date}</td>
                                    <td style={{ textAlign: "center" }}>CID : {service?.cid}<br></br>{service?.name_of_customer}</td>
                                    <td style={{ textAlign: "center" }}>{service?.technician} <br></br>{service?.zone}</td>
                                    <td style={{ textAlign: "center" }}>
                                        IN - {new Date(service?.in_time).toLocaleTimeString()}
                                        <br></br>
                                        OUT - {new Date(service?.out_time).toLocaleTimeString()}
                                    </td>
                                    <td style={{ textAlign: "center" }}>
                                        <span className={`text-badge ${service?.purifier_customer_status}-text`}>{service?.purifier_customer_status}</span>
                                        <br></br>
                                        {service?.work_method}
                                    </td>
                                    <td style={{ textAlign: "center" }}>
                                        {service?.service_srl_number}
                                        <br></br>
                                        {service?.repeat && <span className={`text-badge red-text`}>Repeat</span>}
                                    </td>
                                    <td style={{ textAlign: "center" }}>
                                        {service?.call_details?.primary_secondary ? <>Normal : {service?.call_details?.primary_secondary}<br /></> : null}
                                        {service?.call_details?.extra_work ? <>Extra : {service?.call_details?.extra_work}<br /></> : null}
                                        {service?.call_details?.above_24_kms ? <>Above 24 : {service?.call_details?.above_24_kms}</> : null}
                                    </td>
                                    <td style={{ textAlign: "center" }}>₹{service?.total_amount} {!service?.balance_amount && <span style={{ color: 'green' }}><FaCheckCircle /></span>}</td>
                                    <td style={{ textAlign: "center" }}>
                                        <div className='buttons' >
                                            <button title='View service' className='button-small-icon edit'
                                                onClick={() => navigate(`/admin/serviced-list/${service?.service_srl_number}/view`)}><FaEye /></button>
                                        </div>
                                    </td>
                                </tr>)}

                            </tbody>
                        </table>
                    </TableFilter>
                    : <Spinner message={loading ? 'Fetch data..' : 'No services'} spin={loading} icon={!loading && <MdOutlineMiscellaneousServices />} />}
            </AdminPage>
        </div>
    )
}

export default ServicedList