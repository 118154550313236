import React, { useEffect, useState } from 'react'
import './style.scss'
import { useDispatch, useSelector } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import NormalInput from '../../common/inputs/NormalInput'
import { findServiceFormTotal } from '../../../assets/javascript/calc-functions'
import SelectInput from '../../common/inputs/SelectInput'
import InfoBox from '../../common/info-box/InfoBox'
import { changeFormValue, nextAction } from '../../../redux/features/user/serviceSlice'
import { getDaysFromISODate } from '../../../assets/javascript/find-functions'
import toast from 'react-hot-toast'

const SectionEight = React.memo(({ customer, eligibility }) => {
    const { serviceForm } = useSelector((state) => state.serviceData)
    // eslint-disable-next-line
    const [searchParams, setSearchParams] = useSearchParams();
    const [bill, setBill] = useState({})
    const tempPaymentData = serviceForm?.payment_history?.[0] || {}
    const dispatch = useDispatch()

    const paymentMethods = [
        { option: 'CASH', value: 'CASH', selected: tempPaymentData?.received_method === 'CASH' },
        { option: 'BANK', value: 'BANK', selected: tempPaymentData?.received_method === 'BANK' },
        { option: 'CASH-AND-BANK', value: 'CASH-AND-BANK', selected: tempPaymentData?.received_method === 'CASH-AND-BANK' },
        { option: 'CHEQUE', value: 'CHEQUE', selected: tempPaymentData?.received_method === 'CHEQUE' }
    ]

    const handleChange = (e) => {
        let temp = {}

        if (e.target.name === 'received_method') {
            temp = {
                ...tempPaymentData,
                cash: undefined,
                bank: undefined,
                cheque: undefined,
                balance_due_date: undefined,
                reason: undefined,
                tran_track_no: undefined,
                [e.target.name]: e.target.value,
            }
        } else if (e.target.name === 'cash' || e.target.name === 'bank' || e.target.name === 'cheque') {
            temp = {
                ...tempPaymentData,
                balance_due_date: undefined,
                reason: undefined,
                [e.target.name]: e.target.value,
            }
        } else if (e.target.name === 'reason') {
            temp = {
                ...tempPaymentData,
                [e.target.name]: e.target.value,
                balance_due_date: getDaysFromISODate(new Date(), 15)
            }
        } else {
            temp = {
                ...tempPaymentData,
                [e.target.name]: e.target.value,
            }
        }

        dispatch(changeFormValue({
            ...serviceForm,
            payment_history: [temp]
        }))
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        const totalReceivedAmt = (Number(serviceForm?.payment_history?.[0]?.cash || 0) + Number(serviceForm?.payment_history?.[0]?.bank || 0) + Number(serviceForm?.payment_history?.[0]?.cheque || 0))
        if (!eligibility?.credit && bill?.grand_total_receivable > totalReceivedAmt) {
            toast.error('Credit not allowed for this customer')
            return;
        }

        if (serviceForm?.service_data?.package_renewal && bill?.grand_total_receivable > totalReceivedAmt) {
            toast.error('Credit not allowed for this customer')
            return;
        }

        if ((bill?.grand_total_receivable / 2) > totalReceivedAmt) {
            toast.error(`Credit is allowed only for 50% of the total amount`)
            return;
        }

        if (bill?.grand_total_receivable < totalReceivedAmt) {
            toast.error(`Received amount not equal to total amount`)
            return;
        }

        // to section ten
        dispatch(nextAction(['feedback', 'payment']))
        setSearchParams(`section=feedback`)
    }

    useEffect(() => {
        // Total calculation algorithms
        const totalObj = findServiceFormTotal(serviceForm?.bill_data || {}, serviceForm?.primary_spares || [],
            serviceForm?.secondary_spares || [], serviceForm?.special_spares || [], customer?.debit_amount || 0,
            serviceForm?.service_data || {})

        setBill(totalObj.bill)
        // eslint-disable-next-line 
    }, [customer])


    return (
        <div className="service-form-section-div">
            <div className="title-section">
                <h3>Payment</h3>
                {/* <p>Description</p> */}
            </div>
            {!eligibility?.credit && <InfoBox type={'warning'} description={`Credit not allowed for this customer.
             Reason : ${eligibility?.why_not_credit}`} />}
            {eligibility?.credit && serviceForm?.service_data?.package_renewal &&
                <InfoBox type={'warning'} description={`Credit not allowed for this customer.
             Reason : This is a package renewal service.`} />}
            <div className="form-div">
                <form action="" onSubmit={handleSubmit}>
                    <div className="total-amount-div">
                        <p>Total amount receivable</p>
                        <h3>₹{bill?.grand_total_receivable || 0}</h3>
                    </div>
                    <SelectInput label='Payment method' name='received_method' values={paymentMethods}
                        firstOption={{ option: 'Choose...', value: '' }} onChangeFun={handleChange} />

                    <div className="two-input-single-line-div">
                        {(tempPaymentData?.received_method === 'CASH-AND-BANK' || tempPaymentData?.received_method === 'CASH') &&
                            <NormalInput label='As cash (₹)' type={'number'} name='cash' value={tempPaymentData?.cash} onChangeFun={handleChange} />}
                        {(tempPaymentData?.received_method === 'BANK' || tempPaymentData?.received_method === 'CASH-AND-BANK') &&
                            <NormalInput label='To bank (₹)' type={'number'} name='bank' value={tempPaymentData?.bank} onChangeFun={handleChange} />}
                        {(tempPaymentData?.received_method === 'CHEQUE') &&
                            <NormalInput label='As Cheque (₹)' type={'number'} name='cheque' value={tempPaymentData?.cheque} onChangeFun={handleChange} />}
                    </div>

                    {(tempPaymentData?.received_method === 'BANK' || tempPaymentData?.received_method === 'CASH-AND-BANK' || tempPaymentData?.received_method === 'CHEQUE') &&
                        <NormalInput label='Transaction ID / Cheque no' type={'text'} name='tran_track_no' value={tempPaymentData?.tran_track_no} onChangeFun={handleChange} />}

                    {bill?.grand_total_receivable > (Number(tempPaymentData?.cash || 0) + Number(tempPaymentData?.bank || 0) + Number(tempPaymentData?.cheque || 0)) && <>
                        <NormalInput label='Balance will receive on / Before' name='balance_due_date' value={getDaysFromISODate(new Date(), 15)} type={'text'} />

                        <NormalInput label='Reason for credit' name='reason' value={tempPaymentData.reason} onChangeFun={handleChange} />
                    </>}


                    {/* Next Buttons */}
                    <div className="handle-buttons-div">
                        <button type='submit' className='next'>Next</button>
                    </div>
                </form>
            </div>
        </div>
    )
})

export default SectionEight