import React, { useEffect, useState } from 'react';
import './style.scss';
import { userAxios } from '../../../config/axios';
import { FiEdit2 } from 'react-icons/fi'
import { AiOutlinePlus } from 'react-icons/ai'
import AdminPage from '../../../components/common/page/AdminPage';
import TableFilter from '../../../components/common/table-filter/TableFilter'
import AddEditZone from '../../../components/admin/add-edit-zone/AddEditZone';
import Modal from '../../../components/common/modal/Modal'
import Spinner from '../../../components/common/spinners/SpinWithMessage'
import { RxDashboard } from 'react-icons/rx'
import { YYYYMMDDFormat } from '../../../assets/javascript/formate-functions';

const UpcomingPackages = () => {
    const [data, setData] = useState([])
    const [loading, setLoading] = useState('')

    useEffect(() => {
        setLoading('fetch')
        userAxios.get('/upcoming-packages/list').then((response) => {
            setData(response.data)
            setLoading('')
        })
    }, [])



    return (
        <div className="upcoming-list">

            <AdminPage titleArray={['Upcoming package list']}>
                <div className="table-border-div">
                    {data?.[0] ? <TableFilter srlNo={true} >
                        <table>
                            <thead>
                                <tr>
                                    <th>CID</th>
                                    <th>Package</th>
                                    <th>Do date</th>
                                    <th>Auto renew</th>
                                    <th>Status</th>

                                </tr>
                            </thead>
                            <tbody>
                                {data?.map((value, index) => {
                                    return <tr key={index}>
                                        <td>{value?.cid}</td>
                                        <td>from {value?.from_pkg} to {value.to_pkg}</td>
                                        <td>{YYYYMMDDFormat(value?.do_date)}</td>
                                        <td>{value?.auto_renew_date ? value?.auto_renew_date : 'Nill'}</td>
                                        <td>{value?.activate ? 'Activated' : 'Not activated'}</td>

                                    </tr>
                                })}
                            </tbody>
                        </table>
                    </TableFilter>
                        : <Spinner message={loading ? 'fetch data...' : 'No data'} icon={!loading && <RxDashboard />} spin={loading} />
                    }
                </div>
            </AdminPage>
        </div>
    )
}

export default UpcomingPackages