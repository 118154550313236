import React, { useEffect, useState } from 'react';
import './style.scss'
import { userAxios } from '../../../config/axios';
import { FiEdit2 } from 'react-icons/fi'
import { AiOutlinePlus } from 'react-icons/ai'
import SinglePage from '../../../components/common/page/SinglePage';
import TableFilter from '../../../components/common/table-filter/TableFilter'
import Modal from '../../../components/common/modal/Modal'
import Spinner from '../../../components/common/spinners/SpinWithMessage'
import { RxDashboard } from 'react-icons/rx'
import { getDaysFromISODate } from '../../../assets/javascript/find-functions'
import { useSelector } from 'react-redux';
import { YYYYMMDDFormat } from '../../../assets/javascript/formate-functions'
import AddKms from '../../../components/user/add-kms/AddKms';



const KmsList = () => {
    const [kmsList, setKmsList] = useState([])
    const [modal, setModal] = useState({ content: null, title: null, status: false })
    const [loading, setLoading] = useState('')
    const { user } = useSelector((state) => state.userAuth)

    useEffect(() => {
        setLoading('fetch')
        const today = YYYYMMDDFormat(new Date())
        const before30Days = getDaysFromISODate(new Date(), -35)
        userAxios.get(`/technician/kms?from_date=${before30Days}&to_date=${today}&tech_id=${user?._id}`).then((response) => {
            setKmsList(response.data)
            setLoading('')
        })

        // eslint-disable-next-line
    }, [])

    const openModal = (title, data) => {
        setModal({
            content: <AddKms data={data || ''} setModal={setModal} setKmsList={setKmsList} />,
            title,
            status: true
        })
    }

    return (
        <div className="kms-list">
            <Modal modal={modal} setModal={setModal} />
            <SinglePage titleArray={['Daily kilometers']}>
                <div className="table-border-div">
                    {kmsList?.[0] ? <TableFilter srlNo={true} topRight={<button className='add-button'
                        onClick={() => openModal('ADD KMS')}><AiOutlinePlus /> Add kms</button>}>
                        <table>
                            <thead>
                                <tr>
                                    <th>Date</th>
                                    <th>Kms</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {kmsList?.map((value, index) => {
                                    return <tr key={index}>
                                        <td>{value?.date}</td>
                                        <td>{value?.kms}</td>
                                        <td>
                                            <div className='buttons' >
                                                {(new Date(value?.date) >= new Date(new Date().setDate(new Date().getDate() - 3))) &&
                                                    <button title='Edit' onClick={() => openModal('EDIT KMS    ', value)}
                                                        className={'button-small-icon edit'}> <FiEdit2 /></button>
                                                }
                                            </div>
                                        </td>
                                    </tr>
                                })}
                            </tbody>
                        </table>
                    </TableFilter>
                        : <Spinner message={loading ? 'fetch data...' : 'No data'} icon={!loading && <RxDashboard />} spin={loading}
                            bottomContent={<button className='add-button'
                                onClick={() => openModal('CREATE KMS')}><AiOutlinePlus /> Add kms</button>} />
                    }
                </div>
            </SinglePage>
        </div>
    )
}

export default KmsList