import React, { useEffect, useState } from 'react';
import './reg-full-view.scss';
import { FaStar, FaRegCalendarTimes, FaUserAlt } from "react-icons/fa";
import { IoSettingsSharp } from "react-icons/io5";
import { IoCall } from "react-icons/io5";
import { customerAxios, serviceAxios } from '../../../config/axios';
import { YYYYMMDDFormat, formatStringDate } from '../../../assets/javascript/formate-functions';
import Spinner from '../../../components/common/spinners/SpinWithMessage'

const RegFullView = ({ reg_no, type, cid }) => {

    const [loading, setLoading] = useState('')
    const [customer, setCustomer] = useState({})
    const [service, setService] = useState({})

    useEffect(() => {
        setLoading('fetch')
        const customerData = customerAxios.get(`/profile?cid=${cid}&detail=YES`).then((response) => {
            setCustomer(response.data)
        })

        const workData = serviceAxios.get(`/registered/${type}?reg_no=${reg_no}`).then((response) => {
            setService(response.data)
        })

        Promise.all([customerData, workData]).then(() => setLoading(''))
        // eslint-disable-next-line
    }, [])

    return (
        <div className="reg-full-view-div">
            {loading
                ? <div className='loading'>
                    <Spinner message={'fetch data...'} height={'300px'} />
                </div>
                : <div className="content-div">
                    <div className="left-div">
                        <div className="part-div">
                            <h4><FaUserAlt /> Customer details</h4>
                            <h5>{customer?.full_name} <span className={`text-badge ${customer?.purifier_customer_status}-text`}>{customer?.purifier_customer_status}</span></h5>
                            {customer?.star_rate && <p >Star Rate : <span className={`text-badge green-text`}><FaStar /> {customer?.star_rate}</span></p>}
                            <p className='address'>{customer?.address?.address}, {customer?.address?.place},</p>
                            <p className='address'>{customer?.address?.post} (P.O) - {customer?.address?.pin_code}</p>
                            {customer?.contact1 && <p className='mobile'>Contact 01 : {customer?.contact1}</p>}
                            {customer?.contact2 && <p className='mobile'>Contact 02 : {customer?.contact2}</p>}
                            {service?.additional_contact && <p className='mobile'>Additional : {service?.additional_contact}</p>}
                        </div>
                        <div className="part-div">
                            <h4><IoSettingsSharp /> Service details</h4>
                            <p>Reg no : {service?.reg_no}</p>
                            <p>Reg type : {type}</p>
                            {type === 'complaint'
                                ? <p>Complaint index : {service?.complaint_index}</p>
                                : <p>Service index : {service?.service_index}</p>
                            }
                            {type === 'complaint' && <>
                                <p>Complaint : {service?.what_is_complaint?.map((value) => `${value}, `)}</p>
                                <p>Desc : {service?.description}</p>
                                <p>Priority : <span className={`text-badge ${service?.priority}-text`}>{service?.priority}</span></p>
                            </>}
                            <p>Zone : {service?.send_to_zone}</p>
                            {service?.send_to_technician && <p>Technician : {service?.send_to_technician}</p>}
                        </div>
                        {customer?.purifier_work_description?.[0] && <div className="part-div">
                            <h4><IoCall /> Call History</h4>
                            {customer?.purifier_work_description
                                ?.sort((a, b) => new Date(b?.date) - new Date(a?.date))
                                ?.map((obj) => {
                                    return <p>{YYYYMMDDFormat(new Date(obj?.date))} : {obj?.description} ({obj?.by})</p>
                                })}
                        </div>}
                        {service?.cancel_schedules?.[0] && <div className="part-div">
                            <h4><FaRegCalendarTimes /> Schedule cancels</h4>
                            {service?.cancel_schedules
                                ?.sort((a, b) => new Date(b?.date) - new Date(a?.date))
                                ?.map((obj) => {
                                    return <p>{YYYYMMDDFormat(new Date(obj?.cancelled_date_time))} : {obj?.reason} ({YYYYMMDDFormat(new Date(obj?.scheduled_date_time))})</p>
                                })}
                            <p></p>
                            <p></p>
                        </div>}
                    </div>
                    <div className="right-div">
                        {service?.registered_date_time && <div className="root-div gray-color">
                            <div className="root-text">
                                <h5 className='root-status'>Registered</h5>
                                <p className='date-time'>{formatStringDate(new Date(service?.registered_date_time))}, {new Date(service?.registered_date_time).toLocaleTimeString()}</p>
                                <p className='who'>{service?.customer_reg ? 'Self registration' : service?.reg_by}</p>
                            </div>
                            <div className="dot"></div>
                            {(service?.send_to_zone || service?.cancelled_date_time) && <div className="line"></div>}
                        </div>}

                        {service?.send_to_zone && <div className="root-div gray-color">
                            <div className="root-text">
                                <h5 className='root-status'>Proceed</h5>
                                <p className='date-time'>{formatStringDate(new Date(service?.proceed_date_time))}, {new Date(service?.proceed_date_time).toLocaleTimeString()}</p>
                            </div>
                            <div className="dot"></div>
                            {(service?.scheduled_date_time || service?.cancelled_date_time) && <div className="line"></div>}
                        </div>}

                        {service?.scheduled_date_time && <div className="root-div scheduled-color">
                            <div className="root-text">
                                <h5 className='root-status'>Scheduled</h5>
                                <p className='date-time'>{formatStringDate(new Date(service?.scheduled_date_time))}, {new Date(service?.scheduled_date_time).toLocaleTimeString()}</p>
                                <p className='who'>{service?.scheduled_tech} ({service?.send_to_zone})</p>
                            </div>
                            <div className="dot"></div>
                            {service?.closed_date_time && <div className="line"></div>}
                        </div>}

                        {service?.cancelled_date_time && <div className="root-div red-color">
                            <div className="root-text">
                                <h5 className='root-status'>Cancelled</h5>
                                <p className='date-time'>{formatStringDate(new Date(service?.cancelled_date_time))}, {new Date(service?.cancelled_date_time).toLocaleTimeString()}</p>
                                <p className='who'>{service?.customer_cancel ? 'Self cancel' : service?.cancelled_by}</p>
                                {service?.reason_to_cancel && <p className=''>Reason : {service?.reason_to_cancel}</p>}
                            </div>
                            <div className="dot"></div>
                        </div>}
                        {service?.closed_date_time && <div className="root-div green-color">
                            <div className="root-text">
                                <h5 className='root-status'>Closed</h5>
                                <p className='date-time'>{formatStringDate(new Date(service?.closed_date_time))}, {new Date(service?.closed_date_time).toLocaleTimeString()}</p>
                                <p className='who'>{service?.closed_by}</p>
                            </div>
                            <div className="dot"></div>
                        </div>}


                    </div>
                </div>}
        </div >
    )
}

export default RegFullView