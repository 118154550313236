import * as XLSX from 'xlsx';

export const regDataExportToExcel = (regList, type) => {

    const workbook = XLSX.utils.book_new();

    // UnRegistered
    if (type !== 'complaint') {
        const dataOne = []
        if (regList?.UnRegistered?.[0]) {
            regList?.UnRegistered?.map((data, index) => {
                const obj = {
                    CID: data?.cid || '',
                    "C STATUS": data?.purifier_customer_status || '',
                    NAME: data?.full_name || '',
                    PLACE: data?.address?.place || '',
                    "C ZONE": data?.customer_zone || '',
                    "NEXT SERVICE": data?.next_service_date ? new Date(data?.next_service_date) : '',
                    "PACKAGE EXPIRE": data?.package_expiry_date ? new Date(data?.package_expiry_date) : '',
                    "SERVICE IDX": data?.service_count || ''
                }
                dataOne.push(obj)
                return obj;
            })
        }

        const workSheetOne = XLSX.utils.json_to_sheet(dataOne);
        XLSX.utils.book_append_sheet(workbook, workSheetOne, 'UnRegistered');
    }

    // Proceed
    const dataTwo = []
    if (regList?.Proceed?.[0]) {
        regList?.Proceed?.map((data, index) => {
            const obj = {
                'REG NO': data?.reg_no || '',
                'REG DATE': data?.registered_date_time ? new Date(data?.registered_date_time) : '',
                'CID': data?.cid || '',
                "C STATUS": data?.purifier_customer_status || '',
                NAME: data?.full_name || '',
                PLACE: data?.address?.place || '',
                "C ZONE": data?.customer_zone || '',
                "S ZONE": data?.send_to_zone || '',
                "TECHNICIAN": data?.send_to_technician || '',
            }
            if (type === 'complaint') {
                obj["COMPLAINT IDX"] = data?.complaint_index || ''
                obj["COMPLAINT"] = data?.what_is_complaint?.join(', ') || ''
                obj["PRIORITY"] = data?.priority || ''
            } else {
                obj["SERVICE IDX"] = data?.service_index || ''
            }
            dataTwo.push(obj)
            return obj;
        })
    }

    const workSheetTwo = XLSX.utils.json_to_sheet(dataTwo);
    XLSX.utils.book_append_sheet(workbook, workSheetTwo, 'Proceed');


    // Scheduled
    const dataThree = []
    if (regList?.Scheduled?.[0]) {
        regList?.Scheduled?.map((data, index) => {
            const obj = {
                'REG NO': data?.reg_no || '',
                'REG DATE': data?.registered_date_time ? new Date(data?.registered_date_time) : '',
                'CID': data?.cid || '',
                "C STATUS": data?.purifier_customer_status || '',
                NAME: data?.full_name || '',
                PLACE: data?.address?.place || '',
                "C ZONE": data?.customer_zone || '',
                "S ZONE": data?.send_to_zone || '',
                "TECHNICIAN": data?.send_to_technician || '',
                "ACTION DATE": data?.scheduled_at ? new Date(data?.scheduled_at) : ''
            }
            if (type === 'complaint') {
                obj["COMPLAINT IDX"] = data?.complaint_index || ''
                obj["COMPLAINT"] = data?.what_is_complaint?.join(', ') || ''
                obj["PRIORITY"] = data?.priority || ''
            } else {
                obj["SERVICE IDX"] = data?.service_index || ''
            }
            dataThree.push(obj)
            return obj;
        })
    }

    const workSheetThree = XLSX.utils.json_to_sheet(dataThree);
    XLSX.utils.book_append_sheet(workbook, workSheetThree, 'Scheduled');


    // Attending
    const dataFour = []
    if (regList?.Attending?.[0]) {
        regList?.Attending?.map((data, index) => {
            const obj = {
                'REG NO': data?.reg_no || '',
                'REG DATE': data?.registered_date_time ? new Date(data?.registered_date_time) : '',
                'CID': data?.cid || '',
                "C STATUS": data?.purifier_customer_status || '',
                NAME: data?.full_name || '',
                PLACE: data?.address?.place || '',
                "C ZONE": data?.customer_zone || '',
                "S ZONE": data?.send_to_zone || '',
                "TECHNICIAN": data?.send_to_technician || '',
                "SCHEDULED DATE": data?.scheduled_at ? new Date(data?.scheduled_at) : ''
            }
            if (type === 'complaint') {
                obj["COMPLAINT IDX"] = data?.complaint_index || ''
                obj["COMPLAINT"] = data?.what_is_complaint?.join(', ') || ''
                obj["PRIORITY"] = data?.priority || ''
            } else {
                obj["SERVICE IDX"] = data?.service_index || ''
            }
            dataFour.push(obj)
            return obj;
        })
    }

    const workSheetFour = XLSX.utils.json_to_sheet(dataFour);
    XLSX.utils.book_append_sheet(workbook, workSheetFour, 'Attending');


    // Closed
    const dataFive = []
    if (regList?.Closed?.[0]) {
        regList?.Closed?.map((data, index) => {
            const obj = {
                'REG NO': data?.reg_no || '',
                'REG DATE': data?.registered_date_time ? new Date(data?.registered_date_time) : '',
                'CID': data?.cid || '',
                "C STATUS": data?.purifier_customer_status || '',
                NAME: data?.full_name || '',
                PLACE: data?.address?.place || '',
                "C ZONE": data?.customer_zone || '',
                "S ZONE": data?.send_to_zone || '',
                "TECHNICIAN": data?.send_to_technician || '',
                "ACTION DATE": data?.closed_date_time ? new Date(data?.closed_date_time) : ''
            }
            if (type === 'complaint') {
                obj["COMPLAINT IDX"] = data?.complaint_index || ''
                obj["COMPLAINT"] = data?.what_is_complaint?.join(', ') || ''
                obj["PRIORITY"] = data?.priority || ''
            } else {
                obj["SERVICE IDX"] = data?.service_index || ''
            }
            dataFive.push(obj)
            return obj;
        })
    }

    const workSheetFive = XLSX.utils.json_to_sheet(dataFive);
    XLSX.utils.book_append_sheet(workbook, workSheetFive, 'Closed');


    // Cancelled
    const dataSix = []
    if (regList?.Cancelled?.[0]) {
        regList?.Cancelled?.map((data, index) => {
            const obj = {
                'REG NO': data?.reg_no || '',
                'REG DATE': data?.registered_date_time ? new Date(data?.registered_date_time) : '',
                'CID': data?.cid || '',
                "C STATUS": data?.purifier_customer_status || '',
                NAME: data?.full_name || '',
                PLACE: data?.address?.place || '',
                "C ZONE": data?.customer_zone || '',
                "S ZONE": data?.send_to_zone || '',
                "TECHNICIAN": data?.send_to_technician || '',
                "ACTION DATE": data?.cancelled_date_time ? new Date(data?.cancelled_date_time) : ''
            }
            if (type === 'complaint') {
                obj["COMPLAINT IDX"] = data?.complaint_index || ''
                obj["COMPLAINT"] = data?.what_is_complaint?.join(', ') || ''
                obj["PRIORITY"] = data?.priority || ''
            } else {
                obj["SERVICE IDX"] = data?.service_index || ''
            }
            dataSix.push(obj)
            return obj;
        })
    }

    const workSheetSix = XLSX.utils.json_to_sheet(dataSix);
    XLSX.utils.book_append_sheet(workbook, workSheetSix, 'Cancelled');

    return workbook;
};


export const downloadRegDataXl = (workbook, filename) => {
    const excelBuffer = XLSX.write(workbook, {
        bookType: 'xlsx', bookSST: true, type: 'array', cellDates: true, cellNF: false
    });

    const data = new Blob([excelBuffer], { type: 'application/octet-stream' });
    filename = filename + '.xlsx';

    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        // For IE browser
        window.navigator.msSaveOrOpenBlob(data, filename);
    } else {
        // For other browsers
        const url = window.URL.createObjectURL(data);
        const link = document.createElement('a');
        link.href = url;
        link.download = filename;
        link.click();
        window.URL.revokeObjectURL(url);
    }
}