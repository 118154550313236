import React, { useEffect, useState } from 'react'
import './style.scss';
import './section-seven.scss';
import { useDispatch, useSelector } from 'react-redux'
import { useSearchParams } from 'react-router-dom';
import { findServiceFormTotal } from '../../../assets/javascript/calc-functions'
import InfoBox from '../../common/info-box/InfoBox';
import SignCanvas from '../../common/signatureCanvas/SignCanvas';
import NormalInput from '../../common/inputs/NormalInput';
import { changeFormValue, nextAction, clearNext } from '../../../redux/features/user/serviceSlice'
import toast from 'react-hot-toast';

const SectionSeven = React.memo(({ customer }) => {
    const { serviceForm } = useSelector((state) => state.serviceData)
    // eslint-disable-next-line
    const [searchParams, setSearchParams] = useSearchParams();
    const dispatch = useDispatch()
    const [bill, setBill] = useState({})
    const [counts, setCounts] = useState({})


    const handleChange = (e) => {
        dispatch(changeFormValue({
            ...serviceForm,
            signature: {
                ...(serviceForm.signature || {}),
                [e.target.name]: e.target.value
            }

        }))
    }

    const handleChangeForm = (e) => {
        dispatch(changeFormValue({
            ...serviceForm,
            [e.target.name]: e.target.value,
        }))
    }

    const saveSignature = (url) => {
        dispatch(changeFormValue({
            ...serviceForm,
            signature: {
                ...(serviceForm.signature || {}),
                url: url
            }
        }))
    }

    const resetSignature = () => {
        dispatch(changeFormValue({
            ...serviceForm,
            signature: {
                ...(serviceForm.signature || {}),
                url: null
            }
        }))
    }

    const handleSectionSubmit = (e) => {
        e.preventDefault()
        if (!serviceForm?.signature?.url) {
            toast.error('Customer signature required!')
            return;
        }
        if (bill?.grand_total_receivable > 0) {
            // to section eight
            dispatch(nextAction(['payment', 'review']))
            setSearchParams(`section=payment`)
        } else {
            // to section ten
            dispatch(nextAction(['feedback', 'review']))
            setSearchParams(`section=feedback`)
            dispatch(clearNext(['payment']))
            dispatch(changeFormValue({
                ...serviceForm,
                payment_history: undefined
            }))
        }
    }


    useEffect(() => {
        // Total calculation algorithms
        const totalObj = findServiceFormTotal(serviceForm?.bill_data || {}, serviceForm?.primary_spares || [],
            serviceForm?.secondary_spares || [], serviceForm?.special_spares || [], customer?.debit_amount || 0,
            serviceForm?.service_data || {})
       
        setBill(totalObj?.bill)
        setCounts(totalObj?.counts)

        if (!serviceForm?.receipt_send_to) {
            dispatch(changeFormValue({
                ...serviceForm,
                receipt_send_to: customer?.contact1 || customer?.contact2 || ''

            }))
        }
    }, [customer])


    return (
        <div className="service-form-section-div section-seven-div">
            <div className="title-section">
                <h3>Review</h3>
                <p>Verify amounts before payment</p>
            </div>

            <div className="form-review-div">
                <table>
                    <thead>
                        <tr>
                            <th></th>
                            <th>N</th>
                            <th>Est.</th>
                            <th>Receive</th>
                        </tr>
                    </thead>
                    <tbody>
                        {bill?.amc_fund && <tr>
                            <td>AMC Fund</td>
                            <td></td>
                            <td></td>
                            <td>{bill?.amc_fund || 0}</td>
                        </tr>}
                        {bill?.ssp_fund && <tr>
                            <td>SSP Fund</td>
                            <td></td>
                            <td></td>
                            <td>{bill?.ssp_fund || 0}</td>
                        </tr>}
                        <tr>
                            <td>Primary spares</td>
                            <td>{counts?.primary}</td>
                            <td>{bill?.primary_spare_total_est}</td>
                            <td>{bill?.primary_spare_total_receivable}</td>
                        </tr>
                        <tr>
                            <td>Secondary spares</td>
                            <td>{counts?.secondary}</td>
                            <td>{bill?.secondary_spare_total_est}</td>
                            <td>{bill?.secondary_spare_total_receivable}</td>
                        </tr>
                        <tr>
                            <td>Special spears</td>
                            <td>{counts?.special}</td>
                            <td>{bill?.special_work_spare_total_est}</td>
                            <td>{bill?.special_work_spare_total_receivable}</td>
                        </tr>
                        <tr>
                            <td>Service charge</td>
                            <td></td>
                            <td>{bill?.service_charge_est || 0}{(serviceForm?.service_data?.repeat_tech || (counts?.warranty === counts?.total_spare && counts?.warranty)) ? '*' : ''}</td>
                            <td>{bill?.service_charge_receivable || 0}</td>
                        </tr>
                        {bill?.extra_charge_est ? <tr>
                            <td>Extra charge</td>
                            <td></td>
                            <td>{bill?.extra_charge_est || 0}</td>
                            <td>{bill?.extra_charge_receivable || 0}</td>
                        </tr> : ""}
                        {bill?.compliment_given_now ? <>
                            <tr className='total'>
                                <td>Total</td>
                                <td></td>
                                <td>{bill?.total_est}</td>
                                <td>{bill?.total_receivable}</td>
                            </tr>
                            <tr>
                                <td>Compliment</td>
                                <td></td>
                                <td>{bill?.compliment_given_now}</td>
                                <td>{bill?.compliment_given_now}</td>
                            </tr>
                        </> : ''}
                        <tr className='grand-total'>
                            <td>Grand Total</td>
                            <td></td>
                            <td>{bill?.grand_total_est}</td>
                            <td>{bill?.grand_total_receivable}</td>
                        </tr>
                        {counts?.warranty ? <tr>
                            <td>Warranty Count</td>
                            <td>{counts?.warranty}</td>
                            <td></td>
                            <td></td>
                        </tr> : ''}
                    </tbody>
                </table>
                {serviceForm?.service_data?.repeat_tech && <p>*This is repeat work</p>}
                {(counts?.warranty === counts?.total_spare && counts?.warranty) && <p>*This is warranty spare work</p>}
            </div>

            {customer.credit_amount ? <div className="credit-div">
                <h5>Credit Balance : <span>₹{customer.credit_amount}</span></h5>
                <InfoBox type={'info'} description={`If a credit amount is received, Do not include this service payment.`} />
            </div> : ''}

            <div className="form-div">
                <form action="" onSubmit={handleSectionSubmit}>

                    <div className="signature-div">
                        <div className="sub-title"><h4>Customer signature</h4></div>
                        <NormalInput label='Signatured by' name='name' value={serviceForm?.signature?.name} onChangeFun={handleChange} type={'text'} />
                        <NormalInput label='Contact number to send receipt' name='receipt_send_to' value={serviceForm?.receipt_send_to}
                            onChangeFun={handleChangeForm} type={'number'} max={9999999999} min={5000000000} />
                        <SignCanvas signImg={serviceForm?.signature?.url} saveSign={saveSignature} resetSign={resetSignature} />
                    </div>

                    {/* Next Buttons */}
                    <div className="handle-buttons-div">
                        <button type='submit' className='next'>Next</button>
                    </div>

                </form>
            </div>
        </div>
    )
})

export default SectionSeven