import React from 'react'
import './style.scss'
import { useDispatch, useSelector } from 'react-redux';
import { findServiceFormTotal } from '../../../assets/javascript/calc-functions'
import { changeFormValue, clearFormData } from '../../../redux/features/user/serviceSlice'
import { YYYYMMDDFormat } from '../../../assets/javascript/formate-functions';
import { useNavigate } from 'react-router-dom'
import { serviceAxios } from '../../../config/axios';
import RadioInput from '../../common/inputs/RadioInput'
import toast from 'react-hot-toast';
import InfoBox from '../../common/info-box/InfoBox';
import axios from 'axios';

const SectionNine = React.memo(({ customer, setLoading, purifier, eligibility }) => {
    const { serviceForm } = useSelector((state) => state.serviceData)
    const { user } = useSelector((state) => state.userAuth)
    const { internet } = useSelector((state) => state.network)
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const handleChangeFeed = (e) => {
        dispatch(changeFormValue({
            ...serviceForm,
            tech_feedback: {
                ...(serviceForm?.tech_feedback || {}),
                [e.target.name]: Number(e.target.value)
            }
        }))
    }

    const doSendSMS = (mobileNumber, cid, messageText, billLink, receiptLink) => {
        axios.get(`https://www.smsgatewayhub.com/api/mt/SendSMS?APIKey=5pClQGkgHEesfc32C3BqnQ&senderid=AWSLNS&channel=Trans&DCS=0&flashsms=0&number=91${mobileNumber}&text=Dear Customer ${cid}, Your ${messageText} is completed. Tap the link for bill details. Bill : ${billLink ? "https://link.awsit.in/p?l=" + billLink : 'Nill'} Receipt : ${receiptLink ? "https://link.awsit.in/p?l=" + receiptLink : 'Nill'} -Alliance Water Solutions-&DLTTemplateId=1207171904814732734&PEID=1501671820000052693&route=60`)
            .then((smsRes) => {
                toast.success('SMS Successfully sended!')
            }).catch((error) => {
                if (error.message === 'Network Error') {
                    toast.success('SMS Successfully sended!')
                } else {
                    toast.error('SMS Failed!')
                }
            })

    }

    const handleSubmitForm = (e) => {
        e.preventDefault();
        setLoading('submit')
        // Calculate bill Amount
        const totalObj = findServiceFormTotal(serviceForm?.bill_data || {}, serviceForm?.primary_spares || [],
            serviceForm?.secondary_spares || [], serviceForm?.special_spares || [], customer?.debit_amount || 0,
            serviceForm?.service_data || {})
        const receivedAmount = serviceForm?.payment_history?.[0]
            ? (Number(serviceForm?.payment_history?.[0]?.cash || 0) + Number(serviceForm?.payment_history?.[0]?.bank || 0) + Number(serviceForm?.payment_history?.[0]?.cheque || 0))
            : 0
        const balanceAmount = Number(totalObj?.bill?.grand_total_receivable || 0) - receivedAmount

        let packageStart = YYYYMMDDFormat(new Date())
        let packageEnd = null
        if (purifier?.package_expiry_date) {
            let deference = new Date(purifier?.package_expiry_date) - new Date()
            const days = Math.floor(deference / (1000 * 60 * 60 * 24));
            if (days > 0) {
                const setToYear = new Date(new Date().setFullYear(new Date().getFullYear() + 1))
                const AddToToday = new Date(setToYear.setDate(setToYear.getDate() + days))

                packageEnd = YYYYMMDDFormat(AddToToday)
            } else {
                packageEnd = YYYYMMDDFormat(new Date(new Date().setFullYear(new Date().getFullYear() + 1)))
            }
        } else {
            const setToYear = new Date(new Date().setFullYear(new Date().getFullYear() + 1))
            packageEnd = YYYYMMDDFormat(setToYear)
        }

        // Feedback rate
        const totalFeedPoint = serviceForm?.tech_feedback?.inaccurate_info + serviceForm?.tech_feedback?.delaying_the_work + serviceForm?.tech_feedback?.disruptions_in_workflow
        const feedInTen = parseInt(totalFeedPoint / 3)


        let fullData = JSON.parse(JSON.stringify(serviceForm))

        fullData = {
            ...fullData,
            date: YYYYMMDDFormat(new Date()),
            out_time: new Date(),
            duration: parseInt((new Date() - new Date(serviceForm.in_time)) / 1000),
            total_amount_received: receivedAmount,
            balance_amount: balanceAmount,
            technician: user?._id,
            zone_id: user?.active_zone?._id,
            bill_data: {
                ...(fullData?.bill_data || {}),
                ...totalObj?.bill
            },
            service_data: {
                ...(fullData.service_data || {}),
                carbon_filter_start_date: serviceForm?.service_data?.carbon_filter_changed ? YYYYMMDDFormat(new Date()) : undefined,
                carbon_filter_expiry_date: serviceForm?.service_data?.carbon_filter_changed ? YYYYMMDDFormat(new Date(new Date().setFullYear(new Date().getFullYear() + 1))) : undefined,
                package_start_date: serviceForm?.service_data?.package_renewal ? packageStart : undefined,
                package_expiry_date: serviceForm?.service_data?.package_renewal ? packageEnd : undefined,
            },
            tech_feedback: {
                ...(fullData.tech_feedback || {}),
                star_in_ten: feedInTen
            }
        }

        // If Repeat work then clear call
        if (serviceForm?.service_data?.repeat_tech || (totalObj?.counts?.warranty && totalObj?.counts?.warranty === totalObj?.counts?.total_spare)) {
            fullData.call_details = {}
        }

        if (!internet) {
            toast.error('No internet connection!')
            setLoading('')
            return;
        }


        serviceAxios.post('/service-form-submit', fullData).then((response) => {

            // Message Text
            let messageText = ''
            if (serviceForm?.service_data?.package_renewal) {
                messageText = `${serviceForm?.service_data?.new_customer_status} Package renewal from ${serviceForm?.purifier_customer_status}`
            } else if (serviceForm?.service_data?.periodical_service) {
                messageText = `${serviceForm?.purifier_customer_status} Periodical service`
            } else if (serviceForm?.service_data?.carbon_filter_changed) {
                messageText = `${serviceForm?.purifier_customer_status} Carbon charge service`
            } else if (serviceForm?.work_method === 'complaint') {
                messageText = `${serviceForm?.purifier_customer_status} Complaint service`
            }

            const totalReceivedAmt = (Number(serviceForm?.payment_history?.[0]?.cash || 0) + Number(serviceForm?.payment_history?.[0]?.bank || 0) + Number(serviceForm?.payment_history?.[0]?.cheque || 0))
            let billLink = null
            let receiptLink = null

            // Sort Link Build
            if (messageText) {

                axios.post(`https://link.awsit.in/p/C9TDPV25TEBDL5CC8MBG/create-link`, {
                    ownerId: 'purifier_service',
                    linkUrl: `https://www.controlnex.alliancewatersolutions.com/payment/pdf-generator?srl_number=${response.data.srl_number}&cid=${serviceForm?.cid}&date=${YYYYMMDDFormat(new Date())}&type=Bill`,
                    linkLength: 8,
                    expUntil: 30
                }).then((billResponse) => {
                    billLink = billResponse?.data?.data?.link_id || null

                    if (totalReceivedAmt > 0) {
                        axios.post(`https://link.awsit.in/p/C9TDPV25TEBDL5CC8MBG/create-link`, {
                            ownerId: 'purifier_service',
                            linkUrl: `https://www.controlnex.alliancewatersolutions.com/payment/pdf-generator?srl_number=${response.data.srl_number}&cid=${serviceForm?.cid}&date=${YYYYMMDDFormat(new Date())}&type=Receipt`,
                            linkLength: 8,
                            expUntil: 30
                        }).then((receiptResponse) => {
                            receiptLink = receiptResponse?.data?.data?.link_id || null

                            // Send SMS
                            doSendSMS(serviceForm?.receipt_send_to, serviceForm?.cid, messageText, billLink, receiptLink)
                        })

                    } else {

                        // Send SMS
                        doSendSMS(serviceForm?.receipt_send_to, serviceForm?.cid, messageText, billLink, receiptLink)
                    }
                })

                toast.success('Form submit success!')
                dispatch(clearFormData())
                navigate(`/attending/submit?sms=Yes&srl_number=${response.data.srl_number}&feedback=Yes`, { replace: true })
                setLoading('')

            } else {
                toast.success('Form submit success!')
                dispatch(clearFormData())
                navigate(`/attending/submit?sms=No&srl_number=${response.data.srl_number}&feedback=Yes`, { replace: true })
                setLoading('')
            }

        }).catch((error) => {
            toast.error(error.message)
            toast.error('Exit form, Refresh now!')
            setLoading('')
        })
    }

    return (
        <div className="service-form-section-div">
            <div className="title-section">
                <h3>Feedback</h3>
                <p>Feedback about customer</p>
            </div>
            <div className="form-div">
                <form action="" onSubmit={handleSubmitForm}>
                    <div className="radio-input-border-div">
                        <div className="sub-title"><h4>Does the customer provide accurate and relevant information when you call regarding your query ?</h4></div>
                        <div className="radio-inputs">
                            <RadioInput label={"Yes"} name={'inaccurate_info'} value={10} onChangeFun={handleChangeFeed}
                                checked={serviceForm?.tech_feedback?.inaccurate_info === 10} />
                            <RadioInput label={"Mostly"} name={'inaccurate_info'} value={7.5} onChangeFun={handleChangeFeed}
                                checked={serviceForm?.tech_feedback?.inaccurate_info === 7.5} />
                            <RadioInput label={"Partially"} name={'inaccurate_info'} value={5} onChangeFun={handleChangeFeed}
                                checked={serviceForm?.tech_feedback?.inaccurate_info === 5} />
                            <RadioInput label={"Entirely"} name={'inaccurate_info'} value={2.5} onChangeFun={handleChangeFeed}
                                checked={serviceForm?.tech_feedback?.inaccurate_info === 2.5} />
                            <RadioInput label={"No"} name={'inaccurate_info'} value={0} onChangeFun={handleChangeFeed}
                                checked={serviceForm?.tech_feedback?.inaccurate_info === 0} />
                        </div>
                    </div>

                    <div className="radio-input-border-div">
                        <div className="sub-title"><h4>Does the customer cause delays in your work or make you wait ?</h4></div>
                        <div className="radio-inputs">
                            <RadioInput label={"No"} name={'delaying_the_work'} value={10} onChangeFun={handleChangeFeed}
                                checked={serviceForm?.tech_feedback?.delaying_the_work === 10} />
                            <RadioInput label={"Partially"} name={'delaying_the_work'} value={6.5} onChangeFun={handleChangeFeed}
                                checked={serviceForm?.tech_feedback?.delaying_the_work === 6.5} />
                            <RadioInput label={"Mostly"} name={'delaying_the_work'} value={3.5} onChangeFun={handleChangeFeed}
                                checked={serviceForm?.tech_feedback?.delaying_the_work === 3.5} />
                            <RadioInput label={"Yes"} name={'delaying_the_work'} value={0} onChangeFun={handleChangeFeed}
                                checked={serviceForm?.tech_feedback?.delaying_the_work === 0} />
                        </div>
                    </div>

                    <div className="radio-input-border-div">
                        <div className="sub-title"><h4>Does the customer create any disruptions that impact your workflow ?</h4></div>
                        <div className="radio-inputs">
                            <RadioInput label={"No"} name={'disruptions_in_workflow'} value={10} onChangeFun={handleChangeFeed}
                                checked={serviceForm?.tech_feedback?.disruptions_in_workflow === 10} />
                            <RadioInput label={"Partially"} name={'disruptions_in_workflow'} value={6.5} onChangeFun={handleChangeFeed}
                                checked={serviceForm?.tech_feedback?.disruptions_in_workflow === 6.5} />
                            <RadioInput label={"Mostly"} name={'disruptions_in_workflow'} value={3.5} onChangeFun={handleChangeFeed}
                                checked={serviceForm?.tech_feedback?.disruptions_in_workflow === 3.5} />
                            <RadioInput label={"Yes"} name={'disruptions_in_workflow'} value={0} onChangeFun={handleChangeFeed}
                                checked={serviceForm?.tech_feedback?.disruptions_in_workflow === 0} />
                        </div>
                    </div>

                    {purifier?.pending_ssp_token < 2 && purifier?.purifier_customer_status === 'SSP' && !eligibility?.renewal &&
                        <InfoBox type='warning' title={'Token expiring soon...'} description={`This is the customer's last token and informs to top-up the token for balance services.
                                    Top-up must be done before this service`} />}

                    {/* Next Buttons */}
                    <div className="handle-buttons-div">
                        <button type='submit' className='submit'>Submit</button>
                    </div>

                </form>
            </div>
        </div>
    )


})

export default SectionNine