const findPercentage = (part, whole) => {
    return (part / whole) * 100;
}

const calculatePercentage = (value, percentage) => {
    return (value * percentage) / 100;
}


const sspItemsPriceRounding = (items, roundAmount, itemTotalAmount) => {
    // Find the total difference for minus
    const difference = itemTotalAmount - roundAmount
    let totalMinus = 0

    // Minus amount form product
    const minusAmount = (item, amount) => {
        const newDiscount = Math.round(100 - ((parseFloat((item.mrp - amount).toFixed(2)) / Number(item.mrp)) * 100))
        item.tally_rate = Math.round(item.mrp - amount)
        item.rate = Math.round(item.mrp - amount)
        item.discount_percentage = newDiscount

        // total Minus amount
        totalMinus = totalMinus + (amount * item.quantity)
    }

    if (difference !== 0) {
        // Difference percentage for minus the amount
        const diffPer = findPercentage(difference, itemTotalAmount)

        // Loop items for minus the difference
        items.forEach((item, index) => {
            // Minus value form one product
            let minusValue = parseInt(calculatePercentage(item.mrp, diffPer))

            minusAmount(item, minusValue)
        });

        if (difference !== totalMinus) {
            // Find Highest rate product index
            const highestRateProductIndex = items.reduce((highestIndex, product, currentIndex, array) => (product.rate > array[highestIndex].rate && (difference - totalMinus) % product.quantity === 0) ? currentIndex : highestIndex, 0);

            const balanceAmt = ((difference - totalMinus) / items[highestRateProductIndex].quantity) + (items[highestRateProductIndex].mrp - items[highestRateProductIndex].rate)

            minusAmount(items[highestRateProductIndex], balanceAmt)
        }

    }

    // Find large rate product in array




    return items;
}


const calculateTotalAmount = (items, rate_type) => {
    let totalAmount = 0

    items.forEach((item) => {
        totalAmount += item[rate_type] * item.quantity;
    });

    return totalAmount
}

// Find Total for Service form
const findServiceFormTotal = (billData, primarySpares, secondarySpares, specialSpares, complimentAmt, serviceData) => {
    const data = JSON.parse(JSON.stringify(billData))
    let warrantySpares = 0

    // Primary Spare
    primarySpares.map((obj) => {
        data.primary_spare_total_est = (data.primary_spare_total_est || 0) + (obj?.mrp * obj?.quantity || 0)
        data.primary_spare_total_receivable = (data.primary_spare_total_receivable || 0) + (obj?.rate * obj?.quantity || 0)

        data.total_est = (data.total_est || 0) + (obj?.mrp * obj?.quantity || 0)
        data.total_receivable = (data.total_receivable || 0) + (obj?.rate * obj?.quantity || 0)
        if (obj.warranty) { warrantySpares++ }

        return obj
    })

    // Secondary
    secondarySpares.map((obj) => {
        data.secondary_spare_total_est = (data.secondary_spare_total_est || 0) + (obj?.mrp * obj?.quantity || 0)
        data.secondary_spare_total_receivable = (data.secondary_spare_total_receivable || 0) + (obj?.rate * obj?.quantity || 0)

        data.total_est = (data.total_est || 0) + (obj?.mrp * obj?.quantity || 0) || 0
        data.total_receivable = (data.total_receivable || 0) + (obj?.rate * obj?.quantity || 0) || 0

        if (obj.warranty) { warrantySpares++ }

        return obj
    })

    // Special
    specialSpares.map((obj) => {
        data.special_work_spare_total_est = (data.special_work_spare_total_est || 0) + (obj?.mrp * obj?.quantity || 0)
        data.special_work_spare_total_receivable = (data.special_work_spare_total_receivable || 0) + (obj?.rate * obj?.quantity || 0)

        data.total_est = (data.total_est || 0) + (obj?.mrp * obj?.quantity || 0) || 0
        data.total_receivable = (data.total_receivable || 0) + (obj?.rate * obj?.quantity || 0) || 0

        if (obj.warranty) { warrantySpares++ }

        return obj
    })

    // Calc total of fund, service_charge

    const totalSpareCount = (primarySpares.length || 0) + (secondarySpares.length || 0) + (specialSpares.length || 0)

    if (serviceData.repeat_tech || (warrantySpares !== 0 && warrantySpares === totalSpareCount)) {
        data.service_charge_est = 0
        data.service_charge_tally = 0
        data.service_charge_receivable = 0
    }

    const fund = (data?.amc_fund || 0) + (data?.ssp_fund || 0)
    data.total_est = (data.total_est || 0) + data?.service_charge_est + (data?.extra_charge_est || 0) || 0
    data.total_receivable = (data.total_receivable || 0) + fund + data?.service_charge_receivable + (data?.extra_charge_receivable || 0) || 0

    // Include compliment 

    if (Number(complimentAmt) > (data.total_receivable || 0)) {
        const nowAmt = Number(complimentAmt) - (data.total_receivable || 0)
        data.compliment_given_now = Number(complimentAmt) - nowAmt
    } else {
        data.compliment_given_now = Number(complimentAmt) || 0
    }

    data.grand_total_est = (data.total_est || 0) - data.compliment_given_now
    data.grand_total_receivable = (data.total_receivable || 0) - data.compliment_given_now



    return {
        bill: data,
        counts: {
            warranty: warrantySpares,
            primary: primarySpares.length || 0,
            secondary: secondarySpares.length || 0,
            special: specialSpares.length || 0,
            total_spare: totalSpareCount
        }
    }
}


export {
    sspItemsPriceRounding, calculateTotalAmount, findServiceFormTotal
}