import React, { useEffect, useState } from 'react'
import './style.scss'
import { useParams } from 'react-router-dom'
import { userAxios } from '../../../config/axios';
import Modal from '../../../components/common/modal/Modal';
import AdminPage from '../../../components/common/page/AdminPage';
import TableFilter from '../../../components/common/table-filter/TableFilter';
import Spinner from '../../../components/common/spinners/SpinWithMessage'
import AddEditSubOption from '../../../components/admin/add-edit-compalint-sub_option/AddEditSubOption';
import { FiEdit2 } from 'react-icons/fi'
import { AiOutlinePlus } from 'react-icons/ai'
import { TbDatabaseExclamation } from "react-icons/tb";
import { BsFillTrash3Fill } from "react-icons/bs";
import toast from 'react-hot-toast';
import InfoBox from '../../../components/common/info-box/InfoBox';


const SubOptionInput = () => {
    const { title } = useParams();
    const [data, setData] = useState([])
    const [loading, setLoading] = useState('')
    const [modal, setModal] = useState({ content: null, title: null, status: false })


    const openModal = (head, data) => {
        setModal({
            content: <AddEditSubOption title={title} data={data || ''} setModal={setModal} setData={setData} />,
            title: head,
            status: true
        })
    }

    const handelDelete = (id) => {
        const ask = window.confirm('Delete this ?')
        if (ask) {
            userAxios.delete(`/resource/single/${title}?_id=${id}`).then((response) => {
                setData(data.filter(option => option._id !== id))
            }).catch((error) => {
                toast.error(error.message || 'Try now!')
            })
        }
    }


    useEffect(() => {
        setLoading('fetch')
        userAxios.get(`/resource/single/${title}`).then((response) => {
            setData(response.data)
            setLoading('')
        })
        // eslint-disable-next-line
    }, [])


    return (
        <div className="input-single-sub-option-list-div">
            <Modal modal={modal} setModal={setModal} />
            <AdminPage titleArray={[`${title} List`]}>
                <div className="table-border-div">
                    {data?.[0] ? <TableFilter srlNo={true} topRight={<button className='add-button'
                        onClick={() => openModal('CREATE COMPLAINT NATURE')}><AiOutlinePlus /> Add option</button>}>
                        <table>
                            <thead>
                                <tr>
                                    <th>Nature</th>
                                    <th>Reasons</th>
                                    <th>Solutions</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data.map((value) => <tr key={value._id}>
                                    <td>{value?.option}</td>
                                    <td>{value?.sub_options_one?.map((sub) => <>{sub}<br></br></>)}</td>
                                    <td>{value?.sub_options_two?.map((sub) => <>{sub}<br></br></>)}</td>
                                    <td> <div className='buttons' >
                                        <button title='Edit' onClick={() => openModal('EDIT OPTION    ', value)}
                                            className='button-small-icon edit'><FiEdit2 /></button>
                                        <button title='Remove' onClick={() => handelDelete(value._id)}
                                            className='button-small-icon delete'><BsFillTrash3Fill /></button>
                                    </div></td>
                                </tr>)}
                            </tbody>
                        </table>
                    </TableFilter>
                        : <Spinner message={loading === 'fetch' ? 'Fetch data...' : 'No data'} icon={loading !== 'fetch' && <TbDatabaseExclamation />}
                            spin={loading === 'fetch'} bottomContent={loading !== 'fetch' && <button className='add-button'
                                onClick={() => openModal('CREATE COMPLAINT NATURE')}><AiOutlinePlus /> Add option</button>} />}
                </div>
            </AdminPage>
        </div>
    )
}

export default SubOptionInput