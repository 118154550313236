import React, { useEffect, useState } from 'react'
import './service-list.scss'
import SinglePage from '../../../components/common/page/SinglePage'
import Modal from '../../../components/common/modal/Modal'
import Spinner from '../../../components/common/spinners/SpinWithMessage'
import { FilterServiceList } from '../../../components/user/filter-service-list/FilterServiceList'
import { useSearchParams } from 'react-router-dom'
import { serviceAxios } from '../../../config/axios'
import { useSelector } from 'react-redux'
import { ServiceCard } from '../../../components/user/service-list-card/ServiceCard'
import { listAllPostsFromCustomer } from '../../../assets/javascript/find-functions'
import { RxDashboard } from 'react-icons/rx'
import { YYYYMMDDFormat } from '../../../assets/javascript/formate-functions'

function PendingServiceList() {
    const { user } = useSelector((state) => state.userAuth)
    const [modal, setModal] = useState({ content: null, title: null, status: false })
    const [cache, setCache] = useState({ complaint: [], service: [], renewal: [] })
    const [data, setData] = useState({ complaint: [], service: [], renewal: [] })
    const [list, setList] = useState({ item: null, data: [] })
    const [posts, setPosts] = useState([])
    const [searchParams, setSearchParams] = useSearchParams()
    const [loading, setLoading] = useState('fetch')

    const fetchData = () => {
        setLoading('fetch')
        serviceAxios.get(`/technician/pending-service?zone_id=${user?.active_zone?._id}&tech_id=${user?._id}`).then((response) => {
            setCache(response?.data)
            setList({ item: searchParams.get("page"), data: response?.data?.[searchParams.get("page")] })
            setPosts(listAllPostsFromCustomer([...response.data?.complaint, ...response.data?.service, ...response.data?.renewal]))
            setLoading('')
        })
    }

    useEffect(() => {
        if (!searchParams.get("page")) {
            setSearchParams({ page: 'complaint' })
        }
        fetchData()
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        setList({ item: searchParams.get("page"), data: data?.[searchParams.get("page")] })
        // eslint-disable-next-line
    }, [searchParams.get("page"), data])

    const openFilter = () => {
        setModal({
            content: <FilterServiceList posts={posts} setModal={setModal} />,
            title: 'Filter',
            status: true
        })
    }

    const clearFilter = () => {
        const newSearchParams = new URLSearchParams(searchParams);
        newSearchParams.delete('cid')
        newSearchParams.delete('postOffice')
        newSearchParams.delete('customerStatus')
        newSearchParams.delete('type')
        setSearchParams(newSearchParams);
        setData(cache)
    }

    const changeType = (type) => {
        if (!type) {
            return;
        }

        const newSearchParams = new URLSearchParams(searchParams);
        newSearchParams.set('page', type);
        setSearchParams(newSearchParams);

    }

    const doFilter = () => {
        let keySet = [], complaintFilter = cache.complaint, serviceFilter = cache.service, renewalFilter = cache.renewal
        if (searchParams.get('cid')) {
            complaintFilter = complaintFilter?.filter(obj => obj.cid === searchParams.get('cid'))
            serviceFilter = serviceFilter?.filter(obj => obj.cid === searchParams.get('cid'))
            renewalFilter = renewalFilter?.filter(obj => obj.cid === searchParams.get('cid'))
        }

        // Filter Posts
        if (searchParams.get('postOffice')) {
            const postSplit = searchParams.get('postOffice').split('+')
            keySet = new Set(postSplit)
            complaintFilter = complaintFilter?.filter(obj => keySet.has(obj.address.post))
            serviceFilter = serviceFilter?.filter(obj => keySet.has(obj.address.post))
            renewalFilter = renewalFilter?.filter(obj => keySet.has(obj.address.post))
        }

        // Filter Customer Status
        if (searchParams.get('customerStatus')) {
            const customerStatusSplit = searchParams.get('customerStatus').split('+')
            keySet = new Set(customerStatusSplit)
            complaintFilter = complaintFilter?.filter(obj => keySet.has(obj.purifier_customer_status))
            serviceFilter = serviceFilter?.filter(obj => keySet.has(obj.purifier_customer_status))
            renewalFilter = renewalFilter?.filter(obj => keySet.has(obj.purifier_customer_status))
        }

        // Type
        if (searchParams.get('type')) {
            const today = YYYYMMDDFormat(new Date());
            const firstDay = today.slice(0, 8) + '01'

            if (searchParams.get('type') === '1') {
                complaintFilter = complaintFilter.filter((data) => (data?.registered_date_time ? YYYYMMDDFormat(new Date(data?.registered_date_time)) : data?.next_service_date) >= firstDay)
                serviceFilter = serviceFilter.filter((data) => (data?.registered_date_time ? YYYYMMDDFormat(new Date(data?.registered_date_time)) : data?.next_service_date) >= firstDay)
                renewalFilter = renewalFilter.filter((data) => (data?.registered_date_time ? YYYYMMDDFormat(new Date(data?.registered_date_time)) : data?.next_service_date) >= firstDay)
            } else {
                complaintFilter = complaintFilter.filter((data) => (data?.registered_date_time ? YYYYMMDDFormat(new Date(data?.registered_date_time)) : data?.next_service_date) <= firstDay)
                serviceFilter = serviceFilter.filter((data) => (data?.registered_date_time ? YYYYMMDDFormat(new Date(data?.registered_date_time)) : data?.next_service_date) <= firstDay)
                renewalFilter = renewalFilter.filter((data) => (data?.registered_date_time ? YYYYMMDDFormat(new Date(data?.registered_date_time)) : data?.next_service_date) <= firstDay)
            }
        }

        setData({ complaint: complaintFilter, service: serviceFilter, renewal: renewalFilter })
    }

    useEffect(() => {
        doFilter()
    }, [cache, searchParams.get('cid'), searchParams.get('postOffice'), searchParams.get('customerStatus'), searchParams.get('type')])

    return (
        <div className='pending-service-list-div'>
            <Modal modal={modal} setModal={setModal} />
            <SinglePage titleArray={['Pending service list', `Under ${cache?.about_zone?.pr_get_data_date}`]}>
                <div className="top-buttons-div">
                    <div className="left">
                        <div className={searchParams.get("page") === 'complaint' ? 'menu-button active' : 'menu-button'}
                            onClick={() => changeType('complaint')}>
                            Complaint <span>{data?.complaint?.length}</span></div>
                        <div className={searchParams.get("page") === 'service' ? 'menu-button active' : 'menu-button'}
                            onClick={() => changeType('service')}>
                            Service <span>{data?.service?.length}</span></div>
                        <div className={searchParams.get("page") === 'renewal' ? 'menu-button active' : 'menu-button'}
                            onClick={() => changeType('renewal')}>
                            renewal <span>{data?.renewal?.length}</span></div>
                    </div>
                    <div className="right">
                        {loading !== 'fetch' && <>
                            <button onClick={() => fetchData()}>Refresh</button>
                            {(searchParams.get('cid') || searchParams.get('postOffice') || searchParams.get('customerStatus') || searchParams.get('type'))
                                ? <button onClick={clearFilter}>Clear Filter</button>
                                : <button onClick={openFilter}>Filter</button>}
                        </>}
                    </div>
                </div>
                <div className="content-div">
                    {loading === 'fetch' ?
                        <Spinner message='fetch data...' />
                        : <div className='content-border'>
                            {list?.data?.[0] ? <div className="item-div">
                                {list?.data?.map((service, index) => {
                                    return <ServiceCard key={index} data={service} type={list?.item}
                                        navLink={'/pending-service-list/service'} />
                                })}
                            </div>
                                :
                                <Spinner message={`No ${list?.item}`} icon={<RxDashboard />} spin={false} />}
                        </div>
                    }
                </div>
            </SinglePage >
        </div >
    )
}

export default PendingServiceList 