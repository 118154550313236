import React, { useState } from 'react'
import './style.scss'
import NormalInput from '../../common/inputs/NormalInput';
import toast from 'react-hot-toast';
import { BiLoaderAlt } from 'react-icons/bi'
import { userAxios } from '../../../config/axios';
import { IoIosCloseCircle } from "react-icons/io";
import { BsPlusLg } from "react-icons/bs";
import { complaintPriority } from '../../../assets/javascript/const-data'


const AddEditSubOption = ({ title, data, setModal, setData }) => {

    const [loading, setLoading] = useState(false)
    const [form, setForm] = useState({
        option: data?.option || '', sub_options_one: data?.sub_options_one || [],
        sub_options_two: data?.sub_options_two || [],
    })
    const [reason, setReason] = useState('')
    const [solution, setSolution] = useState('')

    const handleChange = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        

        if (form?.option && form?.option[0] === ' ') {
            toast.error(`Remove the space from the first letter`);
            return
        }

        if (!form?.sub_options_one?.[0] || !form.sub_options_two?.[0]) {
            toast.error('Add any sub options');
            setLoading(false)
            return
        }

        const regex = new RegExp("other", "i")

        if (form?.option.length === 5 && regex.test(form?.option || '')) {
            toast.error(`You cannot add 'other'.`);
            setLoading(false)
            return
        }

        setLoading(true)
        if (data) {
            userAxios.put(`/resource/sub-option/${title}`, { option_id: data._id, ...form }).then(() => {
                setData((state) => state.map((input) => {
                    if (input._id === data._id) {
                        return {
                            ...input,
                            ...form
                        }
                    }
                    return input
                }))
                setModal({ status: false })
                setLoading(false)
            }).catch((error) => {
                toast.error(error.message || 'Try now')
                setLoading(false)
            })
        } else {
            userAxios.post(`/resource/sub-option/${title}`, form).then((response) => {
                setData((state) => ([
                    response.data,
                    ...state
                ]))
                setModal({ status: false })
                setLoading(false)
            }).catch((error) => {
                toast.error(error.message || 'Try now')
                setLoading(false)
            })
        }
    }

    const handleSubmitSubOne = () => {
        if (reason === '') {
            toast.error('Type something!')
            setReason('')
            return
        }

        const check = form?.sub_options_one?.filter((value) => value === reason)
        if (check?.[0]) {
            toast.error('Already added!')
            setReason('')
            return;
        }

        setForm({
            ...form,
            sub_options_one: [
                ...form?.sub_options_one || [],
                reason
            ]
        })
        setReason('')
    }

    const handleSubmitSubTwo = () => {
        if (solution === '') {
            toast.error('Type something!')
            setSolution('')
            return
        }

        const check = form?.sub_options_two?.filter((value) => value === solution)
        if (check?.[0]) {
            toast.error('Already added!')
            setSolution('')
            return;
        }

        setForm({
            ...form,
            sub_options_two: [
                ...form?.sub_options_two || [],
                solution
            ]
        })
        setSolution('')
    }

    const removeSubOptionOne = (value) => {
        setForm({
            ...form,
            sub_options_one: form?.sub_options_one?.filter((sub) => sub !== value)
        })
    }

    const removeSubOptionTwo = (value) => {
        setForm({
            ...form,
            sub_options_two: form?.sub_options_two?.filter((sub) => sub !== value)
        })
    }



    return (
        <div className="add-edit-sub-option-div">
            <form onSubmit={handleSubmit}>
                <NormalInput type={'text'} label='Complaint nature' name='option' value={form?.option} onChangeFun={handleChange} />
                {/* Complaint Reasons */}
                {form?.sub_options_one?.[0] && <>
                    <h5>Complaint Reasons</h5>
                    <div className="sub-options-div">
                        {form?.sub_options_one.map((sub) => <div className="item-div">
                            <p>{sub}</p>
                            <IoIosCloseCircle onClick={() => removeSubOptionOne(sub)} />
                        </div>)}
                    </div>
                </>}
                <form action="">
                    <div className="add-div">
                        <NormalInput type={'text'} label='Reasons' name='sub_options_one' value={reason}
                            onChangeFun={(e) => setReason(e.target.value)} />
                        <button type='button' onClick={handleSubmitSubOne}><BsPlusLg /></button>
                    </div>
                </form>

                {/* Complaint Solutions */}
                {form?.sub_options_two?.[0] && <>
                    <h5>Complaint Solutions</h5>
                    <div className="sub-options-div">
                        {form?.sub_options_two.map((sub) => <div className="item-div">
                            <p>{sub}</p>
                            <IoIosCloseCircle onClick={() => removeSubOptionTwo(sub)} />
                        </div>)}
                    </div>
                </>}
                <form action="">
                    <div className="add-div">
                        <NormalInput type={'text'} label='Solutions' name='sub_options_two' value={solution}
                            onChangeFun={(e) => setSolution(e.target.value)} />
                        <button type='button' onClick={handleSubmitSubTwo}><BsPlusLg /></button>
                    </div>
                </form>
                <div className="button-div">
                    <button type={loading ? 'button' : 'submit'}>{loading ? <span className='loading-icon'><BiLoaderAlt /></span> : 'Submit'}</button>
                </div>
            </form>
            {loading === 'fetch' && <div className="loading-pop">
                <p>Loading...</p>
            </div>}
        </div>
    )
}

export default AddEditSubOption