import React, { useEffect, useState } from 'react'
import './choose-box.scss'
import NormalInput from '../../common/inputs/NormalInput'
import SelectInput from '../../common/inputs/SelectInput'
import { YYYYMMDDFormat } from '../../../assets/javascript/formate-functions'
import { useNavigate } from 'react-router-dom'
import { userAxios } from '../../../config/axios'
import toast from 'react-hot-toast'


const ServicedChooseBox = () => {
    const [form, setForm] = useState({ zone_id: null, from_date: null, end_date: null, })
    const navigate = useNavigate()
    const [zone, setZone] = useState([])
    const [loading, setLoading] = useState('fetch')

    const handleChange = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        navigate('/admin/serviced-list', {
            state: form
        })
    }

    useEffect(() => {
        setLoading('fetch')
        userAxios.get('/zone').then((response) => {
            const zoneList = response?.data || []
            setZone([{ option: 'All', value: 'All' }, ...zoneList.map((obj) => ({ option: obj.zone, value: obj.zone_id }))])
            setLoading('')
        }).catch((error) => {
            toast.error(error.message)
        })

        setForm({
            ...form,
            from_date: YYYYMMDDFormat(new Date().setDate(new Date().getDate() - 2)),
            end_date: YYYYMMDDFormat(new Date()),
            zone_id: 'All'
        })
        // eslint-disable-next-line
    }, [])


    return (
        <div className="choose-box-div">
            <form action="" onSubmit={handleSubmit}>
                <NormalInput label='From date (MM-DD-YYYY)' name='from_date' type={'date'} value={form?.from_date} onChangeFun={handleChange}
                    max={form?.end_date} />
                <NormalInput label='End date (MM-DD-YYYY)' name='end_date' type={'date'} value={form?.end_date} onChangeFun={handleChange}
                    max={YYYYMMDDFormat(new Date())} />
                <SelectInput label='Zone' name='zone_id' values={zone} onChangeFun={handleChange} />
                <div className="button-div">
                    <button type={'submit'}>Select</button>
                </div>
            </form>
            {loading === 'fetch' && <div className="loading-pop">
                <p>Loading...</p>
            </div>}
        </div>
    )
}

export default ServicedChooseBox