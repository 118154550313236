import React, { useEffect, useState } from 'react';
import './reg-full-view.scss';
import { FaStar, FaUserAlt } from "react-icons/fa";
import { IoSettingsSharp } from "react-icons/io5";
import { IoCall } from "react-icons/io5";
import { customerAxios } from '../../../config/axios';
import { YYYYMMDDFormat, formatStringDate } from '../../../assets/javascript/formate-functions';
import Spinner from '../../common/spinners/SpinWithMessage'

const RegFullView2 = ({ data }) => {

    const [loading, setLoading] = useState('')
    const [customer, setCustomer] = useState({})

    useEffect(() => {
        setLoading('fetch')
        customerAxios.get(`/profile?cid=${data?.cid}&detail=YES`).then((response) => {
            setCustomer(response.data)
            setLoading('')
        })
        // eslint-disable-next-line
    }, [])

    return (
        <div className="reg-full-view-div">
            {loading
                ? <div className='loading'>
                    <Spinner message={'fetch data...'} height={'300px'} />
                </div>
                : <div className="content-div">
                    <div className="left-div">
                        <div className="part-div">
                            <h4><FaUserAlt /> Customer details</h4>
                            <h5>{customer?.full_name} <span className={`text-badge ${customer?.purifier_customer_status}-text`}>{customer?.purifier_customer_status}</span></h5>
                            {customer?.star_rate && <p >Star Rate : <span className={`text-badge green-text`}><FaStar /> {customer?.star_rate}</span></p>}
                            <p className='address'>{customer?.address?.address}, {customer?.address?.place},</p>
                            <p className='address'>{customer?.address?.post} (P.O) - {customer?.address?.pin_code}</p>
                            {customer?.contact1 && <p className='mobile'>Contact 01 : {customer?.contact1}</p>}
                            {customer?.contact2 && <p className='mobile'>Contact 02 : {customer?.contact2}</p>}
                        </div>
                        <div className="part-div">
                            <h4><IoSettingsSharp /> Service details</h4>
                            <p>Type : {data?.renewal ? 'Renewal / Periodical' : "Periodical"}</p>
                            <p>Service index : {(data?.service_count || 0) + 1}</p>

                            <p>Next service : {formatStringDate(new Date(data?.next_service_date))}</p>
                            <p>Package expire : {formatStringDate(new Date(data?.package_expiry_date))}</p>
                        </div>
                        {customer?.purifier_work_description?.[0] && <div className="part-div">
                            <h4><IoCall /> Call History</h4>
                            {customer?.purifier_work_description
                                ?.sort((a, b) => new Date(b?.date) - new Date(a?.date))
                                ?.map((obj) => {
                                    return <p>{YYYYMMDDFormat(new Date(obj?.date))} : {obj?.description} ({obj?.by})</p>
                                })}
                        </div>}
                    </div>
                    <div className="right-div">

                    </div>
                </div>}
        </div >
    )
}

export default RegFullView2