import { customerStatusInSmall } from './formate-functions';


const listAllPostsFromCustomer = (arr) => {
    let posts = [...new Set(arr.map(item => item.address.post))];
    posts = posts.sort().map(post => ({
        value: post,
        label: post
    }));

    return posts;
}

const objectToSelectOptions = (arr, key) => {
    let options = arr.map(obj => ({
        value: obj[key],
        option: obj[key]
    }));

    return options;
}

const compareDates = (ISODate) => {
    const currentDate = new Date();
    const inputDateTime = ISODate;

    // Set hours, minutes, seconds, and milliseconds to 0 for accurate date comparison
    currentDate.setHours(0, 0, 0, 0);
    inputDateTime.setHours(0, 0, 0, 0);

    const millisecondsInOneDay = 24 * 60 * 60 * 1000;
    const daysDifference = Math.floor((inputDateTime - currentDate) / millisecondsInOneDay);

    if (daysDifference === 0) {
        return 'Today';
    } else if (daysDifference === -1) {
        return 'Yesterday';
    } else if (daysDifference === 1) {
        return 'Tomorrow';
    } else if (daysDifference >= 2 && daysDifference < 7) {
        // Display the day name for dates within the current week
        const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        return daysOfWeek[inputDateTime.getDay()];
    } else {
        // Display the full date for dates beyond the current week
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        return inputDateTime.toLocaleDateString(undefined, options);
    }
}

const twoDatesGap = (date1, date2) => {
    if (typeof date1 !== 'object' || typeof date2 !== 'object') {
        throw Error('Must have pass date as ISO date')
    }

    // Calculate the time difference (gap) between the two dates in milliseconds
    const gapInMilliseconds = (date2 - date1);
    // Convert the gap from milliseconds to days
    const gapInDays = parseInt(gapInMilliseconds / (1000 * 60 * 60 * 24));


    return gapInDays + 1; // 1 for adjusting day count with today


}

const extractNumbers = (str) => {
    const numbers = (`${str}`).match(/\d+/g);
    if (numbers) {
        return parseInt(numbers.join(""));
    } else {
        return null;
    }
}

const workTypeFinder = (currentStatus, newStatus, work) => {

    let type = 'from_'
    if (currentStatus) {
        type = type + customerStatusInSmall(currentStatus) + '_'
    }

    if (newStatus) {
        type = type + customerStatusInSmall(newStatus) + '_'
    }

    if (work) {
        type = type + work
    }

    return type

}

const thisMonthLastDay = (ISODate) => {
    const today = new Date(ISODate); // This gets the current date and time
    const lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);

    const lastDayISO = new Date(lastDayOfMonth.setHours(23, 59, 59, 999)).toISOString();
    return lastDayISO
}

const thisMonthFirstDay = (ISODate) => {
    const today = new Date(ISODate); // This gets the current date and time
    const lastDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);

    const lastDayISO = new Date(lastDayOfMonth.setHours(0, 0, 0, 0)).toISOString();
    return lastDayISO
}

const getDaysFromISODate = (ISODate, days) => {
    // Not use the function 
    if (!ISODate, !days) {
        throw Error('Get days from ISO date Arguments missing')
    }

    ISODate.setDate(ISODate.getDate() + Number(days));
    const convert = ISODate.toISOString().split('T')[0];

    return convert
}



export {
    listAllPostsFromCustomer, compareDates, objectToSelectOptions, extractNumbers, workTypeFinder, thisMonthLastDay,
    thisMonthFirstDay, getDaysFromISODate, twoDatesGap
}