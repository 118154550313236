import React, { useEffect, useState } from 'react'
import AdminPage from '../../../components/common/page/AdminPage'
import { serviceAxios } from '../../../config/axios'
import toast from 'react-hot-toast'
import './style.scss'
import Spinner from '../../../components/common/spinners/SpinWithMessage'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import Modal from '../../../components/common/modal/Modal'
import TableView from './TableView'
import TableView2 from './TableView2'
import { useSelector } from 'react-redux'


const ServiceComplaintRegList = () => {
    const location = useLocation();
    // eslint-disable-next-line
    const [local, setLocal] = useState(location?.state || {})
    const [dataList, setDataList] = useState({})
    // eslint-disable-next-line
    const [tempList, setTempList] = useState({})
    const [modal, setModal] = useState({ content: null, title: null, status: false })
    const [loading, setLoading] = useState('fetch')
    const [searchParams, setSearchParams] = useSearchParams()
    const navigate = useNavigate()
    const { admin } = useSelector((state) => state.adminAuth)

    const changeStatus = (status) => {

        setSearchParams({ ...searchParams, status })
    }

    const groupByStatusArray = (arr = []) => {
        const groupedByStatus = arr.reduce((result, obj) => {
            const status = obj.status;

            // Create an array for the status if it doesn't exist in the result object
            if (!result[status]) {
                result[status] = [];
            }

            // Push the current object to the array corresponding to its status
            result[status].push(obj);

            return result;
        }, {});

        return groupedByStatus;
    }

    const fetchData = () => {
        setLoading('fetch')
        if (local?.type === 'complaint') {
            serviceAxios.get(`/complaints/list?from_date=${local?.from_date}&end_date=${local?.end_date}&all=${local?.all}`)
                .then((response) => {
                    setDataList(groupByStatusArray(response.data) || {})
                    setTempList(groupByStatusArray(response.data) || {})
                    setLoading('')
                }).catch((error) => {
                    setLoading('')
                    toast.error(error.message)
                })
        } else {
            serviceAxios.get(`/services/list?from_date=${local?.from_date}&end_date=${local?.end_date}&all=${local?.all}`)
                .then((response) => {
                    setDataList(groupByStatusArray(response.data) || {})
                    setTempList(groupByStatusArray(response.data) || {})
                    setLoading('')
                }).catch((error) => {
                    setLoading('')
                    toast.error(error.message)
                })
        }
    }

    useEffect(() => {
        if (!location?.state && !local?.type) {
            navigate(`/admin?id=${admin._id}`)
            return;
        }

        fetchData();
        // eslint-disable-next-line
    }, [local])

    return (
        <div className="reg-list-div">
            <Modal modal={modal} setModal={setModal} />
            <AdminPage titleArray={[`${local?.type === 'complaint' ? 'Complaints list' : 'Service - Renewal list'}`]}>
                <div className="top-bar-div">
                    {local?.type === "service" && <div className={searchParams.get('status') === "UnRegistered" ? "item-div active" : "item-div"}
                        onClick={() => changeStatus("UnRegistered")}>
                        <p>UnRegistered</p>
                        <span className={'text-badge'}>{dataList?.['UnRegistered']?.length || "0"}</span>
                    </div>}
                    {/* <div className={searchParams.get('status') === "Pending" ? "item-div active" : "item-div"}
                        onClick={() => changeStatus("Pending")}>
                        <p>Pending</p>
                        <span className={'text-badge'}>{dataList?.['Pending']?.length || "0"}</span>
                    </div> */}
                    <div className={searchParams.get('status') === "Proceed" ? "item-div active" : "item-div"}
                        onClick={() => changeStatus("Proceed")}>
                        <p>Proceed</p>
                        <span className={'text-badge'}>{dataList?.['Proceed']?.length || "0"}</span>
                    </div>
                    <div className={searchParams.get('status') === "Scheduled" ? "item-div active" : "item-div"}
                        onClick={() => changeStatus("Scheduled")}>
                        <p>Scheduled</p>
                        <span className={'text-badge'}>{dataList?.['Scheduled']?.length || "0"}</span>
                    </div>
                    <div className={searchParams.get('status') === "Attending" ? "item-div active" : "item-div"}
                        onClick={() => changeStatus("Attending")}>
                        <p>Attending</p>
                        <span className={'text-badge'}>{dataList?.['Attending']?.length || "0"}</span>
                    </div>
                    {local?.all === "YES" && <>
                        <div className={searchParams.get('status') === "Closed" ? "item-div active" : "item-div"}
                            onClick={() => changeStatus("Closed")}>
                            <p>Closed</p>
                            <span className={'text-badge'}>{dataList?.['Closed']?.length || "0"}</span>
                        </div>
                        <div className={searchParams.get('status') === "Cancelled" ? "item-div active" : "item-div"}
                            onClick={() => changeStatus("Cancelled")}>
                            <p>Cancelled</p>
                            <span className={'text-badge'}>{dataList?.['Cancelled']?.length || "0"}</span>
                        </div>
                    </>}
                </div>
                <div className="table-view-place">
                    {loading ?
                        <Spinner message={`fetch data...`} />
                        : searchParams.get('status') === 'UnRegistered'
                            ? <TableView2 type={local?.type} status={searchParams.get('status')} doRefresh={fetchData}
                                data={dataList?.[searchParams.get('status')]} setModal={setModal} setData={setDataList} dataList={dataList} />
                            : <TableView type={local?.type} status={searchParams.get('status')} doRefresh={fetchData}
                                data={dataList?.[searchParams.get('status')]} setModal={setModal} setData={setDataList} dataList={dataList} />}
                </div>
            </AdminPage>
        </div>
    )
}

export default ServiceComplaintRegList