import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    serviceForm: null,
    urlSections: ['current_condition'],
    isLoading: false,
    isSuccess: false,
    isError: false,
    message: ''
}



export const serviceSlice = createSlice({
    name: 'serviceData',
    initialState,
    reducers: {
        reset: (state) => {
            state.isError = false
            state.isLoading = false
            state.isSuccess = false
            state.message = ''
        },
        setForm: (state, action) => {
            state.serviceForm = action.payload
        },

        changeFormValue: (state, action) => {
            state.serviceForm = { ...state.serviceForm, ...action.payload }
        },
        clearFormData: (state) => {
            state.serviceForm = null
            state.isLoading = false
            state.isSuccess = false
            state.isError = false
            state.message = ''
            state.urlSections = ['current_condition']
        },


        // url Section
        nextAction: (state, action) => {
            const check = state.urlSections.filter((section) => section === action.payload[0])
           
            if (!check[0]) {
                let index = state.urlSections.indexOf(action.payload[1]);
                state.urlSections.splice(index + 1, 0, action.payload[0]);
            }
        },
        clearNext: (state, action) => {
            action?.payload?.map((value) => {
                state.urlSections = state.urlSections.filter((section) => section !== value)
                return value;
            })
        }

    },


})


export const { reset, setForm, changeFormValue, clearFormData, nextAction, clearNext } = serviceSlice.actions;
export default serviceSlice.reducer