import React, { useEffect } from 'react'
import { Routes, Route, useSearchParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { loginUser } from '../redux/features/user/userAuthSlice'
import { baseIP } from '../config/axios'
import Home from '../pages/user/home/Home'
import NotFound from '../pages/user/404/NotFound'
import PendingServiceList from '../pages/user/service-list/PendingServiceList'
import PendingService from '../pages/user/service/PendingService'
import Settings from '../pages/user/settings/Settings'
import ScheduledList from '../pages/user/service-list/ScheduledList'
import ScheduledService from '../pages/user/service/ScheduledService'
import ServiceForm from '../pages/user/service-form/ServiceForm'
import CustomerDetails from '../pages/user/customer-details/CustomerDetails'
import KmsList from '../pages/user/kms-list/KmsList'
import DarList from '../pages/user/dar-list/DarList'
import ServiceData from '../pages/user/service-data/ServiceData'
import SuccessSubmit from '../components/user/service-form-sub-comp/SuccessSubmit'


function User() {
  const { user } = useSelector((state) => state.userAuth)
  // eslint-disable-next-line
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch()
  let isAuthenticated = false

  if (user?.token) {
    isAuthenticated = true
  }

  useEffect(() => {
    const id = searchParams.get('id');

    if (!id && !user?._id) {
      window.location.href = `http://staff.alliancewatersolutions.com`
    } else if (id || user?._id) {
      dispatch(loginUser(id || user?._id))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  return (
    <Routes>
      {/* Home */}
      <Route path='/' element={<PrivateRoute element={<Home />} isAuthenticated={isAuthenticated} />} />
      {user?.active_zone?.zone && <>
        {/* Service List */}
        <Route path='/pending-service-list/service' element={<PrivateRoute element={<PendingService />} isAuthenticated={isAuthenticated} />} />
        <Route path='/pending-service-list' element={<PrivateRoute element={<PendingServiceList />} isAuthenticated={isAuthenticated} />} />
        <Route path='/scheduled-works/service' element={<PrivateRoute element={<ScheduledService />} isAuthenticated={isAuthenticated} />} />
        <Route path='/scheduled-works' element={<PrivateRoute element={<ScheduledList />} isAuthenticated={isAuthenticated} />} />
        {/* Service form */}
        <Route path='/attending' element={<PrivateRoute element={<ServiceForm />} isAuthenticated={isAuthenticated} />} />
        <Route path='/attending/submit' element={<PrivateRoute element={<SuccessSubmit />} isAuthenticated={isAuthenticated} />} />
        {/* Kms */}
        <Route path='/kms' element={<PrivateRoute element={<KmsList />} isAuthenticated={isAuthenticated} />} />
        {/* Settings */}
        <Route path='/settings' element={<PrivateRoute element={<Settings />} isAuthenticated={isAuthenticated} />} />
        {/* Customer details */}
        <Route path='/customer/:cid/cards' element={<PrivateRoute element={<CustomerDetails />} isAuthenticated={isAuthenticated} />} />
        {/* DAR List */}
        <Route path='/dar-list' element={<PrivateRoute element={<DarList />} isAuthenticated={isAuthenticated} />} />
        <Route path='/dar-list/:srl_number/view' element={<PrivateRoute element={<ServiceData />} isAuthenticated={isAuthenticated} />} />
      </>}

      {/* 404 Route */}
      <Route path="*" element={<NotFound />} />
    </Routes>
  )
}

function PrivateRoute({ element, isAuthenticated }) {
  return isAuthenticated ? (
    <Routes>
      <Route path='/' element={element} />
    </Routes>
  ) : ""

}

export default User