import React, { useEffect, useState } from 'react';
import './zone-list.scss';
import { userAxios } from '../../../config/axios';
import { FiEdit2 } from 'react-icons/fi'
import { AiOutlinePlus } from 'react-icons/ai'
import AdminPage from '../../../components/common/page/AdminPage';
import TableFilter from '../../../components/common/table-filter/TableFilter'
import AddEditZone from '../../../components/admin/add-edit-zone/AddEditZone';
import Modal from '../../../components/common/modal/Modal'
import Spinner from '../../../components/common/spinners/SpinWithMessage'
import { RxDashboard } from 'react-icons/rx'

const ZoneList = () => {
    const [zone, setZone] = useState([])
    const [modal, setModal] = useState({ content: null, title: null, status: false })
    const [loading, setLoading] = useState('')

    useEffect(() => {
        setLoading('fetch')
        userAxios.get('/zone').then((response) => {
            setZone(response.data)
            setLoading('')
        })
    }, [])

    const openModal = (title, data) => {
        setModal({
            content: <AddEditZone data={data || ''} setModal={setModal} setZone={setZone} />,
            title,
            status: true
        })
    }

    return (
        <div className="zone-list">
            <Modal modal={modal} setModal={setModal} />
            <AdminPage titleArray={['Zone list']}>
                <div className="table-border-div">
                    {zone?.[0] ? <TableFilter srlNo={true} topRight={<button className='add-button'
                        onClick={() => openModal('CREATE ZONE')}><AiOutlinePlus /> Add zone</button>}>
                        <table>
                            <thead>
                                <tr>
                                    <th>Zone name</th>
                                    <th>Assign date</th>
                                    <th>Tech list</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {zone?.map((value, index) => {
                                    return <tr key={index}>
                                        <td>{value?.zone}</td>
                                        <td>{value?.pr_get_data_date}</td>
                                        <td>{value?.technicians?.map((tech, idx) => <>{tech?.full_name}<br></br></>)}</td>
                                        <td>
                                            <div className='buttons' >
                                                <button title='Edit' onClick={() => openModal('EDIT ZONE    ', value)}
                                                    className='button-small-icon edit'><FiEdit2 /></button>
                                            </div>
                                        </td>
                                    </tr>
                                })}
                            </tbody>
                        </table>
                    </TableFilter>
                        : <Spinner message={loading ? 'fetch data...' : 'No data'} icon={!loading && <RxDashboard />} spin={loading}
                            bottomContent={<button className='add-button'
                                onClick={() => openModal('CREATE ZONE')}><AiOutlinePlus /> Add zone</button>} />
                    }
                </div>
            </AdminPage>
        </div>
    )
}

export default ZoneList