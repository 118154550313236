import React from 'react'
import './customer-profile-card.scss'
import { BsStarFill } from 'react-icons/bs'
import { RxDotFilled } from 'react-icons/rx'
import { useNavigate } from 'react-router-dom'

const CustomerProfileCard1 = ({ imgObj, fullName, customerStatus, starRate, cid, blacked, before_status }) => {
    const navigate = useNavigate();

    return (
        <div className="customer-profile-card-one-div">
            <div className="profile-info" onClick={() => navigate(`/customer/${cid}/cards`)}>
                <div className={`profile-img ${customerStatus}-text`}>
                    {/* <img src="" alt="" /> */}
                    <h2 >{fullName?.slice(0, 1).toUpperCase()}</h2>
                </div>
                <div className="name-div">
                    <div>
                        <h4>{fullName}</h4>
                        <span className='text-badge green-text star-rate'><BsStarFill /> {starRate || 0}</span>
                    </div>
                    <div>
                        <p>CID : {cid}</p>
                        <RxDotFilled />
                        {blacked
                            ? <>
                                <span className={`text-badge ${before_status}-text`}>{before_status}</span> <p>to</p>
                                <span className={`text-badge ${customerStatus}-text`}>{customerStatus}</span>
                            </>
                            : <span className={`text-badge ${customerStatus}-text`}>{customerStatus}</span>}

                    </div>

                </div>
            </div>
        </div>
    )
}

export default CustomerProfileCard1