import React, { useEffect, useState } from 'react'
import './service-form.scss'
import SinglePage from '../../../components/common/page/SinglePage'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { changeFormValue } from '../../../redux/features/user/serviceSlice'
import { toast } from 'react-hot-toast'
import { TbClockHour9 } from "react-icons/tb";
import { IoArrowBackOutline } from "react-icons/io5";
import { LuTimer } from "react-icons/lu";
import { AiOutlineSync } from "react-icons/ai";
import { formatTimeSeconds } from '../../../assets/javascript/formate-functions'
import { extractNumbers } from '../../../assets/javascript/find-functions'
import { customerAxios, serviceAxios, userAxios } from '../../../config/axios'
import { ImSpinner9 } from "react-icons/im";
import SectionOne from '../../../components/user/service-form-comp/SectionOne'
import SectionTwo from '../../../components/user/service-form-comp/SectionTwo'
import SectionThree from '../../../components/user/service-form-comp/SectionThree'
import SectionFour from '../../../components/user/service-form-comp/SectionFour'
import SectionFive from '../../../components/user/service-form-comp/SectionFive'
import SectionSix from '../../../components/user/service-form-comp/SectionSix'
import SectionSeven from '../../../components/user/service-form-comp/SectionSeven'
import SectionEight from '../../../components/user/service-form-comp/SectionEight'
import SectionNine from '../../../components/user/service-form-comp/SectionNine'
import Modal from '../../../components/common/modal/Modal'
import Timer from '../../../components/user/service-form-sub-comp/Timer'
import ProductChoose from '../../../components/user/service-form-sub-comp/ProductChoose'



const ServiceForm = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const { serviceForm, urlSections } = useSelector((state) => state.serviceData)
    const [elapsedTime, setElapsedTime] = useState(0);
    const [resources, setResources] = useState({})
    const [purifierData, setPurifierData] = useState({})
    const [loading, setLoading] = useState('fetch')
    const [syncing, setSyncing] = useState(false)
    const [showInput, setShowInput] = useState({ pump: true, fr: true, rejectWater: true, ph: false })
    const [searchParams, setSearchParams] = useSearchParams();
    const [eligibility, setEligibility] = useState('')
    const [customer, setCustomer] = useState({})
    const [productDetails, setProductDetails] = useState({})
    const [packageDetails, setPackageDetails] = useState({})
    const [allSpares, setAllSpares] = useState({})
    const [modal, setModal] = useState({ content: null, title: null, status: false })
    const [productPopup, setProductPopup] = useState(false)

    // const sectionList = [
    //     ['current_condition'],
    //     ['with_new_spun_filter'],
    //     ['pump_and_membrane_checking'],
    //     ['inspection_report'],
    //     ['evaluation'],
    //     ['work_category'],
    //     ['review'],
    //     ['payment'],
    //     ['feedback']
    // ]


    const backAction = () => {
        for (let i = 0; i < urlSections.length; i++) {
            if (urlSections[i] === searchParams.get('section')) {
                setSearchParams(`section=${urlSections[i - 1]}`)
                break;
            }
        }
    }

    const openModal = (elem, title, data) => {
        setModal({
            content: elem,
            title,
            status: true
        })
    }

    const fetchData = async () => {

        try {
            let pump = null
            let fr = null
            let product_id = serviceForm?.cabin_details?.product_id || null
            let product_usage = serviceForm?.cabin_details?.product_usage || null
            let cust_zone_id = null
            let cust_ssp_number = null

            // Get Datas
            const allResources = serviceAxios.get('/form-resources').then((response) => {
                userAxios.get('/products?all=YES&spare_list=NO').then((result) => {
                    response.data.product_list = result?.data || []
                    setResources(response.data)
                })
            })

            const purifierDetails = customerAxios.get(`/service/purifier?cid=${location?.state?.cid || serviceForm?.cid}&spare=YES`)
                .then((response) => {
                    setPurifierData(response.data)
                    product_id = serviceForm?.cabin_details?.product_id || response.data.product_id || null
                    product_usage = serviceForm?.cabin_details?.product_usage || response.data.product_usage || null
                    cust_ssp_number = response.data.ssp_card_number

                    if (!['UV', 'UV+UF'].includes(response?.data?.product_category)) {
                        if (response?.data?.spare_list?.[0]) {
                            response?.data?.spare_list?.map((spare) => {

                                if (spare.spare_category === 'pump') {
                                    pump = spare?.spare_name
                                }

                                if (spare.spare_category === 'fr') {

                                    fr = extractNumbers(spare?.spare_name).toString() || null
                                }
                                return spare;
                            })
                        }
                    } else if (['UV', 'UV+UF'].includes(response?.data?.product_category)) {
                        setShowInput({ ...showInput, pump: false, fr: false, rejectWater: false, ph: false })
                    }

                    if (['RO+ALKALINE', 'RO+UV+ALKALINE', 'RO+UV+UF+ALKALINE'].includes(response?.data?.product_category)) {
                        setShowInput({ ...showInput, ph: true })
                    }

                    // Product setup
                    if (response.data?.product_id && response.data?.product_usage) {

                        userAxios.get(`/products?product_id=${response?.data?.product_id}`).then((res) => {
                            if (res.data?._id) {
                                setProductDetails(res.data)
                                setProductPopup(false)

                            } else {
                                setProductPopup(true)
                            }
                        }).catch(() => {
                            setProductPopup(true)
                        })
                    } else {
                        setProductPopup(true)
                        setLoading('')
                    }
                })

            const eligibility = customerAxios.get(`/service/purifier/eligibility?cid=${location?.state?.cid || serviceForm?.cid}`)
                .then((response) => {
                    setEligibility(response.data)
                })

            const profile = customerAxios.get(`/profile?cid=${location?.state?.cid || serviceForm?.cid}&detail=YES`).then((response) => {
                setCustomer(response.data)
                cust_zone_id = response.data.zone_id
            })

            const packageDetails = userAxios.get(`/package/about?cid=${location?.state?.cid || serviceForm?.cid}`).then((response) => {
                setPackageDetails(response.data)
            })

            const allSpares = userAxios.get('/spares/category').then((response) => {
                setAllSpares(response.data)
            })


            await Promise.all([allResources, purifierDetails, eligibility, profile, packageDetails, allSpares])
                .then(() => {

                    if (location?.state?.in_time && !serviceForm?.in_time && location?.state?.cid && !serviceForm?.cid) {
                        dispatch(changeFormValue({
                            in_time: location?.state?.in_time,
                            reg_no: location?.state?.reg_no,
                            reg_type: location?.state?.type,
                            cid: location?.state?.cid,
                            zone_id: cust_zone_id,
                            current_ssp_card_no: cust_ssp_number,
                            before_checking_data: {
                                purifier_working_status: true,
                                leak: false,
                                scratch_crack: false,
                                fr,
                                pump
                            },
                            cabin_details: {
                                product_id,
                                product_usage
                            }
                        }))
                    } else if (!serviceForm?.in_time) {
                        navigate('/scheduled-works')
                        toast.error('Try now!')
                    }

                    setLoading('')
                    setSyncing(false)
                })
                .catch((error) => toast.error(error.message))

        } catch (error) {
            toast.error('Data fetching failed, Try now!')
        }
    }

    const handleSync = () => {
        setSyncing(true)
        fetchData()
    }

    useEffect(() => {
        fetchData()
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        const intervalId = setInterval(() => {
            const currentTime = new Date().toISOString();
            const elapsedMilliseconds = new Date(currentTime) - new Date(serviceForm?.in_time);
            setElapsedTime(elapsedMilliseconds);
        }, 1000);

        // Cleanup the interval on component unmount
        return () => clearInterval(intervalId);
    }, [serviceForm?.in_time]);


    return (
        <div className="service-form-div">
            <Modal modal={modal} setModal={setModal} />
            <SinglePage titleArray={[`Service form - CID : ${serviceForm?.cid}`]}>
                <div className="service-from-border-div">
                    <div className="service-form">
                        <div className="sticky-nav-div">
                            <div className="right">
                                {searchParams.get('section') !== 'current_condition' && <div className="before-after-button"
                                    onClick={() => backAction(searchParams.get('section'))}>
                                    <IoArrowBackOutline />
                                </div>}
                            </div>
                            <div className="left">
                                <div className="icon-badge-div" style={{ cursor: 'pointer' }}
                                    onClick={handleSync}>
                                    {syncing
                                        ? <span className='loading-icon'><AiOutlineSync /></span>
                                        : <AiOutlineSync />}
                                    <p className='hide-text'>Sync</p>
                                </div>
                                <div className="icon-badge-div" style={{ cursor: 'pointer' }}
                                    onClick={() => openModal(<Timer />, 'TIMER')}>
                                    <LuTimer />
                                    <p className='hide-text'>Timer</p>
                                </div>
                                <div className="icon-badge-div">
                                    <TbClockHour9 />
                                    <p>{formatTimeSeconds(elapsedTime)}</p>
                                </div>
                            </div>
                        </div>
                        <div className="section-div">
                            <div className="section-border">
                                {productPopup && <>
                                    <ProductChoose product_list={resources?.product_list} setProductPopup={setProductPopup}
                                        setProductDetails={setProductDetails} setShowInput={setShowInput} />
                                </>}
                                {!productPopup && <>
                                    {searchParams.get('section') === 'current_condition' &&
                                        < SectionOne resources={resources} purifier={purifierData} showInput={showInput}
                                            product={productDetails} />}

                                    {searchParams.get('section') === 'with_new_spun_filter' &&
                                        < SectionTwo resources={resources} purifier={purifierData} showInput={showInput}
                                            product={productDetails} />}

                                    {searchParams.get('section') === 'pump_and_membrane_checking' &&
                                        < SectionThree resources={resources} purifier={purifierData} showInput={showInput}
                                            product={productDetails} />}

                                    {searchParams.get('section') === 'inspection_report' &&
                                        < SectionFour resources={resources} purifier={purifierData} showInput={showInput} />}

                                    {searchParams.get('section') === 'evaluation' &&
                                        < SectionFive resources={resources} purifier={purifierData} showInput={showInput} />}

                                    {searchParams.get('section') === 'work_category' &&
                                        < SectionSix purifier={purifierData} showInput={showInput} eligibility={eligibility}
                                            reg_type={location?.state?.type || serviceForm?.reg_type} customer={customer}
                                            product={productDetails} resources={resources} packageDetails={packageDetails}
                                            allSpares={allSpares} />}

                                    {searchParams.get('section') === 'review' &&
                                        < SectionSeven customer={customer} />}

                                    {searchParams.get('section') === 'payment' &&
                                        < SectionEight customer={customer} eligibility={eligibility} />}

                                    {searchParams.get('section') === 'feedback' &&
                                        < SectionNine customer={customer} setLoading={setLoading} purifier={purifierData} eligibility={eligibility} />}
                                </>}

                                {/* Loading handle */}
                                {loading && <>
                                    <div className="fetch-loading-div">
                                        <div className="box">
                                            <span className='loading-icon'><ImSpinner9 /></span>
                                            <p>{loading === 'fetch' ? 'Ready to attend' : 'Submitting...'}</p>
                                            {loading === 'fetch' && <button style={{ margin: '15px' }} onClick={() => navigate('/')}>Close</button>}
                                        </div>
                                    </div>
                                </>}
                            </div>
                        </div>
                    </div>
                </div>
            </SinglePage>
        </div>
    )
}

export default ServiceForm