import React, { useEffect, useState } from 'react'
import './service-card.scss'
import { GoDotFill } from 'react-icons/go'
import { BsFillTelephoneFill, BsTelephoneInboundFill, BsFillTelephonePlusFill } from 'react-icons/bs'
import { YYYYMMDDFormat } from '../../../assets/javascript/formate-functions'
import { compareDates, twoDatesGap } from '../../../assets/javascript/find-functions'
import { FaInfoCircle } from "react-icons/fa";
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'

export const ServiceCard = ({ data, type, navLink }) => {
    const navigate = useNavigate()
    const [balanceDate, setBalanceDate] = useState({ gapText: null, gap: 0, status: 'normal' })
    const { user } = useSelector((state) => state.userAuth)
    const handleNavigate = () => {
        navigate(navLink, {
            state: {
                cid: data?.cid,
                reg_no: data?.reg_no || null,
                type,
                blacked: data?.black_list ? true : false,
                before_status: data?.purifier_customer_status || ''
            }
        })
    }

    useEffect(() => {

        const gap = twoDatesGap(new Date(), new Date(data?.package_expiry_date || data?.next_service_date))

        if (gap > 20) {
            setBalanceDate({ gapText: gap > 0 ? `${gap} Days` : 'Expired', gap: gap > 0 ? gap : 0, status: 'Normal' })
        } else if (gap > 10) {
            setBalanceDate({ gapText: gap > 0 ? `${gap} Days` : 'Expired', gap: gap > 0 ? gap : 0, status: 'Urgent' })
        } else {
            setBalanceDate({ gapText: gap > 0 ? `${gap} Days` : 'Expired', gap: gap > 0 ? gap : 0, status: 'Emergency' })
        }
    }, [data])



    return (
        <div className="service-card-div">
            <div className={`border ${data?.reg_no && type !== 'complaint' && 'registered'} ${data?.priority}`} onClick={handleNavigate}>
                <div className="top">
                    <div className="left">
                        <p>CID : {data?.cid}</p>
                        <GoDotFill />
                        {!data?.black_list && <span className={`text-badge ${data?.purifier_customer_status}-text`}>{data?.purifier_customer_status}</span>}
                        {!data?.black_list
                            ? <span title='complaint count' className={`text-badge`}>
                                {type === 'complaint'
                                    ? `C${(data?.complaint_count?.length || 0) + 1 || 1}`
                                    : `S${(data?.service_count?.length || 0) + 1 || 1}`
                                }
                            </span>
                            : <span title='complaint count' className={`text-badge`}>
                                S{data?.service_count + 1}
                            </span>}
                    </div >
                    <div className="right">
                        {data?.black_list ? <span className={`text-badge`}>Blacked</span> :
                            <>{(balanceDate?.gap <= 20 && type === 'renewal') && <span className={`text-badge ${balanceDate?.status}-text`}>{balanceDate?.gapText}</span>}</>}

                        {data?.status === 'Scheduled' || data?.status === 'Attending' ? `${type} | `
                            : data?.reg_no && <span className={data?.send_to_technician === user?._id ? 'text-badge Normal-text' : 'text-badge green-text'}
                                title={data?.send_to_technician === user?._id ? 'Registered for you' : 'Registered'}>R</span>}
                        <p>{data?.registered_date_time ? YYYYMMDDFormat(new Date(data?.registered_date_time)) : data?.next_service_date}</p>
                    </div>
                </div >
                <div className="content">
                    <div className="left">
                        <p>{data?.full_name}</p>
                        <p>{data?.address?.address}, {data?.address?.place}</p>
                        <p>{data?.address?.post} P.O</p>
                    </div>
                    <div className="right">
                        {data?.contact1?.number && <p><BsTelephoneInboundFill /> {data?.contact1?.number}</p>}
                        {data?.contact2?.number && <p><BsFillTelephoneFill /> {data?.contact2?.number}</p>}
                        {data?.additional_contact && <p><BsFillTelephonePlusFill /> {data?.additional_contact}</p>}
                    </div>
                </div>
                <div className="bottom">
                    {data?.status === 'Scheduled' || data?.status === 'Attending' ? <p>Scheduled on : {compareDates(new Date(data?.scheduled_date_time))}
                        , {new Date(data?.scheduled_date_time).toLocaleTimeString()}</p> :
                        data?.purifier_work_description?.[0] ? <p>{data?.purifier_work_description
                            ?.sort((a, b) => new Date(b?.date) - new Date(a?.date))
                            .map((item) => `${YYYYMMDDFormat(new Date(item?.date))} : ${item?.description} | `)} </p>
                            : data?.black_list
                                ? <p><FaInfoCircle /> It is cleared only after the service is completed</p>
                                : <p>Description not available</p>}
                    {data?.status === 'Attending' && <span className='text-badge green-text'>Attending</span>}
                    {data?.black_list && <>
                        <span className={`text-badge ${data?.purifier_customer_status}-text`}>{data?.purifier_customer_status}</span> to <span className='text-badge O/W-text'>O/W</span>
                    </>}
                </div>
            </div >
        </div >
    )
}
