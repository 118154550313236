import React, { useEffect, useState } from 'react'
import './home.scss'
import AdminPage from '../../../components/common/page/AdminPage'
import { useSelector } from 'react-redux'
import { useNavigate, useSearchParams } from 'react-router-dom'
import Modal from '../../../components/common/modal/Modal'
import ChooseBox from '../../../components/admin/choose-box-complaint-list/ChooseBox'
import ServicedChooseBox from '../../../components/admin/choose-box-serviced-list/ServicedChooseBox'
import DarDownloadBox from '../../../components/admin/dar-download-box/DarDownloadBox'
import { GrList } from "react-icons/gr";
import { serviceAxios } from '../../../config/axios'


function Home() {
    const { admin } = useSelector((state) => state.adminAuth)
    const [searchParams, setSearchParams] = useSearchParams();
    const [modal, setModal] = useState({ content: null, title: null, status: false })
    const navigate = useNavigate()
    const [yellowCount, setYellowCount] = useState(0)
    const [redCount, setRedCount] = useState(0)
    const [blackCount, setBlackCount] = useState(0)


    const openModal = (elem, head, data) => {
        setModal({
            content: elem,
            title: head,
            status: true
        })
    }


    useEffect(() => {
        const id = searchParams.get('id');
        if (!id && admin._id) {
            setSearchParams(`id=${admin._id}`)
        }

        serviceAxios.get('/yellow-list?countOnly=YES').then((response) => {
            setYellowCount(response.data?.yellow_list_count || 0)
        })
        serviceAxios.get('/red-list?countOnly=YES').then((response) => {
            setRedCount(response.data?.red_list_count || 0)
        })
        serviceAxios.get('/black-list?countOnly=YES').then((response) => {
            setBlackCount(response.data?.black_list_count || 0)
        })
        // eslint-disable-next-line
    }, [])


    return (
        <div className="home-page-div">
            <Modal modal={modal} setModal={setModal} />
            <AdminPage titleArray={[`Name : ${admin.first_name} ${admin.last_name}`, `Designation : ${admin?.designation?.designation}`]}>
                <div className="top-bar">
                    <div className="button-list">
                        <div className="div-button yellow-list" onClick={() => navigate('/admin/service/yellow-list')}>
                            <div className="icon-div">
                                {yellowCount
                                    ? <p>{yellowCount}</p>
                                    : <GrList />}
                            </div>
                            <p>Yellow list</p>
                        </div>
                        <div className="div-button red-list" onClick={() => navigate('/admin/service/red-list')}>
                            <div className="icon-div">
                                {redCount
                                    ? <p>{redCount}</p>
                                    : <GrList />}
                            </div>
                            <p>Red list</p>
                        </div>
                        <div className="div-button black-list" onClick={() => navigate('/admin/service/black-list')}>
                            <div className="icon-div" style={{ color: 'white' }}>
                                {blackCount
                                    ? <p>{blackCount}</p>
                                    : <GrList />}
                            </div>
                            <p>Black list</p>
                        </div>
                    </div>
                </div>
                <div className="home-border">
                    <div className="button-div" >
                        <button onClick={() => navigate('/admin/input-resources')} >INPUT OPTIONS</button>
                    </div>
                    <div className="button-div" >
                        <button onClick={() => navigate('/admin/zone-list')} >ZONE LIST</button>
                    </div>
                    <div className="button-div" >
                        <button onClick={() => navigate('/admin/customer-list')} >CUSTOMER LIST</button>
                    </div>
                    <div className="button-div" >
                        <button onClick={() => openModal(<ChooseBox type={'complaint'} />, 'CHOOSE DATE')} >COMPLAINTS LIST</button>
                    </div>
                    <div className="button-div" >
                        <button onClick={() => openModal(<ChooseBox type={'service'} />, 'CHOOSE DATE')} >SERVICE / RENEWAL LIST</button>
                    </div>
                    <div className="button-div" >
                        <button onClick={() => openModal(<ServicedChooseBox />, 'CHOOSE DATE')} >SERVICED LIST</button>
                    </div>
                    <div className="button-div" >
                        <button onClick={() => openModal(<DarDownloadBox setModal={setModal} />, 'DOWNLOAD XL FILE')} >DOWNLOAD DAR</button>
                    </div>
                    <div className="button-div" >
                        <button onClick={() => navigate('/admin/upcoming-package-list')} >UPCOMING PACKAGE LIST</button>
                    </div>
                </div>
            </AdminPage >
        </div >
    )
}

export default Home