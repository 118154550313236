import React, { useEffect, useState } from 'react'
import './service-cancel.scss'
import SelectInput from '../../common/inputs/SelectInput';
import NormalInput from '../../common/inputs/NormalInput';
import CheckboxInput from '../../common/inputs/CheckboxInput'
import { BiLoaderAlt } from 'react-icons/bi'
import { serviceAxios, userAxios } from '../../../config/axios';
import toast from 'react-hot-toast';
import axios from 'axios';

const ServiceCancel = ({ data, setData, setModal }) => {
    const [loading, setLoading] = useState('fetch')
    const [reasons, setReasons] = useState([])
    const [form, setForm] = useState({
        cid: data?.cid,
        reg_no: data?.reg_no,
        type: data?.renewal ? 'renewal' : data?.service ? 'service' : 'complaint',
        with_index: false
    })

    useEffect(() => {
        setLoading('fetch')
        userAxios.get('/resource/single/service_cancel_reasons').then((response) => {
            setReasons(response.data?.map((obj) => ({ option: obj?.option, value: obj?.option })))
            setLoading('')
        })
    }, [])

    const handleChange = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        })

        if (e.target.name === 'with_index') {
            setForm({
                ...form,
                [e.target.name]: e.target?.checked ? true : false
            })
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        const ask = window.confirm('Are sure cancel this service ?')
        if (ask) {
            setLoading('submit')
            serviceAxios.put('/cancel-service', form).then((response) => {
                // axios.get(`https://push.smsc.co.in/api/mt/SendSMS?User=${process.env.SMS_USER_NAME}&Password=${process.env.SMS_PASSWORD}&senderid=${process.env.SMS_SENDER_ID}&channel=Trans&DCS=0&flashsms=0&number=919567148324&text=Dear&DLTTemplateId=${process.env.SMS_TEMP_ID}&PEID=${process.env.SMS_PEID}&route=${process.env.SMS_ROUTE}`)
                //     .then((smsRes) => {
                //         if (smsRes.ErrorCode === '000' || smsRes.ErrorMessage === 'Done') {
                //             toast.success('SMS Successfully sended!')
                //         } else {
                //             toast.error('SMS Sending Failed!')
                //         }
                //     }).catch(() => toast.error('SMS Failed!'))
                setData((state) => {
                    return {
                        ...state,
                        [data?.status]: state[data?.status]?.filter((obj) => obj?.reg_no !== data?.reg_no),
                        Cancelled: [...(state['Cancelled'] || []), { ...data, cancelled_date_time: new Date(), cancelled_by: 'You' }]
                    }
                })
                setModal({ status: false })
                setLoading('')
            }).catch((error) => {
                toast.error(error.message)
                setLoading('')
            })
        }
    }


    return (
        <div className="cancel-service-div">
            <form onSubmit={handleSubmit}>
                <SelectInput label='Reason for cancel' name='reason_to_cancel' values={reasons} firstOption={{ option: 'Choose...', value: '' }}
                    onChangeFun={handleChange} />
                <NormalInput label='Description' name='cancel_description' value={form?.cancel_description} onChangeFun={handleChange} min={5} />
                {form.type !== 'complaint' && <CheckboxInput label={'With cancel a service index'} name={'with_index'} onChangeFun={handleChange}
                    isRequired={false} />}
                <div className="button-div">
                    <button type={loading === 'submit' ? 'button' : 'submit'}>{loading === 'submit' ? <span className='loading-icon'><BiLoaderAlt /></span> : 'Cancel Service'}</button>
                </div>
            </form>
            {loading === 'fetch' && <div className="loading-pop">
                <p>Loading...</p>
            </div>}
        </div>
    )
}

export default ServiceCancel