import React, { useEffect, useState } from 'react'
import './service-cancel.scss'
import SelectInput from '../../common/inputs/SelectInput';
import NormalInput from '../../common/inputs/NormalInput';
import { BiLoaderAlt } from 'react-icons/bi'
import { serviceAxios, userAxios } from '../../../config/axios';
import toast from 'react-hot-toast';
import axios from 'axios';
import SpinWithMessage from '../../common/spinners/SpinWithMessage';
import { FaCircleInfo } from "react-icons/fa6";
import { useNavigate } from 'react-router-dom';

const PostponeService = ({ data, setData, setModal, isTechnician }) => {
    const [loading, setLoading] = useState('fetch')
    const [reasons, setReasons] = useState([])
    const [form, setForm] = useState({
        cid: data?.cid
    })
    const navigate = useNavigate()

    useEffect(() => {
        setLoading('fetch')
        userAxios.get('/resource/single/service_postpone_reasons').then((response) => {
            setReasons(response.data?.map((obj) => ({ option: obj?.option, value: obj?.option })))
            setLoading('')
        })
    }, [])

    const handleChange = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        const ask = window.confirm('Are sure postpone this service ?')
        if (ask) {
            setLoading('submit')
            serviceAxios.put('/postpone-service', form).then((response) => {
                // axios.get(`https://push.smsc.co.in/api/mt/SendSMS?User=${process.env.SMS_USER_NAME}&Password=${process.env.SMS_PASSWORD}&senderid=${process.env.SMS_SENDER_ID}&channel=Trans&DCS=0&flashsms=0&number=919567148324&text=Dear&DLTTemplateId=${process.env.SMS_TEMP_ID}&PEID=${process.env.SMS_PEID}&route=${process.env.SMS_ROUTE}`)
                //     .then((smsRes) => {
                //         if (smsRes.ErrorCode === '000' || smsRes.ErrorMessage === 'Done') {
                //             toast.success('SMS Successfully sended!')
                //         } else {
                //             toast.error('SMS Sending Failed!')
                //         }
                //     }).catch(() => toast.error('SMS Failed!'))
                if (isTechnician) {
                    navigate(-1)
                } else {
                    setData((state) => {
                        return {
                            ...state,
                            UnRegistered: state['UnRegistered']?.filter((obj) => obj?.cid !== data?.cid)
                        }
                    })
                    setModal({ status: false })
                }
                toast.success('Service postponed')
                setLoading('')
            }).catch((error) => {
                toast.error(error.message)
                setLoading('')
            })
        }
    }


    return (
        <div className="cancel-service-div">
            {new Date() > new Date(data?.package_expiry_date)
                ? <>
                    <SpinWithMessage height={'150px'} icon={<FaCircleInfo />} spin={false} message={`You can not postpone the service. \n
                         This customer package expired on ${new Date(data?.package_expiry_date).toDateString()}`} />
                </>
                : <>
                    <form onSubmit={handleSubmit}>
                        <SelectInput label='Reason for postpone' name='reason' values={reasons} firstOption={{ option: 'Choose...', value: '' }}
                            onChangeFun={handleChange} />
                        <NormalInput label='Postpone to' type={'date'} name='post_to_date' value={form?.post_to_date} onChangeFun={handleChange}
                            min={data?.next_service_date} max={data?.package_expiry_date} />
                        <div className="button-div">
                            <button type={loading === 'submit' ? 'button' : 'submit'}>{loading === 'submit' ? <span className='loading-icon'>
                                <BiLoaderAlt /></span> : 'Postpone Service'}</button>
                        </div>
                    </form>
                    {loading === 'fetch' && <div className="loading-pop">
                        <p>Loading...</p>
                    </div>}
                </>}
        </div>
    )
}

export default PostponeService