import React from 'react'
import './style.scss'
import { changeFormValue, nextAction, clearNext } from '../../../redux/features/user/serviceSlice'
import { useDispatch, useSelector } from 'react-redux'
import NormalInput from '../../../components/common/inputs/NormalInput'
import { extractNumbers } from '../../../assets/javascript/find-functions'
import { useSearchParams } from 'react-router-dom'
import { findTdsStatus } from '../../../assets/javascript/service-from-helper'


const SectionTwo = React.memo(({ resources, purifier, showInput, product }) => {
    const dispatch = useDispatch();
    const { serviceForm } = useSelector((state) => state.serviceData)
    // eslint-disable-next-line
    const [searchParams, setSearchParams] = useSearchParams();

    const handleChange = (e) => {

        let productFilterType = 'RO'
        if (['UV', 'UV+UF'].includes(product?.product_category)) {
            productFilterType = 'UV'
        } else if (['RO+ALKALINE', 'RO+UV+ALKALINE', 'RO+UV+UF+ALKALINE'].includes(product?.product_category)) {
            productFilterType = 'ALKALINE'
        }

        dispatch(changeFormValue({
            ...serviceForm,
            flow_checking_second_time: {
                ...serviceForm?.flow_checking_second_time,
                [e.target.name]: e.target.value
            },
            evaluation_data: {
                ...serviceForm?.evaluation_data,
                [e.target.name]: e.target.value
            }
        }))

        if (e.target.name === 'filtered_water_flow_ltrs') {
            let status = null
            if (Number(e.target.value) === 0) {
                status = 'No'
            } else if (Number(e.target.value) < 11) {
                status = 'Low'
            } else if (Number(e.target.value) >= 11) {
                status = 'Normal'
            }

            dispatch(changeFormValue({
                ...serviceForm,
                flow_checking_second_time: {
                    ...serviceForm?.flow_checking_second_time,
                    filtered_water_flow_status: status,
                    [e.target.name]: e.target.value
                },
                evaluation_data: {
                    ...serviceForm?.evaluation_data,
                    filtered_water_flow_status: status,
                    [e.target.name]: e.target.value
                }
            }))
        }

        if (e.target.name === 'reject_water_flow_ltrs') {
            let status = null
            if (Number(e.target.value) === 0) {
                status = 'No'
            } else if (Number(e.target.value) < extractNumbers(serviceForm?.before_checking_data?.fr || 0)) {
                status = 'Low'
            } else if (Number(e.target.value) >= extractNumbers(serviceForm?.before_checking_data?.fr || 0)) {
                status = 'Normal'
            }

            dispatch(changeFormValue({
                ...serviceForm,
                flow_checking_second_time: {
                    ...serviceForm?.flow_checking_second_time,
                    reject_water_flow_status: status,
                    [e.target.name]: e.target.value
                },
                evaluation_data: {
                    ...serviceForm?.evaluation_data,
                    reject_water_flow_status: status,
                    [e.target.name]: e.target.value
                }
            }))
        }

        if (e.target.name === 'input_tds') {

            let status = findTdsStatus(Number(e.target.value), Number(serviceForm?.flow_checking_second_time?.output_tds), productFilterType)

            dispatch(changeFormValue({
                ...serviceForm,
                flow_checking_second_time: {
                    ...serviceForm?.flow_checking_second_time,
                    tds_status: status,
                    [e.target.name]: e.target.value
                },
                evaluation_data: {
                    ...serviceForm?.evaluation_data,
                    tds_status: status,
                    [e.target.name]: e.target.value
                }
            }))
        }

        if (e.target.name === 'output_tds') {
            let status = findTdsStatus(Number(serviceForm?.flow_checking_second_time?.input_tds), Number(e.target.value), productFilterType)

            dispatch(changeFormValue({
                ...serviceForm,
                flow_checking_second_time: {
                    ...serviceForm?.flow_checking_second_time,
                    tds_status: status,
                    [e.target.name]: e.target.value
                },
                evaluation_data: {
                    ...serviceForm?.evaluation_data,
                    tds_status: status,
                    [e.target.name]: e.target.value
                }
            }))
        }

        if (e.target.name === 'ph') {
            let status = null
            if (!Number(e.target.value)) {
                status = 'No'
            } else if (6.5 > Number(e.target.value)) {
                status = 'Low'
            } else if (8.5 < Number(e.target.value)) {
                status = 'High'
            } else {
                status = 'Normal'
            }

            dispatch(changeFormValue({
                ...serviceForm,
                flow_checking_second_time: {
                    ...serviceForm?.flow_checking_second_time,
                    ph_status: status,
                    [e.target.name]: e.target.value
                },
                evaluation_data: {
                    ...serviceForm?.evaluation_data,
                    ph_status: status,
                    [e.target.name]: e.target.value
                }
            }))
        }
    }

    const handleSectionSubmit = (e) => {
        e.preventDefault()

        if (serviceForm?.flow_checking_second_time?.filtered_water_flow_status !== 'Normal' ||
            serviceForm?.flow_checking_second_time?.tds_status === 'High') {
            // to section Three  
            dispatch(nextAction(['pump_and_membrane_checking', 'with_new_spun_filter']))
            setSearchParams(`section=pump_and_membrane_checking`)
        } else if (serviceForm?.flow_checking_second_time?.reject_water_flow_status !== 'Normal' && showInput?.rejectWater) {
            // to section three
            dispatch(nextAction(['pump_and_membrane_checking', 'with_new_spun_filter']))
            setSearchParams(`section=pump_and_membrane_checking`)
        } else {
            // to section four
            dispatch(nextAction(['inspection_report', 'with_new_spun_filter']))
            setSearchParams(`section=inspection_report`)
            dispatch(clearNext(['pump_and_membrane_checking']))
            dispatch(changeFormValue({
                ...serviceForm,
                pump_membrane_checking: undefined
            }))
        }
    }

    return (
        <div className="service-form-section-div">
            <div className="title-section">
                <h3>With new spun filter</h3>
                <p>Change spun filter and enter after condition</p>
            </div>
            <div className="form-div">
                <form action="" onSubmit={handleSectionSubmit}>

                    {/* Input Output */}
                    {!serviceForm?.before_checking_data?.purifier_working_status &&
                        <div>
                            <div className="sub-title">
                                <h4>TDS</h4>
                                {serviceForm?.flow_checking_second_time?.tds_status &&
                                    <span className={`text-badge ${serviceForm?.flow_checking_second_time?.tds_status}-text`}>{serviceForm?.flow_checking_second_time?.tds_status}</span>}
                            </div>
                            <div className="two-input-single-line-div">
                                <NormalInput label='Input TDS' name='input_tds' value={serviceForm?.flow_checking_second_time?.input_tds || null} type={'number'}
                                    onChangeFun={handleChange} min={"0"} step={"0.1"} />
                                <NormalInput label='Output TDS' name='output_tds' value={serviceForm?.flow_checking_second_time?.output_tds || null} type={'number'} min={"0"} step={"0.1"}
                                    onChangeFun={handleChange} />
                            </div>
                        </div>}

                    {/* Filtered water flow */}
                    <div>
                        <div className="sub-title">
                            <h4>Filtered water flow</h4>
                            {serviceForm?.flow_checking_second_time?.filtered_water_flow_status &&
                                <span className={`text-badge ${serviceForm?.flow_checking_second_time?.filtered_water_flow_status}-text`}>
                                    {serviceForm?.flow_checking_second_time?.filtered_water_flow_status}</span>}
                        </div>
                        <div className="two-input-single-line-div">
                            <NormalInput label='Current flow' name='filtered_water_flow_ltrs'
                                value={serviceForm?.flow_checking_second_time?.filtered_water_flow_ltrs || null} type={'number'} min={"0"} step={"0.1"}
                                onChangeFun={handleChange} />
                        </div>
                    </div>

                    {/*Reject water flow */}
                    <div>
                        {showInput?.rejectWater && <div className="sub-title">
                            <h4>Reject water flow</h4>
                            {serviceForm?.flow_checking_second_time?.reject_water_flow_status &&
                                <span className={`text-badge ${serviceForm?.flow_checking_second_time?.reject_water_flow_status}-text`}>
                                    {serviceForm?.flow_checking_second_time?.reject_water_flow_status}</span>}
                        </div>}
                        <div className="two-input-single-line-div">
                            {showInput?.rejectWater && <NormalInput label='Current flow' name='reject_water_flow_ltrs'
                                value={serviceForm?.flow_checking_second_time?.reject_water_flow_ltrs || null} type={'number'} min={"0"} step={"0.1"}
                                onChangeFun={handleChange} />}

                        </div>
                    </div>

                    {/* PH */}
                    {!serviceForm?.before_checking_data?.purifier_working_status && <div>
                        {showInput.ph && <div className="sub-title">
                            <h4>Potential of Hydrogen (PH)</h4>
                            {serviceForm?.flow_checking_second_time?.ph_status &&
                                <span className={`text-badge ${serviceForm?.flow_checking_second_time?.ph_status}-text`}>
                                    {serviceForm?.flow_checking_second_time?.ph_status}</span>}
                        </div>}
                        <div className="two-input-single-line-div">
                            {showInput.ph &&
                                <NormalInput label='' name='ph'
                                    value={serviceForm?.flow_checking_second_time?.ph || null} type={'number'} min={"0"} max={14} step={"0.1"}
                                    onChangeFun={handleChange} />}
                        </div>
                    </div>}

                    {/* Next Buttons */}
                    <div className="handle-buttons-div">
                        <button type='submit' className='next'>Next</button>
                    </div>
                </form>
            </div>
        </div>
    )
})

export default SectionTwo